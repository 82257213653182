<ng-container *ngIf="isLoading; else viewModalDody">
  <div class="spinner-loading">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-container>
<ng-template #viewModalDody>
  <div class="shared-entity shared-events">
    <ng-container *ngIf="items && items.length > 0; else noEventsMessage">
      <div *ngFor="let item of items" class="item card container">
        <div class="card-body">
          <a
            appUnsavedFormCheck
            class="link-unstyled"
            [href]="entitybase + '/' + item.id"
          >
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3 p-0 pr-md-3">
                <div class="card-item__image-wrapper">
                  <img
                    class="item__image"
                    [src]="item.image?.url"
                    alt="Event image cap"
                    appDefaultPlaceholder
                    appDynamicImgUrl
                  />
                </div>
              </div>

              <div class="item__info col">
                <ng-container *ngIf="isRegisteredEvent">
                  <div
                    ngbDropdown
                    container="body"
                    placement="left-top"
                    class="dropdown-no-arrow shared-events__more"
                  >
                    <a class="p-2" ngbDropdownToggle appClickPreventDefault>
                      <i class="fa fa-ellipsis-v align-middle"></i>
                    </a>
                    <ul ngbDropdownMenu>
                      <li ngbDropdownItem class="venture__actions-item p-0">
                        <a
                          class="link-unstyled w-100 p-2"
                          appLeaveEntity
                          [entityId]="item.id"
                          [entityName]="EntityName.Event"
                          (afterLeaved)="afterLeaveEvent($event)"
                        >
                          <i class="fas fa-user-times"></i>
                          <span
                            class="venture__actions-label font-weight-bold ml-2"
                            [innerHTML]="
                              'UI.Common.LeaveThis'
                                | translate
                                | stringReplacement
                                  : {
                                      '{entity}': (EntityName.Event | translate)
                                    }
                            "
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-container>

                <div
                  class="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row"
                >
                  <app-privacy-flag
                    class="my-2"
                    [isPrivate]="item.isPrivate"
                  ></app-privacy-flag>

                  <ng-container
                    *ngIf="
                      !isChallenge &&
                      item.eventPartnerStatus &&
                      item.eventPartnerStatus.codeId !==
                        EventPartnerStatusCodeId.DefaultValue
                    "
                  >
                    <div
                      class="event-partner-status event-partner-status--height ml-0 ml-md-3"
                    >
                      <app-partner-status
                        [appendPrefixText]="
                          item.initiator?.id === sessionService.currentUser?.id
                            ? ('UI.Common.YouAre' | translate)
                            : ''
                        "
                        [isEventPartnerStatus]="true"
                        [partnerStatus]="item.eventPartnerStatus"
                      ></app-partner-status>
                    </div>
                  </ng-container>
                </div>

                <div class="row align-items-start">
                  <h6 class="card-title col-12 col-md-10">
                    <a
                      appUnsavedFormCheck
                      [href]="entitybase + '/' + item?.id"
                      >{{ item?.title }}</a
                    >
                  </h6>
                </div>

                <div 
                  class="d-block d-sm-flex"
                  [ngClass]="item?.eventTags.length > 0 ? 'align-items-center' : 'align-items-start' "
                >
                  <div class="flex-shrink-0">
                    <small class="item__date-label">Date</small>

                    <p class="card-text">
                      <small>
                        {{
                          item.eventDateTime
                            | localizedDate : DateFormat.FullDateTimeCommon
                        }}
                      </small>
                    </p>
                  </div>

                  <div class="ml-5 flex-grow-1">
                    <small class="item__date-label">Tags</small>
                    <app-tag-view
                      [tags]="item?.eventTags || []"
                      [bindLabel]="'content'"
                      class="w-100 d-block"
                    >
                    </app-tag-view>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="card-footer">
          <ul class="venture__actions list-inline d-flex justify-content-start">
            <li class="venture__actions-item list-inline-item mr-5">
              <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
                <div class="text-center">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </ng-container>
              <ng-template #followersBtn>
                <a
                  appDebounceClick
                  (debounceClick)="showEntityData(item); showPeopleModal = true"
                  class="d-flex"
                >
                  <app-internal-icon
                    [className]="'filter-gray'"
                    [name]="'user-follow'"
                  ></app-internal-icon>
                  <span
                    class="venture__actions-label font-weight-500 text-gray-600"
                  >
                    <span class="text-hide-small">{{
                      'Followers' | translate
                    }}</span>
                    <span [innerHTML]="item.followerCount | parentheses"></span>
                  </span>
                </a>
              </ng-template>
            </li>

            <app-comment-entity
              [entity]="item"
              [entityName]="EntityName.Event"
              [isLoadingCommentsCount]="isLoadingCommentsCount"
              (afterCommentModalClosed)="afterCommentModalClosed()"
            ></app-comment-entity>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-template #noEventsMessage>
      <span class="message-empty" translate>There are no related events</span>
    </ng-template>
  </div>
</ng-template>

<app-bootstrap-pagination
  [page]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  (changePageSize)="onPageSizeChange($event)"
  (changePage)="onPageChange($event)"
></app-bootstrap-pagination>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.title"
  [modalTitle]="'Followers' | translate"
  [httpService]="eventHttpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>
