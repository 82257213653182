import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { EventPartnerStatusCodeIdEnum } from '@src/app/shared/enums/partner-status.enum';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';

@Component({
  selector: 'app-organization-card-list',
  templateUrl: './organization-card-list.component.html',
})
export class OrganizationCardListComponent {
  @Input() displayItems: LeadCompany[];
  @Input() editting = false;
  @Input() showChallengeTimeline = false;
  @Input() emptyPlacholderKey: string;
  @Input() showVenturesCtrl = false;
  @Input() lstEventPartnerStatus: CustomAtrributeValue[] = [];
  @Input() advancedSetting: boolean;
  @Input() ignoreCompany: LeadCompany;
  @Input() allowRemoveSelf: false;
  @Input() entityName: string;
  @Input() entity: Entity;

  @Output() itemRemoved = new EventEmitter();

  EventPartnerStatusCodeId = EventPartnerStatusCodeIdEnum;

  constructor(private sessionService: SessionService) {}

  removeItem(item: LeadCompany): void {
    this.itemRemoved.emit(item);
  }

  //#region HANDLE EVENT PARTNER STATUS
  getEventPartnerStatusIndex(value: CustomAtrributeValue): number {
    let idx = 0;

    if (!value) {
      return idx;
    }

    idx = this.lstEventPartnerStatus.findIndex(
      (status) => status.codeId === value.codeId
    );

    return idx;
  }

  selectEventPartnerStatus(
    item: LeadCompany,
    status: CustomAtrributeValue
  ): void {
    item.eventPartnerStatus = status;
  }
  //#endregion End Handle Event Partner Status

  shouldShowRemoveBtn(item: LeadCompany): boolean {
    if (this.entityName === EntityName.Event) {
      const isPartnerOrgAdmin = ArrayUtils.hasItem(
        item.initiators,
        this.sessionService.currentUser,
        'id'
      );

      const isEventAdmin = ArrayUtils.hasItem(
        (this.entity as EventInterface)?.initiators,
        this.sessionService.currentUser,
        'id'
      );

      return this.editting || (isPartnerOrgAdmin && !isEventAdmin);
    }

    return this.editting;
  }
}
