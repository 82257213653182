<div class="jip-multi-link">
  <ng-container
    *ngTemplateOutlet="
      linksRef;
      context: {
        items: fixedItems,
        removeable: false
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      linksRef;
      context: {
        items: items,
        removeable: true
      }
    "
  ></ng-container>

  <div
    *ngIf="editable && enabledAddNew"
    class="jip-multi-link__item d-flex align-items-baseline"
  >
    <div class="jip-multi-link__prefix jip-multi-link__prefix--editable">
      <i class="jip-multi-link__icon fas fa-link fa-lg"></i>
    </div>

    <div class="w-100">
      <input
        #newLinkInput="ngModel"
        [(ngModel)]="newLink"
        type="text"
        class="form-control"
        [placeholder]="placeholder"
        [pattern]="regex"
        (keyup.enter)="addLink(newLinkInput)"
      />
      <div
        *ngIf="
          newLinkInput.invalid && (newLinkInput.dirty || newLinkInput.touched)
        "
        class="alert alert-danger"
        translate
      >
        UI.Common.Url-Hint
      </div>
    </div>

    <div class="jip-multi-link__btn-manage"></div>
  </div>
</div>

<ng-template #linksRef let-items="items" let-removeable="removeable">
  <div
    *ngFor="let item of items; index as i"
    class="jip-multi-link__item d-flex align-items-baseline"
  >
    <div
      class="jip-multi-link__prefix"
      [class]="{ 'jip-multi-link__prefix--editable': editable }"
    >
      <i
        class="jip-multi-link__icon fas fa-lg"
        [class]="item?.faIconClass ? item?.faIconClass : 'fa-link'"
      ></i>
    </div>

    <div class="w-100 mx-2" *ngIf="editable; else viewLink">
      <input
        #linkInput="ngModel"
        type="text"
        class="form-control d-block"
        [placeholder]="placeholder"
        [(ngModel)]="items[i].url"
        [pattern]="regex"
        (ngModelChange)="emitOutput()"
      />

      <div
        *ngIf="linkInput.invalid && (linkInput.dirty || linkInput.touched)"
        class="alert alert-danger"
        translate
      >
        UI.Common.Url-Hint
      </div>
    </div>

    <ng-template #viewLink>
      <a appUrlPrefix [value]="item.url" target="_blank">{{ item.url }}</a>
    </ng-template>

    <div class="jip-multi-link__btn-manage">
      <button
        *ngIf="editable && enabledAddNew"
        (click)="removeLink(item)"
        class="btn btn-black widget__btn-create-custom ml-1 ml-md-4"
      >
        <i class="far fa-times"></i>
      </button>
    </div>
  </div>
</ng-template>
