<ng-container
  [appScrollToFragment]="data$ | async"
  *ngIf="data$ | async as response; else skeleton"
>
  <ng-container *ngIf="response.items?.length; else emptyContent">
    <div
      class="list-tiles"
      [class.widget__card--list--background]="!bg_color"
      [style.background-color]="bg_color"
      [class.two-columns]="columns === '2'"
    >
      <div *ngFor="let org of response.items" class="list-tiles__item">
        <ng-container
          *ngIf="
            getUiTemplate(org) !== 'SIListTile';
            else switzerlandInnovationTile
          "
        >
          <app-card-layout (click)="goToEntityDetails(org.id)">
            <!--        Image-->
            <ng-container slot="img">
              <app-image-wrapper
                [wrapperClasses]="
                  'widget__image-wrapper entity__image-wrapper h-100'
                "
                [imgObject]="org.image"
              >
                <span
                  class="simple-card__floating organization__logo position-absolute"
                >
                  <img
                    [src]="org.logo?.url || org.logo"
                    appDefaultPlaceholder
                    appDynamicImgUrl
                    alt=""
                  />
                </span>
              </app-image-wrapper>
            </ng-container>

            <!--        Content-->
            <ng-container slot="body">
              <h3 class="simple-card__title widget__heading d-flex">
                <span class="truncate-line-1">
                  {{ org.orgName }}
                </span>
                <a
                  *ngIf="org.orgUrl.url"
                  [ngbTooltip]="orgWebsiteRef"
                  [value]="org.orgUrl.url"
                  appUrlPrefix
                  target="_blank"
                  class="ml-2"
                >
                  <app-internal-icon
                    [name]="InternalIcon.GlobeCursor"
                    [height]="18"
                    [minWidth]="19.5"
                  ></app-internal-icon>
                </a>
              </h3>

              <ng-template #orgWebsiteRef>
                <div class="d-flex flex-column align-items-center">
                  <span>{{
                    'UI.Organization.ExternalWebsiteTooltip' | translate
                  }}</span>
                  <span class="text-white font-weight-bold">
                    {{ org.orgUrl?.url }}
                  </span>
                </div>
              </ng-template>

              <div class="simple-card__description widget__content">
                <p appEllipsis [innerHTML]="org.description"></p>
              </div>
            </ng-container>

            <!--        Footer-->
            <ng-container slot="footer">
              <div class="p-3">
                <app-challenge-card-footer
                  [keyOfferings]="org.keyOfferings"
                ></app-challenge-card-footer>
              </div>
            </ng-container>
          </app-card-layout>
        </ng-container>

        <ng-template #switzerlandInnovationTile>
          <a
            appUnsavedFormCheck
            class="link-unstyled link-rich-text-pointer border-none si-small-tile-container"
            target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
            rel="noopener"
            (click)="goToEntityDetails(org.id)"
          >
            <div class="venture__header">
              <div>
                <div class="custom-organization__brand">
                  <div
                    class="custom-organization__logo d-flex mb-3 justify-content-center"
                  >
                    <img
                      class="mt-4"
                      [src]="org.logo?.url"
                      appDefaultPlaceholder
                      [default]="placeholderImg"
                      appDynamicImgUrl
                    />
                  </div>

                  <div class="custom-organization__name-container">
                    <div class="custom-organization__name d-flex">
                      <span
                        [ngbTooltip]="org.displayText"
                        class="text-reset truncate-line-1 underline"
                      >
                        {{ org.orgName }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="venture__body widget__card--background">
              <div class="row">
                <div class="col col-12">
                  <div class="venture__info d-flex flex-column">
                    <div
                      class="d-flex justify-content-between align-items-center w-100 my-2"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center custom-organization__button-wrapper"
                      >
                        <app-join-organization
                          [baseHttpService]="listService"
                          [entity]="org"
                          [hideTextSmall]="false"
                        ></app-join-organization>

                        <!-- More -->
                        <div class="more-options" ngbDropdown>
                          <button
                            placement="bottom-left"
                            class="btn btn-interact btn-interact--small btn-secondary-dark text-white ml-2"
                            (click)="
                              $event.preventDefault(); $event.stopPropagation()
                            "
                            ngbDropdownToggle
                          >
                            <span translate> More </span>
                            <i class="fas fa-caret-down ml-1 mr-0"></i>
                          </button>
                          <ul ngbDropdownMenu>
                            <!-- Ventures -->
                            <li
                              ngbDropdownItem
                              class="list-inline-item venture__actions-item"
                            >
                              <ng-container
                                *ngIf="isLoadingVenturesCount; else venturesBtn"
                              >
                                <div class="text-center">
                                  <i class="fas fa-spinner fa-spin"></i>
                                </div>
                              </ng-container>
                              <ng-template #venturesBtn>
                                <a
                                  appDebounceClick
                                  (debounceClick)="
                                    showEntityData(org);
                                    showVenturesModal = true
                                  "
                                >
                                  <i class="fas fa-briefcase"></i>
                                  <span
                                    class="venture__actions-label font-weight-bold"
                                  >
                                    <span class="">{{
                                      'Ventures' | translate
                                    }}</span>
                                    <span
                                      [innerHTML]="
                                        org.venturesCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                </a>
                              </ng-template>
                            </li>

                            <!-- Events -->
                            <li
                              ngbDropdownItem
                              class="list-inline-item venture__actions-item"
                            >
                              <ng-container
                                *ngIf="isLoadingEventsCount; else eventsBtn"
                              >
                                <div class="text-center">
                                  <i class="fas fa-spinner fa-spin"></i>
                                </div>
                              </ng-container>
                              <ng-template #eventsBtn>
                                <a
                                  appDebounceClick
                                  (debounceClick)="
                                    showEntityData(org); showEventsModal = true
                                  "
                                >
                                  <i class="fas fa-folder-open"></i>
                                  <span
                                    class="venture__actions-label font-weight-bold"
                                  >
                                    {{ 'Events' | translate }}
                                    <span
                                      [innerHTML]="
                                        org.eventsCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                </a>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-between align-items-center custom-organization__external-website"
                        *ngIf="org.orgUrl.url"
                      >
                        <a
                          [ngbTooltip]="orgWebsiteRef"
                          [value]="org.orgUrl.url"
                          appUrlPrefix
                          target="_blank"
                          class="ml-2 d-flex align-items-center"
                        >
                          <app-internal-icon
                            [name]="InternalIcon.SIGlobeCursor"
                            [height]="18"
                            [minWidth]="19.5"
                          ></app-internal-icon>
                          <span class="ml-1">Website</span>
                        </a>
                        <ng-template #orgWebsiteRef>
                          <div class="d-flex flex-column align-items-center">
                            <span>{{
                              'UI.Organization.ExternalWebsiteTooltip'
                                | translate
                            }}</span>
                            <span class="text-white font-weight-bold">
                              {{ org.orgUrl?.url }}
                            </span>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="venture__description mt-2">
                      <div
                        class="simple-card__description widget__content"
                        style="font-size: 14px; line-height: 1.2rem"
                      >
                        <p
                          appEllipsis
                          [innerHTML]="org.description"
                          class="m-0"
                          style="min-height: 96px; max-height: 100px"
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="venture__body widget__card--background">
              <div>
                <div class="custom-organization__label" translate>
                  Innovation Focus
                </div>
                <div class="custom-organization__content truncate-line-1">
                  {{ org.innovationFocus?.value }}
                </div>
              </div>
              <div class="mt-2">
                <div class="custom-organization__label" translate>
                  Resident at
                </div>
                <div class="custom-organization__content truncate-line-1">
                  {{ org.residentOf?.value }}
                </div>
              </div>
              <div class="mt-2">
                <div class="custom-organization__label" translate>
                  Key offerings
                  <span
                    [innerHTML]="org.keyOfferings.length | parentheses"
                  ></span>
                </div>
                <div
                  class="custom-organization__content truncate-line-1"
                  appTooltip
                  triggers="manual"
                  #t="ngbTooltip"
                  [toolTipControl]="t"
                  [ngbTooltip]="keyOfferings"
                  tooltipClass="no-limit-width-tooltip"
                >
                  <ng-container
                    *ngFor="let keyOffering of org.keyOfferings; index as i"
                  >
                    <span
                      >{{ keyOffering.description
                      }}{{
                        i + 1 !== org.keyOfferings.length ? ', ' : ''
                      }}</span
                    >
                  </ng-container>

                  <ng-template #keyOfferings>
                    <ul>
                      <li
                        *ngFor="let keyOffering of org.keyOfferings"
                        class="list-inline-item"
                      >
                        <span
                          class="venture__characteristic tooltip-description text-truncate widget__characteristics--background widget__highlight badge badge-pill badge-light"
                        >
                          {{ keyOffering.description }}
                        </span>
                      </li>
                    </ul>
                  </ng-template>
                </div>
              </div>
            </div>
          </a>
          <div
            class="my-2 px-3 custom-organization__social-interact-wrapper d-flex justify-content-between align-items-center"
            [ngClass]="{
              'flex-row-reverse': !org.likesCount
            }"
          >
            <div
              *ngIf="org.likesCount"
              class="custom-organization__social-interact-wrapper--like d-flex align-items-center"
              (click)="showEntityData(org); showLikesModal = !!org?.likesCount"
            >
              <span class="like-icon">
                <i class="fas fa-thumbs-up"></i>
              </span>

              <span class="ml-1">{{ org.likesCount }}</span>
            </div>
            <div
              class="custom-organization__social-interact-wrapper--comment-follower d-flex align-items-center"
              (click)="showEntityData(org); showPeopleModal = true"
            >
              <div *ngIf="org.followerCount" class="d-flex align-items-center">
                <span>{{ org.followerCount }}</span>
                <span class="ml-1">{{
                  'Followers' | translate | lowercase
                }}</span>
              </div>
            </div>
          </div>
          <app-interaction-bar
            entityPath="{{ getEntityUrl(org) }}/{{ org.id }}"
            [httpService]="listService"
            [entity]="org"
            [entityName]="'Organization'"
            [isLoadingLikingsCount]="isLoadingLikingsCount"
            [isLoadingFollowingsCount]="isLoadingFollowingsCount"
            [isLoadingCommentsCount]="isLoadingCommentsCount"
            [hideLikeNumber]="true"
            [useFollowIcon]="true"
            [wrapperClass]="'shadow-none'"
            (triggerToggleLike)="toggleLike(org)"
            (triggerShowLikingsData)="
              showEntityData(org); showLikesModal = !!org?.likesCount
            "
            (triggerShowFollowingsData)="
              showEntityData(org); showPeopleModal = true
            "
            (triggerToggleFavorite)="toggleFavorite(org)"
          >
          </app-interaction-bar>
        </ng-template>
      </div>
    </div>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="searchEntitiesService.changePaging($event)"
      [page]="searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>

    <div class="text-right" *ngIf="show_creation_btn">
      <button
        class="btn btn-primary widget__btn-create my-3"
        [callBackLoginPage]="entityCreationPath"
        appRequireLogin
        translate
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
<ng-template #skeleton>
  <div class="loading-skeleton">
    <div class="row">
      <div
        *ngFor="let item of skeletonCount"
        class="col-12"
        [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="skeleton-item img-skeleton"></div>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <h6></h6>
            <h6></h6>
            <h6></h6>
            <h6></h6>
            <h6></h6>
            <h6 class="w-50 mt-4"></h6>
            <h6></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-events-modal
  *ngIf="showEventsModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Events' | translate"
  [httpService]="listService"
  (dismissModal)="showEventsModal = false"
></app-events-modal>

<app-ventures-modal
  *ngIf="showVenturesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Ventures' | translate"
  [httpService]="listService"
  [globalOrgId]="orgId"
  (dismissModal)="showVenturesModal = false"
></app-ventures-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'UI.Likes.Organization' | translate"
  [httpService]="listService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Followers' | translate"
  [httpService]="listService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>
