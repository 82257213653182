import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { TenantHttpService } from '@core/http/tenant.http.service';
import { I18nService } from '@core/i18n/i18n.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionKeyEnum } from '@shared/enums/session.key.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { StrictlyCookies } from '@shared/enums/strictly-cookies.enum';
import { UrlParam } from '@shared/enums/url-param.enum';
import {
  LanguageInterface,
  TranslationResourceItem,
  TranslationResourcesCache,
} from '@shared/interfaces/language.interface';
import { TenantInterface } from '@shared/interfaces/tenant.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { StringUtils } from '@shared/utils/string-utils';
import { TimeUtils } from '@shared/utils/time-utils';
import { SessionExpirationDialogComponent } from '@src/app/components/dialogs/session-expiration-dialog/session-expiration-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import packageVersion from '../../../package.json';
import { ApplicationService, Version } from './services/application.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  domain = '';
  tenant: TenantInterface;
  apiReady$ = new BehaviorSubject(false); // need to get tenantID, language info
  isLogin = false;
  currentUser: UserInterface;
  isOnListPage = false;
  isOnDetailPage = false;
  isOnHomePage = false;
  isHeaderVisible$ = new BehaviorSubject(false);
  hasTopicFilter = false;
  hasFilterPane = false;
  isOnSearchResultPage = false;
  tenant$ = new BehaviorSubject<TenantInterface>(null);

  get isOnLandingPage() {
    return (
      !this.isOnDetailPage &&
      !this.isOnListPage &&
      !this.isOnHomePage &&
      !this.isOnSearchResultPage
    );
  }

  constructor(
    private cookieService: CookieService,
    private tenantService: TenantHttpService,
    private authenService: AuthenService,
    private i18nService: I18nService,
    private http: HttpClient,
    private readonly applicationService: ApplicationService,
    private modalService: NgbModal
  ) {
    if (!this.getSessionStorageId()) {
      this.setSessionStorageId();
    }

    this.setTenantId('');
    this.setTenantUrl('');
    this.authenService.isLogin().subscribe((islogin) => {
      this.isLogin = islogin;
      if (this.isLogin) {
        document.body.classList.add('client-logged-in');
      }
    });

    this.authenService.getProfile().subscribe((profile) => {
      this.currentUser = profile;
    });
  }

  getCurrentLanguage(): LanguageInterface {
    return this.i18nService.language;
  }

  setCookie(key, value): void {
    this.cookieService.set(key, null, new Date(0), '/');

    const expiredDate = TimeUtils.getDateWithOffset(new Date(), 1);
    this.cookieService.set(key, value, expiredDate, '/');
  }

  getCookie(key): string {
    return this.cookieService.get(key);
  }

  setCookieObject(key, obj = {}): void {
    this.setCookie(key, JSON.stringify(obj));
  }

  getCookieObject(key): object {
    const strData = this.getCookie(key);
    let result;
    try {
      result = strData ? JSON.parse(strData) : null;
    } catch (err) {}

    return result;
  }

  deleteCookie(key): void {
    this.cookieService.delete(key);
    this.cookieService.delete(key, '/');
  }

  setDomain(domain: string): Observable<any> {
    if (domain === this.domain) {
      return;
    }

    this.domain = domain;
    this.tenantService
      .getCurrentTenantId(domain)
      .subscribe((tenant: TenantInterface) => {
        if (tenant) {
          this.tenant = tenant;
          this.setTenantId(tenant.id);
          this.setTenantUrl(tenant.companyURL?.url || '');
          this.setTenantLogo(tenant.logo?.url || '');
          this.setTenantName(tenant.name || '');
          this.setTenantDomain(tenant.domain?.url || '');
          this.setTenantIcon(tenant.icon?.url || '');

          this.tenant$.next(tenant);

          this.i18nService.loadLanguageForTenant().subscribe({
            next: () => {
              this.getTranslationResourceFromBE();

              const isEnabledCookies = this.checkEnabledBrowserCookies();
              if (isEnabledCookies) {
                this.i18nService.checkExternalMatchedDefaultLangAndReload();
              }

              console.log('APP READY: ', isEnabledCookies);
              this.apiReady$.next(isEnabledCookies);

              if (this.isLogin) {
                this.authenService.updateProfileInfo(true);
              }

              this.getSocialMedia();
            },
            error: (error) => {
              console.log('...READY: ', false);
              this.apiReady$.next(false);
            },
          });
        }
      });
  }
  setTenantName(name: string) {
    this.setCookie(SessionKeyEnum.TENANT_NAME, name);
  }

  setTenantDomain(domain: string) {
    this.setCookie(SessionKeyEnum.TENANT_DOMAIN, domain);
  }

  setSessionStorageId(): void {
    const timestamp = Date.now().toString();

    sessionStorage.setItem(StorageEnum.sessionId, timestamp);
  }

  getSessionStorageId(): string {
    return sessionStorage.getItem(StorageEnum.sessionId);
  }

  private getTranslationResourceFromBE(): void {
    const currentTranslationResources = localStorage.getItem(
      StorageEnum.translationResources
    );
    const currentLocale = this.i18nService.language?.locale;
    let currentVersion = packageVersion.version;
    this.applicationService
      .getServiceVersion()
      .pipe(
        mergeMap((version: Version) => {
          currentVersion = version.version;
          return this.handleTranslationByVersion(
            currentTranslationResources,
            version.version,
            currentLocale
          );
        }),
        catchError(() => {
          // if get version from BE false, use static version from FE to check
          return this.handleTranslationByVersion(
            currentTranslationResources,
            packageVersion.version,
            currentLocale
          );
        })
      )
      .subscribe({
        next: (data: TranslationResourceItem[]) => {
          if (data?.length > 0) {
            const translateData: Record<string, string> = {};
            const filterUITranslation = data.filter(
              (x) => x.key.includes('UI.') || x.key.includes('Label.Tooltip.')
            );
            filterUITranslation.forEach((element: TranslationResourceItem) => {
              translateData[element.key] = element.value;
            });
            const cacheTranslationResoures: TranslationResourcesCache = {
              version: currentVersion,
              locale: currentLocale,
              data: translateData,
            };
            localStorage.setItem(
              StorageEnum.translationResources,
              JSON.stringify(cacheTranslationResoures)
            );
            this.i18nService.appendTranslationResourceFromBE(translateData);
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private handleTranslationByVersion(
    currentTranslationResources: string,
    currentVersion: string,
    currentLocale: string
  ): Observable<TranslationResourceItem[]> {
    if (currentTranslationResources) {
      const currentCacheTranslationResoures: TranslationResourcesCache =
        JSON.parse(currentTranslationResources);
      if (
        currentCacheTranslationResoures.version === currentVersion &&
        currentCacheTranslationResoures.locale === currentLocale
      ) {
        this.i18nService.appendTranslationResourceFromBE(
          currentCacheTranslationResoures.data
        );
        return of(null);
      }
    }
    const url = 'resources/all';
    return this.http.get<TranslationResourceItem[]>(url);
  }

  private checkEnabledBrowserCookies(): boolean {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = 'jiptestcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled;
  }

  appendLanguagePath(url: string): string {
    if (
      url &&
      url.startsWith(`/`) &&
      !url.includes(this.i18nService.languagePath)
    ) {
      return this.i18nService.languagePath + url;
    }
    return url;
  }

  setTenantId(id: number | string): void {
    this.setCookie(SessionKeyEnum.TENANT_ID, id);
  }

  setTenantUrl(url: string): void {
    this.setCookie(SessionKeyEnum.TENANT_URL, url);
  }

  setTenantLogo(imgUrl: string): void {
    this.setCookie(SessionKeyEnum.TENANT_LOGO, imgUrl);
  }

  setTenantIcon(imgUrl: string): void {
    this.setCookie(SessionKeyEnum.TENANT_ICON, imgUrl);
  }

  getTenantId(): string {
    return (
      this.cookieService.get(SessionKeyEnum.TENANT_ID) ||
      this.tenant?.id?.toString()
    );
  }

  getTenantUrl(): string {
    return (
      this.cookieService.get(SessionKeyEnum.TENANT_URL) ||
      this.tenant?.companyURL?.url
    );
  }

  getTenantLogo(): string {
    return (
      this.cookieService.get(SessionKeyEnum.TENANT_LOGO) ||
      this.tenant?.logo?.url
    );
  }

  getLoginCallBackpage(): string {
    return this.cookieService.get(StorageEnum.callBackLoginPage);
  }

  setLoginCallBackpage(url: string): void {
    const urlWithoutFilterToken = StringUtils.removeParamByKey(
      url,
      UrlParam.FilterTokenId
    );

    this.setCookie(StorageEnum.callBackLoginPage, urlWithoutFilterToken);
  }

  deleteLoginCallBackpage(): void {
    this.cookieService.delete(StorageEnum.callBackLoginPage, '/');
  }

  deleteGlobalFilterCookies(): void {
    this.cookieService.delete(StorageEnum.globalFilters, '/');
  }

  getAcceptedCookies(): string {
    return this.cookieService.get(StorageEnum.acceptedCookies);
  }

  setAcceptedCookies(): void {
    this.setCookie(StorageEnum.acceptedCookies, 'true');
  }

  getStrictlyCookies(): StrictlyCookies {
    const strictlyCookies = this.cookieService.get(
      StorageEnum.activeStrictlyCookies
    );
    return strictlyCookies as StrictlyCookies;
  }

  setStrictlyCookies(isActive: StrictlyCookies): void {
    this.setCookie(StorageEnum.activeStrictlyCookies, isActive.toString());
  }

  deleteStrictlyCookies(): void {
    this.cookieService.delete(StorageEnum.activeStrictlyCookies, '/');
  }

  isTokenExpired(): boolean {
    const expiredTokenTime = this.cookieService.get(
      StorageEnum.expiredTokenTime
    );
    return (
      Number(expiredTokenTime) <=
      TimeUtils.convertUtcToLocalTimeInSeconds(new Date(), 60)
    );
  }

  getSocialMedia(): void {
    const url = 'people/socialmedia';

    this.http.get(url).subscribe((data) => {
      localStorage.setItem(
        StorageEnum.socialMediaResources,
        JSON.stringify(data)
      );
    });
  }

  onSessionExpire(): void {
    this.modalService.dismissAll();
    this.modalService.open(SessionExpirationDialogComponent, {
      centered: true,
      backdrop: 'static',
    });
  }
}
