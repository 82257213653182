import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NewPostDialogComponent } from '@components/news-messages/components/new-post-dialog/new-post-dialog.component';
import { EntityCreationModalService } from '@core/services/entity-creation-modal.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  EntityName,
  EntityStateCount,
  MessageAction,
  MessageType,
} from '@shared/enums/entity-name.enum';
import { GlobalEventBus } from '@shared/enums/event-bus.enum';
import { NewsMessagesFilterType as Filter } from '@shared/enums/filter.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  NewsMessageInterface,
  NewsMessageType,
} from '@shared/interfaces/news-message.interface';
import { MessageUIService } from '@shared/services/message-ui.service';
import {
  applyFilterByWidgetConfig,
  applyFilterStreamByWidgetConfig,
  applyFollowingOrLikeFilter,
} from '@shared/utils/filter.utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { NewsMessagesHttpService } from '@src/app/core/http/news-messages-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { SessionService } from '@src/app/core/session.service';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { NewsMessagesFilterType as FilterType } from '@src/app/shared/enums/filter.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import {
  EntityGlobalFilterCriteria,
  GlobalFilterCriteria,
} from '@src/app/shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, filter, skipUntil, take } from 'rxjs/operators';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { NewMessageDialogComponent } from './components/new-message-dialog/new-message-dialog.component';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-news-messages',
  templateUrl: './news-messages.component.html',
  providers: [
    { provide: SendMessageHttpService, useClass: SendMessageHttpService },
  ],
})
export class NewsMessagesComponent
  extends BaseListComponent<NewsMessageInterface>
  implements OnInit, OnChanges, OnDestroy
{
  // tslint:disable-next-line:variable-name
  @Input() ui_template: TemplateName = TemplateName.Default;

  // tslint:disable-next-line:variable-name
  @Input() filter_selection: string;

  @Input() token: string;

  @Input() newsAndMessagesTab: string;

  @Input() stream: string;

  MESSAGE_TYPE = MessageType;
  ENTITY_NAME = EntityName;
  MESSAGE_ACTION = MessageAction;
  peopleURL = environment.jipUrl.people;
  entityName = EntityName.News;

  currentFilter = Filter.News;
  filterType = Filter;

  currentUser: UserInterface;

  page = 1;
  pageSize = 8;

  showFollowersModal = false;
  showLikesModal = false;

  isLogin$ = this.authenService.isLogin$;

  hasFilterByOrg = false;

  private newsMessageCreationService = this.injector.get(
    EntityCreationModalService
  );

  constructor(
    public listService: NewsMessagesHttpService,
    public messageHttpService: SendMessageHttpService,
    protected messageUIService: MessageUIService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector,
    private entityCreationModalService: EntityCreationModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((userContext: UserInterface) => {
        this.currentUser = userContext;

        if (userContext) {
          this.displayMessageByTokenLink();
        }
      });

    this.sessionService.isOnHomePage = true;
    this.hasFilterByOrg = true;

    this.listenFilterChanged();
    this.listenToCreatePostBtnClick();
    this.listenToEditedPostEvent();
    this.listenToDeletedPostEvent();
    this.listenToPostCreated();

    const currentTab = StringUtils.getParamFromUrl(UrlParam.TabNews);

    if (currentTab) {
      this.currentFilter = Filter.Message;
    }

     // Log raw URL first để debug
  console.log('Current URL:', window.location.href);
  
  // Case 1: Use URLSearchParams
  const urlParams = new URLSearchParams(window.location.search);
  console.log('Direct URL Params:', {
    pageSize: urlParams.get('pageSize'),
    pageIndex: urlParams.get('pageIndex'),
    category: urlParams.get('category')
  });

  // Case 2: Use route.queryParamMap with error handling
  this.route.queryParamMap.pipe(
    filter(params => params.keys.length > 0)
  ).subscribe({
    next: (params) => {
      console.log('Full params object:', params);
      console.log('All available keys:', params.keys);
      console.log('Params updated:', {
        pageSize: params.get('pageSize'),
        pageIndex: params.get('pageIndex'),
        category: params.get('category')
      });
    },
    error: (error) => {
      console.error('Error getting params:', error);
    }
  });

  // Case 3: Decode URL manually
  try {
    const decodedUrl = decodeURIComponent(window.location.href);
    const searchParams = new URL(decodedUrl).searchParams;
    console.log('Decoded URL Params:', {
      pageSize: searchParams.get('pageSize'),
      pageIndex: searchParams.get('pageIndex'),
      category: searchParams.get('category')
    });
  } catch (error) {
    console.error('Error decoding URL:', error);
  }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.newsAndMessagesTab) {
      if (this.newsAndMessagesTab === FilterType.Message) {
        this.currentFilter = FilterType.Message;
        StringUtils.setQueryParamOnUrl(UrlParam.TabNews, 'message');
      } else {
        this.currentFilter = FilterType.News;
      }
    }
  }

  listenFilterChanged(): void {
    if (this.ui_template === TemplateName.Default) return;

    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(
        skipUntil(this.globalLoadingService.getLoadingState()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.handleLoading(true);

        this.loadData();
      });
  }

  openNewMessage(params = {}): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(NewMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    // Transfer params to modal
    modalRef.componentInstance.params = {
      ...params,
    };

    modalRef.componentInstance.shouldAddNewItemToList =
      this.currentFilter === Filter.Message;
    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;
      this.paginateFilteredItems();
    });
  }

  openCreatePostModal(): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(NewPostDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: 'message-modal-custom post-modal-custom',
    });

    modalRef.componentInstance.globalFilterCriteria =
      this.entityGlobalFilterCriteria;

    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;
      this.paginateFilteredItems();
    });
  }

  openLikesModal(item: NewsMessageInterface): void {
    this.showEntityData(item);
    this.showLikesModal = true;
  }

  createEntity(event: Event, handler?: any): void {
    if (this.isLogin$) {
      this.entityCreatepath = '';
    }
    super.createEntity(event, this.openNewMessage());
  }

  paginateFilteredItems(): void {
    const filter = {
      ...this.entityGlobalFilterCriteria,
      filterType: this.currentFilter,
    };

    super.paginateFilteredItems(filter);
  }

  onFilterChange(filter: Filter): void {
    this.currentFilter = filter;

    filter === Filter.Message &&
      delete this.entityGlobalFilterCriteria.isMyFollowingOrLiked;

    if (filter === Filter.Message) {
      StringUtils.setQueryParamOnUrl(UrlParam.TabNews, 'message');
    } else {
      StringUtils.removeParamFromUrl(UrlParam.TabNews);
    }

    this.paginateFilteredItems();
  }

  async onSubfilterChange(filter: Filter): Promise<void> {
    this.entityGlobalFilterCriteria.isMyFollowingOrLiked =
      filter === Filter.FollowingOrLike;

    const isHeaderVisible = await firstValueFrom(
      this.sessionService.isHeaderVisible$
    );

    this.hasFilterByOrg = !isHeaderVisible || filter === Filter.OrganizationIds;

    this.paginateFilteredItems();
  }

  isShowAsNoReplyMessageYet(message: NewsMessageInterface): boolean {
    if (
      message.messageType !== MessageType.Message &&
      message.action !== MessageAction.GetInTouch
    ) {
      return false;
    }

    if (
      (message?.content?.entityName as string) ===
      EntityName.VentureSubmittedToChallenge
    ) {
      return false;
    }

    return message?.latestMessage
      ? this.currentUser?.id !== message.latestMessage?.from?.id
      : this.currentUser?.id !== message?.from?.id;
  }

  syncCommentStateCount(news: NewsMessageInterface): void {
    this.syncEntityStateCount(
      EntityStateCount.Comment,
      EntityName.News,
      [news],
      this.listService
    );
  }

  updatePageFilters(payload: GlobalFilterCriteria): void {
    // Keep news additional filter (isMyFollowingOrLiked)
    if (
      this.entityGlobalFilterCriteria?.hasOwnProperty('isMyFollowingOrLiked')
    ) {
      const additionalFilter =
        this.entityGlobalFilterCriteria.isMyFollowingOrLiked;

      super.updatePageFilters(payload);

      this.entityGlobalFilterCriteria.isMyFollowingOrLiked = additionalFilter;
    } else {
      super.updatePageFilters(payload);
    }
  }

  protected getFilterDto(
    filterCriteria: EntityGlobalFilterCriteria
  ): EntityGlobalFilterCriteria {
    applyFollowingOrLikeFilter(this.currentFilter, filterCriteria);
    applyFilterByWidgetConfig(this.filter_selection, filterCriteria);
    applyFilterStreamByWidgetConfig(this.stream, filterCriteria);
    return super.getFilterDto(filterCriteria);
  }

  protected getPagingMethod(
    filterDto: EntityGlobalFilterCriteria
  ): Observable<ApiGetResponse<NewsMessageInterface>> {
    return this.listService.paginateX(filterDto, 'all');
  }

  protected updateEntityList(res: ApiGetResponse<NewsMessageInterface>): void {
    super.updateEntityList(res, false);

    this.items?.forEach((item: NewsMessageInterface) => {
      let obsNewsMessageDetail$: Observable<NewsMessageInterface>;
      if (item.type === NewsMessageType.Message) {
        obsNewsMessageDetail$ = this.messageHttpService.read(
          item.id,
          null
        ) as unknown as Observable<NewsMessageInterface>;
      } else {
        obsNewsMessageDetail$ = this.listService.read(item.id, null);
      }

      if (obsNewsMessageDetail$) {
        obsNewsMessageDetail$
          .pipe(
            take(1),
            catchError(() => {
              return of(null);
            })
          )
          .subscribe((newMesageDetail: NewsMessageInterface) => {
            if (newMesageDetail) {
              const updatedItemIndex = this.items.findIndex(
                (x) => x.id === newMesageDetail.id
              );
              if (updatedItemIndex > -1) {
                const entityName =
                  this.items[updatedItemIndex].type === NewsMessageType.News
                    ? EntityName.News
                    : EntityName.Message;
                this.items[updatedItemIndex] = newMesageDetail;
                this.syncEntityStateCount(
                  EntityStateCount.Liking,
                  entityName,
                  [this.items[updatedItemIndex]],
                  this.listService
                );
                if (entityName === EntityName.News) {
                  this.syncEntityStateCount(
                    EntityStateCount.Comment,
                    entityName,
                    [this.items[updatedItemIndex]],
                    this.listService
                  );
                }
              }
            }
          });
      }
    });
  }

  protected mappingResponseToListData(
    res: ApiGetResponse<NewsMessageInterface>
  ): void {
    this.items = res.items.map((resNewsMessage: NewsMessageInterface) => {
      return {
        ...resNewsMessage,
        isLoading: true,
      };
    });
  }

  /* Private */
  private displayMessageByTokenLink(): void {
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((ready: boolean) => {
        const messageKey = localStorage.getItem(StorageEnum.tokenMessageKey);
        if (messageKey) {
          const messageKeyObj = JSON.parse(messageKey);
          const { messageId } = messageKeyObj;

          if (ready) {
            this.messageUIService.openMessage(Number(messageId), true);
            localStorage.removeItem(StorageEnum.tokenMessageKey);
          }
        }
      });
  }

  private listenToCreatePostBtnClick(): void {
    this.createPostBtnClickEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.CreatePost,
      callback: (event: Event) => {
        if (this.isLogin$) {
          this.entityCreatepath = '';
        }
        super.createEntity(event, this.openCreatePostModal());
      },
    });
  }

  private listenToEditedPostEvent(): void {
    this.newsMessageCreationService.postEdited$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.paginateFilteredItems());
  }

  private listenToDeletedPostEvent(): void {
    this.newsMessageCreationService.postDeleted$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.paginateFilteredItems());
  }

  private listenToPostCreated(): void {
    this.entityCreationModalService.newsMessageCreated$
      .pipe(
        filter((entityName: EntityName) => entityName === EntityName.Post),
        untilDestroyed(this)
      )
      .subscribe(() => this.paginateFilteredItems());
  }
}
