export enum GlobalEventBus {
  GlobalFilterEvent = 'GLOBAL_FILTER_EVENT',
  AddNewItemToList = 'UPDATE_LIST_ITEMS',
  ListItemUpdate = 'MESSAGE_ITEM_UPDATE',
  EntityCreatePathUpdate = 'ENTITY_CREATE_PATH_UPDATE',
  CreatePost = 'CREATE_POST',
  ViewPost = 'VIEW_POST',
  GlobalFilterSelectionEvent = 'GLOBAL_FILTER_SELECTION_EVENT',
  FilterPaneEvent = 'FILTER_PANE_EVENT',
  SortEntityList = 'SORT_ENTITY_LIST',
  ChangeEntityListView = 'CHANGE_ENTITY_LIST_VIEW'
}
