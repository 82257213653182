import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { UserInterface } from '../../interfaces/user.interface';

@Component({
  selector: 'app-venture-people',
  templateUrl: './venture-people.component.html',
})
export class VenturePeopleComponent {
  @Input() initiator: UserInterface;
  @Input() stakeholders: UserInterface[] = [];
  @Input() showLabel = true;
  @Input() showOnlyStakeholder = false;
  @Input() fromTableCell = false;
  @Input() numberOfDisplays = 2;
  @Input() styleClass = '';
  @Input() labelTranslateKey = 'Initiator';

  personBaseUrl = environment.jipUrl.people;
}
