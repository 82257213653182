import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import {
  TagFieldCustom,
  transformToTagModal,
} from '@shared/components/tag-field/tag-field.component';
import { AttributeType } from '@shared/enums/attribute-type.enum';
import { EntityName } from '@shared/enums/entity-name.enum';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { VentureSubmissionStatus } from '@shared/enums/venture.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  AttributeDescription,
  CustomAtrributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { SharedVenturesFilter } from '@shared/interfaces/filters/venture-filter.interface';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { AutocompleteRequestInterface } from '@shared/interfaces/request/autocomplete-request.interface';
import {
  ApiGetResponse,
  VentureWithCountingResponse,
} from '@shared/interfaces/responses/ApiResponse.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { Entity } from '@shared/models/entity.model';
import {
  PageSizeConfig,
  PaginationFilterStored,
  PaginationSettingPlace,
  SortCriteria,
} from '@shared/models/pagination.model';
import { SortingVenturesService } from '@src/app/components/organizations/services/sorting-ventures.service';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { PaginationSettingService } from '@src/app/core/services/pagination-setting.service';
import { NewsMessagesFilterType } from '@src/app/shared/enums/filter.enum';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-shared-ventures',
  templateUrl: './shared-ventures.component.html',
  providers: [CustomFormService],
})
export class SharedVenturesComponent
  extends EntityStateCountComponent
  implements OnInit, OnChanges
{
  @Input() parentEntityId: number = null;
  @Input() parentEntityName: EntityName;
  @Input() entityData: VentureInterface[] = [];
  @Input() entityPagination: {
      page: 1,
      pageSize: 8,
      collectionSize: 0
  };
  @Input() isParentEntityOwner = false;
  @Input() httpService: BaseHttpService<Entity>;
  @Input() allowUpdateSubmissionStatus: boolean;
  @Input() isGridView = true;
  @Input() showFilter = false;
  @Input() paginationSettingPlace: PaginationSettingPlace;
  @Input() isTableExpanded = false;
  @Input() allowPaginationWarning: boolean;
  @Input() cancelSection$;
  @Input() isShowAsChallenge = false;
  @Input() isAdmin = false;
  @Input() showSubmitMenu: boolean;
  @Input() globalOrgId = '';

  @Output() ventureCount = new EventEmitter<number>();
  @Output() isGridViewChange = new EventEmitter<boolean>();
  @Output() showFilterChange = new EventEmitter<boolean>();
  @Output() isTableExpandedChange = new EventEmitter<boolean>();

  @Output() confirmSaving = new EventEmitter();

  items: VentureInterface[] = [];
  submissionStatuses: CustomAtrributeValue[] = [];
  submissionStatusAttrDes: AttributeDescription;

  selectedOrgs: TagFieldCustom[] = [];
  sharedVenturesfilter: SharedVenturesFilter = {
    pageIndex: 1,
    pageSize: 4,
    keyword: '',
    organizations: [],
    sortingAttribute: null,
    submissionStatus: 'All',
  };
  collectionSize = 0;

  showPeopleModal = false;
  selectedEntityId: number;
  selectedEntity: VentureInterface;

  isSearching: boolean;
  isPageChanged = false;

  VentureSubmissionStatus = VentureSubmissionStatus;
  EntityName = EntityName;
  OrgsSearchMode = OrgsSearchMode;
  AttributeType = AttributeType;

  areVentureStatesHaveModified = false;

  ventureStateCount: Record<string, number> = {};

  term$ = new BehaviorSubject<string>('');
  virtualScrollItems: any[] = [];
  totalScrollItem = 0;
  currentPageIndex = 1;
  searchText = '';
  isLoadMore = true;
  isSearchInputFocused = false;
  isFirstTimeSearchOnClick = false;
  isDropdownOpen = false;
  isSearchingOrg = false;
  isFullTextSearch = true;
  showSearchResultText$ = new BehaviorSubject<string>('');

  sortingVenturesService: SortingVenturesService;

  @ViewChild('modalRef') modalRef: ElementRef;

  constructor(
    public readonly ventureHttpService: VentureHttpService,
    protected readonly organizationHttpService: OrganizationHttpService,
    protected readonly paginationSettingservice: PaginationSettingService,
    protected readonly modalService: NgbModal,
    protected customFormService: CustomFormService,
    public injector: Injector
  ) {
    super();

    this.sortingVenturesService = this.injector.get(SortingVenturesService);
  }

  ngOnInit(): void {
    this.ventureHttpService
      .getAttributeDescription()
      .pipe(untilDestroyed(this))
      .subscribe((descriptions: MetaInformation) => {
        this.customFormService.setControlConfig(descriptions);
      });

    this.term$
      .pipe((term) => {
        return this.autocomplete(term);
      }, untilDestroyed(this))
      .subscribe((items) => {
        this.virtualScrollItems = [];
        this.virtualScrollItems = this.virtualScrollItems.concat(items);
      });
  }

  autocomplete(text$: Observable<string>): Observable<LeadCompany[]> {
    return text$.pipe(
      filter(() => this.isSearchInputFocused),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(() => {
        this.isLoadMore = true;
        this.currentPageIndex = 1;
        return this.getOrgs();
      })
    );
  }

  onSelectOrg(item) {
    this.searchText = item.orgName;
    this.searchByOrg([item]);
    this.closeDropdown();
  }

  onChangeSearchText(): void {
    this.term$.next(this.searchText);
  }

  onFocusSearchInput(): void {
    this.openDropdown();
    this.isSearchInputFocused = true;
    this.isFirstTimeSearchOnClick = true;
  }

  onBlurSearchInput(): void {
    this.isSearchInputFocused = false;
    this.isFirstTimeSearchOnClick = false;
  }

  onClickSearchInput(): void {
    if (
      this.isSearchInputFocused &&
      this.isFirstTimeSearchOnClick &&
      !this.isSearchingOrg
    ) {
      this.isLoadMore = true;
      this.currentPageIndex = 1;

      const obs = this.getOrgs();
      if (obs) {
        obs.pipe(untilDestroyed(this)).subscribe((items: LeadCompany[]) => {
          this.virtualScrollItems = [];
          this.virtualScrollItems = this.virtualScrollItems.concat(items);
          this.isFirstTimeSearchOnClick = false;
        });
      }
    }
  }

  resetSearchText(): void {
    this.searchText = '';
    this.searchByOrg([]);
    this.closeDropdown();
  }

  onClickOutSide(): void {
    this.onBlurSearchInput();
    this.closeDropdown();
  }

  loadMore(event: IPageInfo) {
    if (
      event.endIndex !== this.virtualScrollItems.length - 1 ||
      this.isSearchingOrg ||
      !this.isLoadMore
    ) {
      return;
    }

    this.currentPageIndex++;
    this.getOrgs()
      .pipe(untilDestroyed(this))
      .subscribe((items: LeadCompany[]) => {
        if (items?.length === 0) {
          this.isLoadMore = false;
          return;
        }
        this.virtualScrollItems = this.virtualScrollItems.concat(items);
      });
  }

  getOrgs() {
    this.isSearchingOrg = true;
    const params: AutocompleteRequestInterface = {
      pageIndex: this.currentPageIndex,
      pageSize: 20,
      keyword: this.searchText,
      entityId: this.parentEntityId,
      entityName: this.parentEntityName,
    };

    return this.organizationHttpService
      .paginateX(params, OrgsSearchMode.ForFilterVenture)
      .pipe(
        finalize(() => (this.isSearchingOrg = false)),
        untilDestroyed(this),
        map((res: ApiGetResponse<OrganizationInterface>) => {
          this.totalScrollItem = res.rowCount;
          return res.items;
        })
      );
  }

  onSearchTypeChange(value) {
    if (value) {
      this.searchByOrg([]);
      this.searchText = '';
    } else {
      this.onResetSearchText();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.cancelSection$?.currentValue) {
      this.cancelSection$
        .pipe(untilDestroyed(this))
        .subscribe(() => (this.areVentureStatesHaveModified = false));
    }

    if (
      changes.parentEntityId &&
      changes.parentEntityId.currentValue &&
      changes.parentEntityId.firstChange
    ) {
      await this.updateFilterFromPaginationFilterStored();
    }

    if (
      changes.isTableExpanded &&
      changes.isTableExpanded.previousValue !== undefined
    ) {
      if (changes.isTableExpanded.currentValue) {
        this.modalService
          .open(this.modalRef, {
            centered: true,
            backdrop: 'static',
            windowClass: 'modal-fullscreen shared-ventures-component',
          })
          .result.finally(() => {
            this.emitTableExpanded(false);
          });

        if (this.isGridView) {
          this.isGridView = false;
          this.isGridViewChange.emit(this.isGridView);
        }
      }
      return;
    }

    if (
      changes.showFilter &&
      changes.showFilter.previousValue !== undefined &&
      changes.showFilter.previousValue !== changes.showFilter.currentValue
    ) {
      return;
    }

    if (
      changes.isGridView &&
      changes.isGridView.currentValue &&
      !changes.isGridView.firstChange
    ) {
      this.sharedVenturesfilter.sortingAttribute = null;
    }

    if (this.allowUpdateSubmissionStatus !== undefined && this.httpService) {
      this.setSubmissionStatuses();
    }

    this.sortingVenturesService
      .getSortingAttribute()
      .subscribe((res: SortCriteria) => {
        if (res) {
          this.onSort(res);
        } else {
          this.sharedVenturesfilter.sortingAttribute = null;
          this.paginateItems();
        }
      });
  }

  handleSyncEntityStateCount(): void {
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncCheckUserIsOrgAdminOfEntity();
  }

  showEntityData(entity: VentureInterface): void {
    this.showPeopleModal = true;
    if (entity) {
      this.selectedEntityId = null;
      this.selectedEntity = null;
      setTimeout(() => {
        this.selectedEntityId = entity.id;
        this.selectedEntity = entity;
      });
    }
  }

  paginateItems(): void {
    if (!this.parentEntityId || !this.httpService) {
      return;
    }
    const params: HttpParams = this.buildPaginationParams();

    this.isSearching = true;
    this.httpService
      .paginateByEntity(
        this.getApiPath(),
        this.parentEntityId,
        params,
        this.getEntityPath()
      )
      .pipe(
        tap((res) => this.updateVentureStateCount(res)),
        map((res) => this.extractVenturesFromResponse(res)),
        untilDestroyed(this),
        finalize(() => {
          this.isSearching = false;
          this.showSearchResultText$.next(params.get('keyword'));
        })
      )
      .subscribe((res: ApiGetResponse<VentureInterface>) => {
        this.items = res?.items;

        if (!this.items) return;

        // Initialize lastSubmissionStatus for all venture
        this.items.forEach(({ currentSubmission }) => {
          if (currentSubmission) {
            currentSubmission.lastSubmissionStatus = {
              ...currentSubmission.submissionStatus,
            };
          }
        });

        this.collectionSize = res?.rowCount;
        this.ventureCount.emit(res?.rowCount);

        if (this.isPageChanged) {
          this.isPageChanged = false;

          setTimeout(() => {
            this.scrollTo1stItem();
          }, 100);
        }
        this.runEntityStateCount(
          EntityName.Venture,
          this.items,
          this.ventureHttpService
        );
        this.setLatestFilterToPaginationFilterStored();
      });
  }

  onPageChange(pageNumber: number): void {
    this.sharedVenturesfilter.pageIndex = pageNumber;
    this.isPageChanged = true;
    this.paginateItems();
  }

  onPageSizeChange(pageSize: number): void {
    this.sharedVenturesfilter.pageSize = pageSize;
    this.isPageChanged = true;
    this.paginateItems();
  }

  onSort(sortCriteria: SortCriteria): void {
    this.sharedVenturesfilter.sortingAttribute = sortCriteria;
    this.paginateItems();
  }

  onResetSearchText(): void {
    this.sharedVenturesfilter.keyword = '';
    this.searchVenture();
  }

  searchVenture(): void {
    this.sharedVenturesfilter.pageIndex = 1;
    this.paginateItems();
  }

  searchByOrg(items: OrganizationInterface[]): void {
    this.sharedVenturesfilter.pageIndex = 1;
    const filterOrg = (items[1] ?? items[0]) || null;
    this.sharedVenturesfilter.organizations = filterOrg
      ? [
          {
            display: filterOrg.orgName,
            value: filterOrg.id,
          },
        ]
      : [];
    this.paginateItems();
  }

  selectSubmissionStatus(
    ventureId: number,
    status: CustomAtrributeValue
  ): void {
    this.areVentureStatesHaveModified = true;

    const venture = this.items.find(
      (item) => item.currentSubmission && item.id === ventureId
    );

    if (!venture) return;

    venture.currentSubmission.submissionStatus = status;
  }

  getSubmissionStatusIndex(value: CustomAtrributeValue): number {
    let idx = 0;

    if (!value) {
      return idx;
    }

    idx = this.submissionStatuses.findIndex(
      (status) => status.codeId === value.codeId
    );

    return idx;
  }

  shouldShowItem(item: VentureInterface): boolean {
    return (
      item.currentSubmission?.submissionStatus.codeId !==
        VentureSubmissionStatus.Refused || this.isParentEntityOwner
    );
  }

  searchOrg(text: string): Observable<TagFieldCustom[]> {
    const params: AutocompleteRequestInterface = {
      pageIndex: 1,
      pageSize: 20,
      keyword: text,
      entityId: this.parentEntityId,
      entityName: this.parentEntityName,
    };

    return this.organizationHttpService
      .paginateX(params, OrgsSearchMode.ForFilterVenture)
      .pipe(
        untilDestroyed(this),
        map((res: ApiGetResponse<OrganizationInterface>) => {
          const orgs: Record<string, any>[] = res.items;
          if (orgs?.length > 0) {
            orgs.forEach(
              (org: Record<string, any>) => (org.name = org.orgName)
            );
          }
          return transformToTagModal(orgs);
        })
      );
  }

  handleAddedOrg(org: TagFieldCustom): void {
    if (this.selectedOrgs.length > 0) {
      this.selectedOrgs = [org];
    }
  }

  afterLeaveVenture(isLeaveSuccess: boolean): void {
    if (isLeaveSuccess) {
      this.paginateItems();
    }
  }

  afterCommentModalClosed(): void {
    this.syncCommentsState();
  }

  onConfirmSave(isSave: boolean): void {
    this.areVentureStatesHaveModified = false;
    isSave && this.confirmSaving.emit();
  }

  onVenturesFilterChanged(event: VentureSubmissionStatus): void {
    this.sharedVenturesfilter.submissionStatus = event;
    this.paginateItems();
  }

  refreshVentures(): void {
    this.onVenturesFilterChanged(
      this.sharedVenturesfilter.submissionStatus as VentureSubmissionStatus
    );
  }

  private emitTableExpanded(value: boolean): void {
    this.isTableExpanded = value;
    this.paginateItems();
    this.isTableExpandedChange.emit(this.isTableExpanded);
  }

  /* Private */
  private scrollTo1stItem(): void {
    let element: HTMLElement = document.querySelector('.shared-entity > .item');

    if (!this.isGridView) {
      const elementRow = document.querySelectorAll(
        'app-shared-ventures app-metadata-table-venture datatable-row-wrapper'
      )[1];
      if (elementRow) {
        element = elementRow as HTMLElement;
      }
    }

    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  private async updateFilterFromPaginationFilterStored(): Promise<void> {
    if (this.paginationSettingPlace) {
      const currentFilterStored =
        await this.paginationSettingservice.getPaginationFilterStored(
          this.paginationSettingPlace
        );

      if (
        currentFilterStored &&
        currentFilterStored?.parentEntityId === this.parentEntityId
      ) {
        const keepFilter = {
          ...currentFilterStored.keepFilter,
        } as SharedVenturesFilter;
        this.sharedVenturesfilter = keepFilter;
        this.selectedOrgs = keepFilter.organizations;
        this.searchText = (keepFilter.organizations as any)[0]?.display || '';
        this.isGridView = currentFilterStored.isGridView;
        this.isGridViewChange.emit(this.isGridView);
        this.showFilter = true;
        this.showFilterChange.emit(this.showFilter);

        if (keepFilter.organizations.length > 0) {
          this.isFullTextSearch = false;
        }
      }
    } else {
      const currentGlobal =
        await this.paginationSettingservice.getPaginationSettings(
          PageSizeConfig.FourItemsFirstPage
        );

      if (!isNaN(currentGlobal?.keepPageSize)) {
        this.sharedVenturesfilter.pageSize = currentGlobal?.keepPageSize;
      }
    }
  }

  private setLatestFilterToPaginationFilterStored(): void {
    if (this.paginationSettingPlace) {
      const paginationFilterStored = new PaginationFilterStored(
        this.sharedVenturesfilter,
        this.paginationSettingPlace,
        PageSizeConfig.FourItemsFirstPage,
        this.isGridView,
        this.parentEntityId
      );
      this.paginationSettingservice.setPaginationFilterStored(
        paginationFilterStored
      );
    }
  }

  private setSubmissionStatuses(): void {
    this.httpService
      .getSubmissionAttributes()
      .pipe(untilDestroyed(this))
      .subscribe((data: MetaInformation) => {
        if (!data) {
          return;
        }

        const { attributeDescriptions } = data.entityDescription;
        this.submissionStatusAttrDes = attributeDescriptions.find(
          (attribute) => attribute.propertyName === 'Status'
        );

        if (this.submissionStatusAttrDes) {
          this.submissionStatuses =
            this.submissionStatusAttrDes.choice.selections;
        }
      });
  }

  private buildPaginationParams(): HttpParams {
    let params = new HttpParams()
      .set('pageIndex', this.sharedVenturesfilter.pageIndex.toString())
      .set('pageSize', this.sharedVenturesfilter.pageSize.toString())
      .set('keyword', this.sharedVenturesfilter.keyword.toString());

    if (this.sharedVenturesfilter.organizations.length > 0) {
      params = params.set(
        NewsMessagesFilterType.OrganizationIds,
        this.sharedVenturesfilter.organizations[0].value
      );
    }

    if (this.sharedVenturesfilter.sortingAttribute) {
      params = params
        .set(
          'SortingAttribute.PropertyName',
          this.sharedVenturesfilter.sortingAttribute.propertyName
        )
        .set(
          'SortingAttribute.SortingDirection',
          this.sharedVenturesfilter.sortingAttribute.sortingDirection
        );
    }

    if (
      this.isShowAsChallenge &&
      this.sharedVenturesfilter.submissionStatus &&
      this.sharedVenturesfilter.submissionStatus !== 'All'
    ) {
      params = params.set('state', this.sharedVenturesfilter.submissionStatus);
    }

    return params;
  }

  private getApiPath(): string {
    return this.isShowAsChallenge
      ? `${environment.jipUrl.venture}/submission-state`
      : environment.jipUrl.venture;
  }

  private getEntityPath(): string {
    return this.isShowAsChallenge ? 'challenges' : '';
  }

  private extractVenturesFromResponse(res): ApiGetResponse<VentureInterface> {
    return this.isShowAsChallenge
      ? (res as VentureWithCountingResponse).ventures
      : (res as ApiGetResponse<VentureInterface>);
  }

  private updateVentureStateCount(res): void {
    if (!this.isShowAsChallenge) return;
    const counting = res.counting ?? {};
    const { all, accepted, awarded, submitted } = counting;

    this.ventureStateCount = {
      All: all || 0,
      [VentureSubmissionStatus.Accepted]: accepted || 0,
      [VentureSubmissionStatus.Awarded]: awarded || 0,
      [VentureSubmissionStatus.Submitted]: submitted || 0,
    };
  }

  private openDropdown(): void {
    this.isDropdownOpen = true;
  }

  private closeDropdown(): void {
    this.isDropdownOpen = false;
    this.virtualScrollItems = [];
  }
}
