<div class="modal-header">
  <div class="d-flex align-items-center">
    <div class="sender-profile d-flex justify-content-center mr-3">
      <div class="message-to-profile rounded-circle">
        <img
          alt="profile image"
          [src]="profileImage"
          appDefaultAvatar
          appDynamicImgUrl
        />
      </div>
    </div>
    <div>
      <a  class="underline d-inline-block jip-upper-first-letter font-weight-bold"
          [href]="peopleURL + '/' + postData?.from.id"
      >
        {{ getSender() }} </a
      > |

      <ng-container *ngIf="!hideMessageDate">
        <span class="text-gray-500"
          ><i class="fas fa-globe-europe mr-2"></i>
            {{ postData?.dateTime | localizedDate : DateFormat.FullDateTimeCommon2 }}
        </span>
      </ng-container>
    </div>
  </div>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body new-message-dialog-modal-body news-details">
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>

  <div    *ngIf="postData?.message"
          class="news-details__content"
          style="max-height: 0">
    <span [innerHTML]="rawHtml"></span>
    <hr class="d-inline-block"/>
  </div>

  <ng-template #sendMessageTemplate>
    <form [formGroup]="form">
      <div *ngIf="currentOrg" class="d-flex align-items-center mb-3">
        <button
          class="filter__btn mr-2 filter__btn--active flex-shrink-0"
          id="filterCollapse"
        >
          <app-internal-icon
            [hasFilter]="false"
            [height]="20"
            [iconType]="IconType.PNG"
            [name]="InternalIcon.InnovationSpace"
          ></app-internal-icon>
        </button>
        <span class="flex-shrink-0" translate>UI.News.ThisPostBelongsTo</span>

        <span class="font-weight-bold text-truncate">
          &nbsp;{{ currentOrg?.name || 'All Innovation Spaces' | translate }}
        </span>
      </div>
    </form>
  </ng-template>

    <div class=" text-medium-gray" *ngIf="streams && streamNames">
        <span class="flex-shrink-0 font-weight-bold" translate>UI.PublishingStreams.Label</span> :
        <span class="stream-items">{{ streamNames }}</span>
    </div>
</div>
<div class="modal-footer message-command">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">
    {{ 'Close' | translate | uppercase }}
  </button>
</div>
