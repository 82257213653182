import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-add-home-screen',
    templateUrl: './add-home-screen.component.html',
    styleUrls: []
})
export class AddHomeScreenComponent implements OnInit {
    deferredPrompt: any;
    userAgent: string;
    browserName: string;
    showInstallButton = true;
    browserGuideUrl: string | null = null;
    isIOS = false;
    isInStandaloneMode = false;

    constructor(private router: Router) {
        this.browserName = this.detectBrowser();
    }

    ngOnInit(): void {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = event;
            this.showInstallButton = true;
        });

        // When app installed
        window.addEventListener('appinstalled', () => {
            this.showInstallButton = false;
            this.deferredPrompt = null;
        });
    }

    private detectBrowser(): string {
        this.userAgent = navigator.userAgent;

        if (this.userAgent.includes('CriOS')) return 'Chrome'; // Chrome trên iOS
        if (this.userAgent.includes('FxiOS')) return 'FirefoxIOS'; // Firefox trên iOS
        if (this.userAgent.includes('Firefox')) return 'Firefox';  // Firefox
        if (this.userAgent.includes('EdgiOS')) return 'Edge'; // Edge trên iOS
        if (this.userAgent.includes('SamsungBrowser')) return 'SamsungInternet';
        if (this.userAgent.includes('Opera') || this.userAgent.includes('OPR')) return 'Opera';
        if (this.userAgent.includes('Edge')) return 'Edge';
        if (this.userAgent.includes('Chrome')) return 'Chrome';
        if (this.userAgent.includes('Safari')) return 'Safari';

        return 'Unknown';
    }


    private openBrowserGuide() {
        let guideUrl: string;

        switch (this.browserName) {
            case 'Chrome':
                guideUrl = 'https://support.google.com/chrome/answer/15085120';
                break;
            case 'Safari':
                guideUrl = 'https://support.apple.com/guide/iphone/iph42ab2f3a7/ios';
                break;
            case 'FirefoxIOS':
                guideUrl = 'https://support.mozilla.org/en-US/kb/add-website-shortcut-your-home-screen-ios';
                break;
            case 'Firefox':
                guideUrl = 'https://support.mozilla.org/en-US/kb/add-web-page-shortcuts-your-home-screen';
                break;
            case 'Edge':
                guideUrl = 'https://support.microsoft.com/en-us/microsoft-edge/add-a-site-to-your-home-screen-in-microsoft-edge-9e9d0e4e-6e6a-4e6b-8e9e-5e5e5e5e5e5e';
                break;
            case 'Opera':
                guideUrl = 'https://blogs.opera.com/mobile/2015/09/opera-32-add-to-home-screen/';
                break;
            case 'SamsungInternet':
                guideUrl = 'https://www.samsung.com/us/support/answer/ANS10001362/';
                break;
            default:
                guideUrl = 'https://www.google.com/search?q=how+to+add+website+to+home+screen';
        }
        window.open(guideUrl, '_blank');
    }

    addToHomeScreen() {
        // If Browser support Prompt
        if (this.deferredPrompt) {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted A2HS prompt');
                } else {
                    console.log('User dismissed A2HS prompt');
                }
                this.deferredPrompt = null;
                this.showInstallButton = false;
            });
        } else {
            // Open Guide
            this.openBrowserGuide();
        }
    }
}
