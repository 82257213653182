import { Component, Input } from '@angular/core';
import { SocialMedia } from '@shared/interfaces/people.interface';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
})
export class SocialLinksComponent {
  @Input() items: SocialMedia[] = [];
  @Input() size = 20;
}
