import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApproveRejectMessageDialogComponent } from '@src/app/components/news-messages/components/new-message-dialog/approve-reject-message-dialog/approve-reject-message-dialog.component';
import { SharedPendingPeopleComponent } from '@src/app/shared/components/pending-people-modal/shared-pending-people/shared-pending-people.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pending-people-modal',
  templateUrl: './pending-people-modal.component.html',
  providers: [NgbModal],
})
export class PendingPeopleModalComponent implements OnChanges {
  @Input() entityId: number;
  @Input() httpService: BaseHttpService<any>;
  @Input() entityName: string;

  @Output() dismissModal = new EventEmitter<any>();
  @Output() notifyRequestChange = new EventEmitter<any>();

  requesting: boolean;
  action$ = new BehaviorSubject<any>(null);
  EntityName = EntityName;

  @ViewChild('modalRef') modalRef: ElementRef;
  @ViewChild('pendingList') pendingList!: SharedPendingPeopleComponent;

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entityId !== null && this.entityId >= 0) {
      this.open();
    }
  }

  open(): void {
    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        scrollable: true,
        size: 'lg',
      })
      .result.then(
        (result) => {
          // do nothing
        },
        (reason) => {
          this.dismissModal.emit();
        }
      );
  }

  openSendMessageDialog(action: 'approve' | 'reject') {
    const modalRef = this.modalService.open(
      ApproveRejectMessageDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: `message-modal-custom`,
      }
    );

    modalRef.result.then((payload) => {
      if (!payload) return;
      this.action$.next({ action, payload });
    });

    modalRef.componentInstance.title =
      action === 'approve'
        ? 'UI.PendingMember.SendMessageWithApproval'
        : 'UI.PendingMember.SendMessageWithRejection';
  }
}
