export function JsonParse(): PropertyDecorator {
  return function (target: any, propertyKey: string | symbol): void {
    let value: any;

    Object.defineProperty(target, propertyKey, {
      get: () => value,
      set: (newValue: string | object) => {
        try {
          value =
            typeof newValue === 'string' ? JSON.parse(newValue) : newValue;
        } catch (error) {
          console.error(
            `Error parsing JSON for property ${String(propertyKey)}`,
            error
          );
          value = null;
        }
      },
      enumerable: true,
      configurable: true,
    });
  };
}
