export type PropertyName =
  | 'InnovationProgress'
  | 'ConstitutionState'
  | 'BusinessCharacteristics'
  | 'OpenFor'
  | 'Terms'
  | 'Content'
  | 'OnlyBusinessArea'
  | 'VentureEvolution'
  | 'LevelOfDisclosure';

export enum TemplateName {
  Reduced = 'ReducedListTile1', // KCZ+
  Default = 'Default',
  SIListTile = 'SIListTile', // Switzerland Innovation
  Reduced2 = 'ReducedListTile2', // Future Food Farming
  Reduced3 = 'ReducedListTile3', // Screen4Care
  Reduced4 = 'ReducedListTile4', // Cbcharta
}

export type EntityVisibilityConfig = {
  [key in TemplateName]: {
    [property in PropertyName | 'default']: boolean;
  };
};

export const entityVisibilityConfig: EntityVisibilityConfig = {
  ReducedListTile1: {
    InnovationProgress: false,
    ConstitutionState: false,
    BusinessCharacteristics: false,
    OpenFor: true,
    default: true,
    Terms: false,
    Content: true,
    OnlyBusinessArea: false,
    VentureEvolution: true,
    LevelOfDisclosure: true,
  },
  Default: {
    InnovationProgress: true,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: true,
    OnlyBusinessArea: false,
    VentureEvolution: true,
    LevelOfDisclosure: true,
  },
  SIListTile: {
    InnovationProgress: true,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: true,
    OnlyBusinessArea: false,
    VentureEvolution: true,
    LevelOfDisclosure: true,
  },
  ReducedListTile2: {
    InnovationProgress: false,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: false,
    OnlyBusinessArea: true,
    VentureEvolution: true,
    LevelOfDisclosure: true,
  },
  ReducedListTile3: {
    InnovationProgress: false,
    ConstitutionState: false,
    BusinessCharacteristics: false,
    OpenFor: false,
    default: true,
    Terms: false,
    Content: true,
    OnlyBusinessArea: false,
    VentureEvolution: false,
    LevelOfDisclosure: false,
  },
  ReducedListTile4: {
    InnovationProgress: true,
    ConstitutionState: false,
    BusinessCharacteristics: false,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: true,
    OnlyBusinessArea: false,
    VentureEvolution: true,
    LevelOfDisclosure: true,
  },
};
