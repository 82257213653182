import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '@core/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { ArrayUtils } from '@shared/utils/array-utils';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { ApproveRejectMessageDialogComponent } from '@src/app/components/news-messages/components/new-message-dialog/approve-reject-message-dialog/approve-reject-message-dialog.component';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { StringReplacementPipe } from '@src/app/shared/pipes/string-replacement.pipe';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Component({
  selector: 'app-shared-pending-people',
  templateUrl: './shared-pending-people.component.html',
})
export class SharedPendingPeopleComponent implements OnInit {
  @Input() entityId: number;
  @Input() httpService: BaseHttpService<any>;
  @Input() action$: BehaviorSubject<any>;
  @Input() entityName: string;

  @Output() notifyRequestChange = new EventEmitter<any>();

  items: PeopleInterface[] = [];
  page = 1;
  pageSize = 5;
  collectionSize = 0;
  requesting = { all: { reject: false, approve: false } };

  constructor(
    private toastService: ToastService,
    private modalService: NgbModal,
    private stringReplacementPipe: StringReplacementPipe,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.paginateItems();

    this.action$.pipe(filter(Boolean)).subscribe(({ action, payload }) => {
      if (action === 'approve') {
        if (this.entityName === EntityName.Venture) {
          this.approveAllForProjectIdea(true, true, payload);
        } else {
          this.approveAll(payload);
        }
      }

      if (action === 'reject') {
        this.rejectAll(payload);
      }
    });
  }

  paginateItems(): void {
    if (!this.entityId || !this.httpService) {
      return;
    }
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    const path =
      this.entityName === EntityName.Venture
        ? '/stakeholders/pending'
        : '/pending-list/people';

    this.httpService
      .paginateByEntity(path, this.entityId, params)
      .subscribe((res: ApiGetResponse<PeopleInterface>) => {
        this.items = res?.items;
        this.collectionSize = res?.rowCount;
      });
  }

  approveOne(item: PeopleInterface, index: number, payload = {}): void {
    this.approve([item], index, payload);
  }

  approveAll(payload = {}): void {
    if (!this.items) {
      return;
    }
    this.approve(this.items, 'all', payload);
  }

  approve(
    items: PeopleInterface[] = [],
    index: any = 'all',
    payload = {},
    sendMsg = false
  ): void {
    if (this.entityName === EntityName.Venture) {
      this.approveForProjectIdea(items, index, false, sendMsg, payload);
      return;
    }

    const ids = items.map((item) => item.id);
    const api = this.httpService.putByEntity(
      this.entityId,
      { ids, ...payload },
      '/approve-all'
    );
    this.handleApi(api, items, index, 'approve');
  }

  rejectOne(item: PeopleInterface, index: number, payload = {}): void {
    this.reject([item], index, payload);
  }

  rejectAll(payload = {}): void {
    if (!this.items) {
      return;
    }

    this.reject(this.items, 'all', payload);
  }

  reject(
    items: PeopleInterface[] = [],
    index: any = 'all',
    payload = {}
  ): void {
    const ids = items.map((item) => item.id);
    const api =
      this.entityName !== EntityName.Venture
        ? this.httpService.putByEntity(
            this.entityId,
            { ids, ...payload },
            '/reject-all'
          )
        : this.httpService.rejectStakeHolder(this.entityId, items, payload);
    this.handleApi(api, items, index, 'reject');
  }

  handleApi(
    api: Observable<any>,
    items: PeopleInterface[] | number[],
    i,
    key
  ): void {
    if (!this.requesting[i]) {
      this.requesting[i] = {};
    }
    this.requesting[i][key] = true;
    api.subscribe({
      next: (res) => {
        this.requesting[i][key] = false;

        this.items = ArrayUtils.removeItems(this.items, items, 'id');

        if (this.items.length === 0 && this.collectionSize > this.pageSize) {
          this.page = Math.max(this.page - 1, 1);
          this.paginateItems();
        }

        this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
        this.notifyRequestChange.emit();
      },
      error: (err) => {
        this.requesting[i][key] = false;
        this.toastService.showError('UI.Toast.UpdatedFailed');
      },
    });
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateItems();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.paginateItems();
  }

  handleMessageClick(
    item: PeopleInterface,
    index,
    action: 'reject' | 'approve'
  ) {
    if (action === 'approve' && this.entityName === EntityName.Venture) {
      this.approveForProjectIdea([item], index, false, true);
    } else {
      this.openSendMessageDialog([item], index, action);
    }
  }

  openSendMessageDialog(
    items: PeopleInterface[],
    index,
    action: 'reject' | 'approve'
  ) {
    const modalRef = this.modalService.open(
      ApproveRejectMessageDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: `message-modal-custom`,
      }
    );

    modalRef.result.then((payload) => {
      if (!payload) return;

      if (action === 'approve') {
        if (this.entityName === EntityName.Venture) {
          this.approveForProjectIdea(items, index, true, true, payload);
        } else {
          this.approve(items, index, payload);
        }
      }

      if (action === 'reject') {
        this.reject(items, index, payload);
      }
    });

    modalRef.componentInstance.title =
      action === 'approve'
        ? 'UI.PendingMember.SendMessageWithApproval'
        : 'UI.PendingMember.SendMessageWithRejection';
  }

  approveForProjectIdea(
    items = [],
    index: any = 'all',
    ignoreValidateRole = false,
    sendMsg: boolean = false,
    payload = {}
  ) {
    const key = 'approve';

    if (!this.requesting[index]) {
      this.requesting[index] = {};
    }

    return this.httpService
      .approveStakeHolder(
        this.entityId,
        items,
        ignoreValidateRole,
        sendMsg,
        payload
      )
      .subscribe((res) => {
        if (res) {
          if (Array.isArray(res) && res.length > 0) {
            this.openConfirmIgnoreValidateRoleDialog(
              items,
              payload,
              index,
              sendMsg,
              res
            );
          } else {
            this.openSendMessageDialog(items, index, 'approve');
          }
        } else {
          this.requesting[index][key] = false;

          this.items = ArrayUtils.removeItems(this.items, items, 'id');

          if (this.items.length === 0 && this.collectionSize > this.pageSize) {
            this.page = Math.max(this.page - 1, 1);
            this.paginateItems();
          }

          this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
          this.notifyRequestChange.emit();
        }
      });
  }

  openConfirmIgnoreValidateRoleDialog(
    items: any,
    payload = {},
    index: any = 'all',
    sendMsg = false,
    itemsNeedConfirmation: any[] = []
  ): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });

    const messageText = this.stringReplacementPipe.transform(
      this.translateService.instant(
        'UI.PendingMember.ConfirmAcceptPendingStudentOfAnotherVenture'
      ),
      {
        '{studentName}': this.builStudentName(itemsNeedConfirmation),
      }
    );

    modalRef.componentInstance.title = 'Confirmation';
    modalRef.componentInstance.message = messageText;
    modalRef.componentInstance.confirmLabel = 'Yes';

    modalRef.result.then((accepted) => {
      if (accepted) {
        this.confirmToIgnoreValidateRole(items, index, sendMsg, payload);
      }
    });
  }

  builStudentName(items: PeopleInterface[]): string {
    let name = '';

    items.forEach((item, index) => {
      name += `${item.firstName} ${item.lastName}${
        index + 1 !== items.length ? ', ' : ''
      }`;
    });

    return name;
  }

  confirmToIgnoreValidateRole(items: any, index, sendMsg, payload) {
    if (sendMsg) {
      this.openSendMessageDialog(items, index, 'approve');
    } else {
      this.approveForProjectIdea(items, index, true, sendMsg, payload);
    }
  }

  approveAllForProjectIdea(
    ignoreValidateRole: boolean,
    sendMsg: boolean,
    payload = {}
  ) {
    this.approveForProjectIdea(
      this.items,
      'all',
      ignoreValidateRole,
      sendMsg,
      payload
    );
  }
}
