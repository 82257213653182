<ng-template #modalRef let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" translate>RequestedApplications</h4>
    </div>

    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true"> &times; </span>
    </button>

    <div *ngIf="pendingList?.items?.length > 0" class="modal-header__btns mt-2">
      <div class="d-flex align-items-center">
        <div ngbDropdown class="dropdown-no-arrow">
          <div ngbDropdownToggle>
            <button class="jip-btn-big btn btn-outline-primary py-2 px-3 mr-2">
              <ng-container *ngIf="requesting">
                <i class="fas fa-spinner fa-spin"></i>
              </ng-container>

              <i class="far fa-times-circle"></i>
              <span class="ml-2 font-weight-bold">{{
                'UI.Common.RejectAll' | translate | uppercase
              }}</span>
            </button>
          </div>

          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="pendingList.rejectAll()"
              [disabled]="requesting"
            >
              <i class="far fa-times-circle"></i>
              {{'UI.PendingMember.RejectNow' | translate}}
            </button>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="openSendMessageDialog('reject')"
            >
              <i class="far fa-comment-alt"></i>
              {{'UI.PendingMember.RejectWithMessage' | translate}}
            </button>
          </div>
        </div>

        <div ngbDropdown class="dropdown-no-arrow">
          <div ngbDropdownToggle>
            <button
              class="btn btn-secondary-blue py-2 px-3 btn-toggle-secondary-blue"
            >
              <ng-container *ngIf="requesting">
                <i class="fas fa-spinner fa-spin"></i>
              </ng-container>

              <i class="far fa-check-circle"></i>
              <span class="ml-2 font-weight-bold">{{
                'UI.Common.ApproveAll' | translate | uppercase
              }}</span>
            </button>
          </div>

          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="pendingList.approveAll()"
              [disabled]="requesting"
            >
              <i class="far fa-check-circle"></i>
              {{'UI.PendingMember.ApproveNow' | translate}}
            </button>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="
                entityName === EntityName.Venture
                  ? pendingList?.approveAllForProjectIdea(false, true)
                  : openSendMessageDialog('approve')
              "
            >
              <i class="far fa-comment-alt"></i>
              {{'UI.PendingMember.ApproveWithMessage' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-md-3 pb-4">
    <app-shared-pending-people
      #pendingList
      [entityId]="entityId"
      [httpService]="httpService"
      [action$]="action$"
      [entityName]="entityName"
      (notifyRequestChange)="notifyRequestChange.emit()"
    >
    </app-shared-pending-people>
  </div>
</ng-template>
