import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { environment } from '@src/environments/environment';
import { DateFormat } from '../../enums/date.enum';

@Component({
  selector: 'app-message-header-wrapper',
  templateUrl: './message-header-wrapper.component.html',
})
export class MessageHeaderWrapperComponent {
  @Input() people: PeopleInterface | UserInterface;
  @Input() isSystemNews = false;
  @Input() messageDate: Date;
  @Input() hideMessageDate = false;
  @Input() hideMessageDetails = false;
  @Input() showPeopleAsJipLogo = false;

  @Input() isPostOwner = false;
  @Input() isEdited = false;

  @Output() edited: EventEmitter<void> = new EventEmitter();
  @Output() viewing: EventEmitter<void> = new EventEmitter();
  @Output() deleted = new EventEmitter();

  DateFormat = DateFormat;
  peopleUrl = environment.jipUrl.people;

  onEdit(): void {
    this.edited.emit();
  }

  onViewing(): void {
    this.viewing.emit();
  }

  onDelete(): void {
    this.deleted.emit();
  }
}
