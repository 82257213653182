<div class="upload-control-wrapper d-flex">
  <div class="d-flex">
    <div
      appRequireSession
      class="d-flex align-items-center pointer-cursor"
      (credentialClick)="uploadImage.emit()"
    >
      <span class="upload-control-wrapper__change-logo d-block">
        <i class="fas fa-pencil-alt"></i>
      </span>
      <span class="ml-1 upload-control-wrapper__control-text">{{
        changeImageText
      }}</span>
    </div>
    <ng-container *ngIf="shouldShowResetButton">
      <div
        appRequireSession
        class="d-flex align-items-center upload-control-wrapper__back-to-default pointer-cursor"
        (credentialClick)="resetImage.emit()"
      >
        <span class="upload-control-wrapper__change-logo d-block">
          <i class="fas fa-undo-alt"></i>
        </span>
        <span class="ml-1 upload-control-wrapper__control-text">{{
          resetDefaultImageText
        }}</span>
      </div>
    </ng-container>
  </div>
</div>
