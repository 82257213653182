import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SearchEntitiesService } from '@app/components/search-results/services/search-entities.service';
import { AuthenService } from '@core/authen/authen.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { SessionService } from '@core/session.service';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import { WINDOW } from '@shared/helpers/window.token';
import {
  NewsMessageInterface,
  NewsMessageType,
} from '@shared/interfaces/news-message.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { CrossSearchingEntity } from '@shared/models/cross-searching-entity.model';
import { Entity } from '@shared/models/entity.model';
import {
  EntityName,
  EntityStateCount,
} from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent
  extends EntityStateCountComponent
  implements OnInit, OnDestroy
{
  sections$: BehaviorSubject<CrossSearchingEntity[]>;

  EntityName = EntityName;

  isShowPaging = false;

  constructor(
    readonly authService: AuthenService,
    readonly searchEntitiesService: SearchEntitiesService,
    protected readonly sessionService: SessionService,
    private readonly newsMessageService: NewsMessagesHttpService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
  }

  ngOnInit(): void {
    this.sections$ = this.transformDataSource();
    this.searchEntitiesService.generateSearchResults().then(() => {
      this.filterEntityFromParam();
    });
    this.sessionService.isOnListPage = true;
    this.sessionService.isOnSearchResultPage = true;
  }

  ngOnDestroy(): void {
    /**/
  }

  transformDataSource(): BehaviorSubject<CrossSearchingEntity[]> {
    return this.searchEntitiesService.entities$.pipe(
      map((data) => {
        const newsSearching: CrossSearchingEntity = data.find(
          (x: CrossSearchingEntity) => x.key === EntityName.News
        );

        if (!newsSearching) return [...data];

        newsSearching.content = newsSearching.content.pipe(
          map((newsResponse: ApiGetResponse<Entity>) => {
            newsResponse.items = this.mapEntityStateCount(
              newsResponse.items,
              this.newsMessageService
            );

            return newsResponse;
          })
        ) as Observable<ApiGetResponse<Entity>>;

        return [...data];
      })
    ) as BehaviorSubject<CrossSearchingEntity[]>;
  }

  onFilterChanged(entity: EntityName): void {
    this.searchEntitiesService.currentFilter = entity;
    this.searchEntitiesService.filterDTO.pageIndex = 1;
    if (entity === EntityName.All) {
      this.searchEntitiesService.getAllEntities();
      this.isShowPaging = false;
      StringUtils.setQueryParamOnUrl(UrlParam.GlobalSearchTab, EntityName.All);
      return;
    }
    this.searchEntitiesService.getSpecificEntity(entity);
    this.isShowPaging = true;
    StringUtils.setQueryParamOnUrl(UrlParam.GlobalSearchTab, entity);
  }

  onViewSpecificEntity(entity: EntityName): void {
    this.searchEntitiesService.currentFilter = entity;
    this.searchEntitiesService.filterDTO.pageIndex = 1;
    this.searchEntitiesService.getSpecificEntity(entity);
    this.isShowPaging = true;
    this.window.scrollTo({ top: 0 });
  }

  mapEntityStateCount(
    items: Entity[],
    service: NewsMessagesHttpService
  ): Entity[] {
    const newsGroup = items.filter(
      (item: NewsMessageInterface) =>
        item?.messageType !== NewsMessageType.Message
    );
    const messageGroup = items.filter(
      (item: NewsMessageInterface) =>
        item?.messageType === NewsMessageType.Message
    );

    if (newsGroup.length) {
      this.syncEntityStateCount(
        EntityStateCount.Liking,
        EntityName.News,
        newsGroup,
        service
      );

      this.syncEntityStateCount(
        EntityStateCount.Comment,
        EntityName.News,
        newsGroup,
        service
      );
    }

    if (messageGroup.length) {
      this.syncEntityStateCount(
        EntityStateCount.Liking,
        EntityName.Message,
        messageGroup,
        service
      );
    }

    return items.map((item) =>
      [...newsGroup, ...messageGroup].find(({ id }) => id === item.id)
    );
  }

  syncCommentStateCount(news: NewsMessageInterface): void {
    this.syncEntityStateCount(
      EntityStateCount.Comment,
      EntityName.News,
      [news],
      this.newsMessageService
    );
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncCommentsState();
  }

  filterEntityFromParam(): void {
    const entityName = StringUtils.getParamFromUrl(UrlParam.GlobalSearchTab) as
      | EntityName
      | undefined;

    if (entityName) {
      this.onFilterChanged(entityName);
    }
  }
}
