import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { EntityName } from '@shared/enums/entity-name.enum';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesHttpService extends BaseHttpService<NewsMessageInterface> {
  protected entityName = EntityName.Message;
  public apiUrl = 'messages';

  getMessages(
    entityId: number,
    entityName: string
  ): Observable<ApiGetResponse<NewsMessageInterface>> {
    const url = `${entityName}/${entityId}/${this.apiUrl}`;
    return this.http.get<ApiGetResponse<NewsMessageInterface>>(url);
  }

  read(id: number, params?: any): Observable<NewsMessageInterface> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<NewsMessageInterface>(url, { params });
  }

  getLikes(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}/likes`;
    return this.http.get(url);
  }

  delete(id: number): Observable<boolean> {
    if (id) {
      const url = `${this.apiUrl}/${id}`;
      return this.http.delete<boolean>(url);
    } else {
      return of(false);
    }
  }
}
