import {
  Component,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PeopleHttpService } from '@core/http/people-http.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { Observable } from 'rxjs/internal/Observable';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { InvitationByEmailComponent } from '../entity-section/invitation-by-email/invitation-by-email.component';
import { TokenTypes } from '@src/app/shared/enums/token-type';

@Component({
  selector: 'app-peoples',
  templateUrl: './peoples.component.html',
  providers: [{ provide: BaseHttpService, useClass: PeopleHttpService }],
})
export class PeopleListComponent
  extends BaseListComponent<PeopleInterface>
  implements OnInit
{
  entityName = EntityName.Person;
  entitybase = environment.jipUrl.people;
  currentUser: UserInterface;

  page = 1;
  pageSize = 8;
  isMobileScreen = false;
  showModal: EntityName = null;
  readonly TokenTypes = TokenTypes;

  @ViewChild('invitePeopleRef')
  invitationByEmailComponent: InvitationByEmailComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.isMobileScreen = innerWidth <= MediaBreakpoint.sm;
  }

  isLogin = this.sessionService.isLogin;

  private peopleHttpService: PeopleHttpService;

  constructor(
    public listService: PeopleHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );

    this.peopleHttpService = injector.get(PeopleHttpService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.profile$.subscribe((userContext) => {
      this.currentUser = userContext;
    });

    this.show_paginator = true;
  }

  protected handleSyncEntityStateCount(): void {
    this.syncFollowingsState();
    this.syncVenturesState();
    this.syncEventsState();
  }

  createEntity(event: Event, handler?: any): void {
    this.entityCreatepath = '';
    super.createEntity(
      event,
      this.invitationByEmailComponent.openInvitationEmail()
    );
  }

  showEntityModal(entityModal: EntityName, entity: PeopleInterface): void {
    super.showEntityData(entity);
    this.showModal = entityModal;
  }

  getVcard(person: PeopleInterface): Observable<string> {
    return this.peopleHttpService.getVCard(person.id);
  }
}
