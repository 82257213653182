import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { DirectMessage } from '@shared/interfaces/direct-message.interface';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { FollowerInterface } from '@src/app/shared/interfaces/follower.interface';
import { MessageTo } from '@src/app/shared/interfaces/message-to.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root',
})
export class SendMessageHttpService extends BaseHttpService<ChatMessage> {
  getApiRootPath(): string {
    return 'messages';
  }

  send(message: MessageTo): Observable<any> {
    return this.create(message);
  }

  getMessageDetail(id: number): Observable<ApiGetResponse<ChatMessage>> {
    const entityPath = '/thread';
    return this.paginateByEntity(entityPath, id);
  }

  reply(id: number, payload: DirectMessage): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.post(url, payload);
  }

  searchRecipients(
    type: CustomAtrributeValue,
    text: string = '',
    pageIndex = 0,
    pageSize = 10,
    customParams = new HttpParams()
  ): Observable<MessageRecipient> {
    const url = `${this.apiUrl}/search-recipient`;
    let params = new HttpParams()
      .set('keyword', text)
      .set('type', type.codeId)
      .set('pageSize', pageSize.toString())
      .set('pageIndex', pageIndex.toString());

    if (customParams) {
      customParams.keys().forEach((key) => {
        params = params.set(key, customParams.get(key)!);
      });
    }

    return this.http.get<MessageRecipient>(url, { params });
  }

  sendToRecipients(payload: {
    type: string;
    preselection?: string;
    ids?: [20, 21];
    content: string;
  }): Observable<any> {
    const url = `${this.apiUrl}/send`;
    return this.http.post(url, payload);
  }

  paginateFollowers(
    id: number,
    params: HttpParams
  ): Observable<ApiGetResponse<FollowerInterface>> {
    return super.paginateFollowers(id, params, 'recipients');
  }

  getRecipientEntity(ids: number[], entityName: string) {
    const url = `${this.apiUrl}/recipients`;
    let params = new HttpParams();

    ids.forEach((id) => {
      params = params.append('Ids', id.toString());
    });

    params = params.append('EntityName', entityName);

    return this.http.get(url, { params });
  }

  getMessageHistory(
    id: number,
    entityName: string,
    pageIndex = 0,
    pageSize = 10,
  ) {
    const url = `${this.apiUrl}/message-history`;
    let params = new HttpParams();

    params = params.append('EntityId', id.toString());
    params = params.append('EntityName', entityName);
    params = params.append('PageSize', pageSize.toString());
    params = params.append('PageIndex', pageIndex.toString());

    return this.http.get(url, { params });
  }

  deleteThreadMessage(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}

export class MessageRecipient {
  preselections: CustomAtrributeValue[];
  items: any[];
}
