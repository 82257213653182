<div class="challenge-card-list">
  <ng-container *ngFor="let item of items; trackBy: trackByFn">
    <div
      *ngIf="!item?.isPending"
      class="organization-card-list__item d-flex flex-column"
    >
      <app-organizer
        [leadCompany]="item"
        [editing]="false"
        [showSubmitButton]="true"
        [beforeSubmitFn]="checkNDAFn?.bind(this)"
        [isFromList]="true"
        (submitVenture)="submitVenture(item)"
      >
      </app-organizer>
    </div>
  </ng-container>
</div>
