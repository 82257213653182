import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { CbChartaProjectIdeaDisplayProperties } from '@src/app/shared/constants/ventures.const';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { VentureMessageRights } from '@src/app/shared/enums/venture.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  AttributeDescription,
  AttributeValue,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { AttributeValuePipe } from '@src/app/shared/pipes/attribute-value.pipe';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shared-venture-item',
  templateUrl: './shared-venture-item.component.html',
})
export class SharedVentureItemComponent implements OnDestroy {
  @Input() item: VentureInterface;
  @Input() allowUpdateSubmissionStatus: boolean;
  @Input() showSubmitMenu: boolean;
  @Input() beforeSubmitFn: () => Observable<boolean>;

  @Output() ventureDeleted = new EventEmitter();
  @Output() afterLeaved = new EventEmitter<boolean>();

  EntityName = EntityName;
  AttributeType = AttributeType;
  TemplateName = TemplateName;
  CbChartaProjectIdeaDisplayProperties = CbChartaProjectIdeaDisplayProperties;

  constructor(
    private sessionService: SessionService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private ventureHttpService: VentureHttpService,
    private customFormService: CustomFormService,
    private attributeValuePipe: AttributeValuePipe
  ) {}

  get shouldShowCbChartaTemplate(): boolean {
    return FormUtils.getFieldValue(
      this.item.attributeValues,
      'ShowCbChartaTemplate'
    )?.describedValue?.value;
  }

  getCbChartaPhase(item: VentureInterface): AttributeValue {
    return this.attributeValuePipe.transform(
      item.attributeValues,
      'CbChartaPhase'
    );
  }

  getVentureDetailUrl(ventureId: number): string {
    return `${environment.jipUrl.venture}/${ventureId}`;
  }

  isCurrentVentureOwner(item: VentureInterface): boolean {
    return (
      this.sessionService.isLogin &&
      item.initiator?.id === this.sessionService.currentUser?.id
    );
  }

  isSendMessToMemberShown(item: VentureInterface): boolean {
    return (
      ((this.isCurrentVentureOwner(item) && item.stakeholders?.length > 0) ||
        true) &&
      this.canSendMessage(item)
    );
  }

  isVentureStakeholder(venture: VentureInterface): boolean {
    return (
      this.sessionService.isLogin &&
      ArrayUtils.hasItem(
        venture?.stakeholders,
        this.sessionService.currentUser,
        'id'
      )
    );
  }

  canDeleteVenture(item: VentureInterface): boolean {
    return (
      this.isCurrentVentureOwner(item) ||
      this.sessionService.currentUser?.isTenantAdmin
    );
  }

  deleteVenture(ventureId: number): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.confirmLabel = 'Confirm';
    modalRef.componentInstance.message = 'UI.Venture.ConfirmDelete';
    modalRef.result.then((accepted: boolean) => {
      if (accepted) {
        this.ventureHttpService
          .delete(ventureId)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: () => {
              this.toastService.showSuccess(
                'UI.Venture.Toast.DeletedSuccessfully'
              );
              this.ventureDeleted.emit();
            },
            error: () => {
              this.toastService.showError('UI.Toast.DeletedFailed');
            },
          });
      }
    });
  }

  getFieldOptions(key: string): AttributeDescription | null {
    return this.customFormService.getFieldOptions(key);
  }

  getFieldOptionsFromEntity(key: string, entity: VentureInterface) {
    return FormUtils.getFieldOptions(entity.attributeDescriptions, key);
  }

  shouldShowVentureConstitutionState(item: VentureInterface): boolean {
    if (!item.isNDARequired) {
      return true;
    }

    const currentUserId = this.sessionService.currentUser?.id;
    if (
      currentUserId &&
      (item.initiator?.id === currentUserId ||
        item.stakeholders?.some((x) => x.id === currentUserId))
    ) {
      return true;
    }

    return false;
  }

  canSendMessage(item: VentureInterface): boolean {
    if (!this.sessionService.isLogin) return true;
    const { attributeDescriptions, attributeValues } = item;
    const sendMessageRights =
      CustomMetadataUtils.getAttributeValueByPropertyName(
        attributeDescriptions,
        attributeValues,
        'SendMessageRights'
      );
    const isOwner = this.isCurrentVentureOwner(item);
    const isTeam = this.isVentureStakeholder(item);
    let messageRight = sendMessageRights;

    if (typeof messageRight === 'object') {
      messageRight = (sendMessageRights as CustomAtrributeValue).codeId;

      switch (messageRight) {
        case VentureMessageRights.Everybody:
        case VentureMessageRights.AdminAndCommunity:
          return isOwner || isTeam;
        case VentureMessageRights.AdminOnly:
          return isOwner;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    /**/
  }
}
