import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { OrganizationHttpService } from '@core/http/organization-http.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { EntityNotFoundDialogComponent } from '@src/app/components/dialogs/entity-not-found-dialog/entity-not-found-dialog.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { SessionService } from '@src/app/core/session.service';
import { ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES } from '@src/app/shared/constants/entities.const';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import {
  EntityGlobalFilterCriteria,
  FilterPanePayload,
} from '@src/app/shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { Observable, firstValueFrom, from, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListComponent } from '../base/base-list/base-list.component';
@Component({
  selector: 'app-organization-list',
  templateUrl: './organizations.component.html',
  providers: [{ provide: BaseHttpService, useClass: OrganizationHttpService }],
})
export class OrganizationListComponent
  extends BaseListComponent<OrganizationInterface>
  implements OnInit, OnDestroy
{
  entityName = EntityName.Organization;

  showPeopleModal = false;
  showEventsModal = false;
  showVenturesModal = false;
  showLikesModal = false;
  showKeyOfferModal = false;
  selectedOrg: OrganizationInterface;
  page = 1;
  pageSize = 8;
  globalOrgId;

  centralConfig: CentralConfigService;

  hasFilterPane: boolean;
  showFilterResult: boolean;
  ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES =
    ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES;

  globalPaneEvent: any;

  constructor(
    public listService: OrganizationHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );

    this.centralConfig =
      this.injector.get<CentralConfigService>(CentralConfigService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onNotFoundOrganization();
    this.handleStandaloneWidgetMode();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.globalPaneEvent) {
      this.eventBus.removeEventListener(this.globalPaneEvent);
    }
  }

  handleStandaloneWidgetMode() {
    // Defer execution until widgets are fully loaded
    setTimeout(async () => {
      const isHeaderVisible = await firstValueFrom(
        this.sessionService.isHeaderVisible$
      );

      if (isHeaderVisible) return;

      this.updatePageFilters({
        keyword: '',
        isMyInvolvement: false,
        isMyOrgs: false,
        fromFilterPane: true,
      });

      this.listenFilterPaneEvent();
    });
  }

  listenFilterPaneEvent() {
    this.globalPaneEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.FilterPaneEvent,
      callback: () => this.filterData(),
    });
  }

  getEntityBase(): string {
    return environment.jipUrl.organizations;
  }

  getEntityUrl(org: OrganizationInterface): string {
    return org.showAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;
  }

  protected handleSyncEntityStateCount(): void {
    super.handleSyncEntityStateCount();
    this.syncEntityJoinStates();
    this.modifyDescriptionView();
  }

  private modifyDescriptionView(): void {
    this.items.forEach((org: OrganizationInterface) => {
      if (org.description) {
        org.description = this.enhanceDescriptionHtmlString(org.description);
      }
    });
  }

  getPagingMethod(
    filterDto: EntityGlobalFilterCriteria
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    const activeInnovationSpace = this.centralConfig.innovationSpaceId;
    let params;
    return from(this.getOrgId()).pipe(
      switchMap((globalOrgId) => {
        const orgId = activeInnovationSpace || globalOrgId;
        if (orgId) {
          params = { organizationId: orgId };
        }

        return this.listService.paginateAsPost(
          {
            ...filterDto,
            ...params,
          },
          null,
          params
        );
      })
    );
  }

  async getOrgId(): Promise<number> {
    const isHeaderVisible = await firstValueFrom(
      this.sessionService.isHeaderVisible$
    );

    if (isHeaderVisible) {
      const filterCriteria = await this.filterStoredService.getFilterCriteria();
      this.globalOrgId = filterCriteria?.organizationIds?.[0];
    }

    return +this.globalOrgId;
  }
  getUiTemplate(organization: OrganizationInterface): string {
    const { attributeDescriptions, attributeValues } = organization;
    return CustomMetadataUtils.getAttributeValueByPropertyName(
      attributeDescriptions,
      attributeValues,
      'UiTemplate'
    );
  }
  protected getFilterDto(
    filterCriteria: EntityGlobalFilterCriteria
  ): EntityGlobalFilterCriteria {
    let filterDto = super.getFilterDto(filterCriteria);
    const filterPanePayload: FilterPanePayload[] = [];

    for (const key of Object.keys(this.filterPaneSource)) {
      const attrDes = this.customFormService.getCustomAttributeFieldOptions(
        StringUtils.toUpperCaseFirstLetter(key)
      );
      const dto: FilterPanePayload = {
        propertyName: StringUtils.toUpperCaseFirstLetter(key),
        attributeType: attrDes?.originalAttributeType || attrDes?.attributeType,
        values: this.filterPaneSource[key] || [],
      };
      filterPanePayload.push(dto);
    }

    filterDto = {
      ...filterDto,
      attributeValues: [...filterPanePayload],
    };
    return filterDto;
  }

  onFilterPaneDisplay(value) {
    this.hasFilterPane = value;
  }

  afterUpdateEntityList() {
    let filterDto = {};
    this.filterStoredService
      .getFilterPaneSource()
      .pipe(take(1))
      .subscribe((currentFilter: Record<string, any>) => {
        filterDto = currentFilter;
      });

    const hasFilter = ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES.some((item) => {
      return StringUtils.toLowerCaseFirstLetter(item) in filterDto;
    });

    this.showFilterResult = !!hasFilter;
  }

  openNotFoundEntityDialog() {
    const modal = this.modalService.open(EntityNotFoundDialogComponent, {
      centered: true,
      backdrop: 'static',
    });

    modal.componentInstance.heading = 'UI.NotFoundDialog.Heading.Organizations';
    modal.componentInstance.message = 'UI.NotFoundDialog.Message.Organizations';
  }

  private onNotFoundOrganization() {
    const notFoundParam = StringUtils.getParamFromUrl(UrlParam.NotFound);
    if (notFoundParam) {
      this.openNotFoundEntityDialog();
    }
  }
}
