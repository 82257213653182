<div
  *ngIf="collectionSize > 0"
  class="boostrap-pagination d-flex mt-3 flex-column flex-md-row align-items-center"
  [class.justify-content-md-between]="showChangePageSizeCtrl"
  [class.justify-content-end]="!showChangePageSizeCtrl"
>
  <div
    class="d-flex mb-2 mb-md-0 align-items-center"
    *ngIf="showChangePageSizeCtrl"
  >
    <span class="venture__label widget__label mr-1">
      {{ 'UI.Pagination.ChangePageSizeLabel' | translate }}
    </span>
    <div container="body" ngbDropdown class="jip-dropdown">
      <div class="btn jip-dropdown__btn-select" ngbDropdownToggle>
        {{ pageSize }}
      </div>

      <div
        ngbDropdownMenu
        class="overflow-auto"
        [style.max-height.px]="maxDropdownHeight"
      >
        <button
          *ngFor="let pageSizeItem of pageSizeDropdownItems"
          ngbDropdownItem
          (click)="onChangePageSize(pageSizeItem)"
        >
          {{ pageSizeItem }}
        </button>
      </div>
    </div>
  </div>

  <div class="d-sm-flex flex-column flex-md-row align-items-center d-none">
    <div class="d-flex mb-2 mb-md-0 align-items-center mr-3 mr-md-4">
      <span class="venture__label widget__label mr-1">{{
        'UI.Pagination.ChangePageLabel' | translate
      }}</span>
      <div container="body" ngbDropdown class="jip-dropdown">
        <div class="btn jip-dropdown__btn-select" ngbDropdownToggle>
          {{ page }}
        </div>

        <div
          ngbDropdownMenu
          class="overflow-auto"
          [style.max-height.px]="maxDropdownHeight"
        >
          <button
            *ngFor="let pageItem of pageDropdownItems"
            ngbDropdownItem
            (click)="onChangePage(pageItem)"
          >
            {{ pageItem }}
          </button>
        </div>
      </div>
    </div>

    <ngb-pagination
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      [maxSize]="maxSize"
      [rotate]="true"
      (pageChange)="onChangePage($event)"
      appPagingItem
    >
      <ng-template ngbPaginationPrevious>
        <i class="far fa-chevron-left"></i>
      </ng-template>

      <ng-template ngbPaginationNext>
        <i class="far fa-chevron-right"></i>
      </ng-template>
    </ngb-pagination>
  </div>

  <!-- Pagination Mobile -->
  <div class="pagination-mobile d-sm-none d-block">
    <ngb-pagination
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      [maxSize]="maxSize"
      [rotate]="true"
      (pageChange)="onChangePage($event)"
      appPagingItem
    >
      <ng-template ngbPaginationPages let-page let-pages="pages">
        <ul>
          <li class="ngb-custom-pages-item">
            <div class="d-flex flex-nowrap px-2">
              <label
                id="paginationInputLabel"
                for="paginationInput"
                class="col-form-label mr-2"
                >Page</label
              >
              <div class="pagination-mobile__dropdown" ngbDropdown>
                <input
                  #i
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control custom-pages-input rounded"
                  id="paginationInput"
                  [value]="page"
                  (blur)="onChangePage(i.value)"
                  aria-labelledby="paginationInputLabel paginationDescription"
                  style="width: 100px"
                  ngbDropdownToggle
                  min="1"
                />

                <i class="fas fa-sort-down dropdown-icon"></i>

                <ul
                  ngbDropdownMenu
                  class="dropdown-menu"
                  class="overflow-auto"
                  [style.max-height.px]="maxDropdownHeight"
                >
                  <li
                    ngbDropdownItem
                    *ngFor="let pageItem of pageDropdownItems"
                    (mousedown)="onChangePage(pageItem)"
                  >
                    <a class="dropdown-item">{{ pageItem }}</a>
                  </li>
                </ul>
              </div>
              <span
                id="paginationDescription"
                class="col-form-label text-nowrap px-2"
              >
                of {{ pageDropdownItems.length }}</span
              >
            </div>
          </li>
        </ul>
      </ng-template>

      <ng-template ngbPaginationPrevious>
        <i class="far fa-chevron-left"></i>
      </ng-template>

      <ng-template ngbPaginationNext>
        <i class="far fa-chevron-right"></i>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
