import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { BaseListComponent } from '@src/app/components/base/base-list/base-list.component';
import { NewsMessagesComponent } from '@src/app/components/news-messages/news-messages.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { UnsavedFormComponent } from '@src/app/core/form/unsaved-form-check.service';
import { MessagesHttpService } from '@src/app/core/http/messages-http.service';
import { NewsMessagesHttpService } from '@src/app/core/http/news-messages-http.service';
import { NotificationService } from '@src/app/core/notification.service';
import { FactoryService } from '@src/app/core/services/factory.service';
import { SessionService } from '@src/app/core/session.service';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import {
  EntityName,
  MessageValidation,
} from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { BaseEntityInterface } from '@src/app/shared/interfaces/base/base-entity.interface';
import { NewsMessageInterface } from '@src/app/shared/interfaces/news-message.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { ContentChange } from 'ngx-quill';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  providers: [NgbActiveModal, NewsMessagesComponent],
})
export class MessageDialogComponent
  extends BaseListComponent<NewsMessageInterface>
  implements OnInit, OnChanges, OnDestroy, UnsavedFormComponent
{
  @ViewChild('messageForm') messageForm: FormFieldCustomComponent;
  modalRef: NgbModalRef;

  form = new UntypedFormGroup({});
  @Input() entityId!: number;
  @Input() entity!: BaseEntityInterface;
  @Input() entityName!: EntityName;
  @Input() instanceName!: string;
  @Input() isLoadingCommentsCount = false;
  @Input() items: NewsMessageInterface[] = [];
  @Input() uiTemplateConfig: TemplateName = TemplateName.Default;
  @Input() currentUserId: number = 0;
  @Input() isTeamMember: boolean = false;
  @Input() isInitiator: boolean = false;
  @Output() afterMessageListModalClosed = new EventEmitter();

  isLoadingItems$ = new BehaviorSubject(true);
  EntityName = EntityName;
  currentUser: UserInterface;

  constructor(
    protected readonly modalService: NgbModal,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector,
    protected notificationService: NotificationService,
    public readonly activeModal: NgbActiveModal,
    public listService: NewsMessagesHttpService,
    public messagesHttpService: MessagesHttpService,
    public factoryService: FactoryService,
    private newsMessagesComponent: NewsMessagesComponent // Inject component
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );
  }

  ngOnInit(): void {
    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((userContext: UserInterface) => {
        this.currentUser = userContext;
      });
    return this.loadData();
  }

  get content(): AbstractControl {
    return this.form?.get('Content');
  }

  get isSendMail(): AbstractControl {
    return this.form?.get('isSendMail');
  }

  get imageId(): AbstractControl {
    return this.form?.get('imageId');
  }

  get recipients(): AbstractControl {
    return this.form?.get('selectedRecipients');
  }

  getForm(): UntypedFormGroup {
    return this.form;
  }

  get haveRightsToSendMessage(): boolean {
    if(this.entityName == this.EntityName.Challenge || this.entityName == this.EntityName.Event){
      const messageRights = typeof this.entity?.sendMessageRights === 'object' 
                          ? this.entity?.sendMessageRights?.codeId 
                          : this.entity?.sendMessageRights;
      switch(messageRights){  
        case `${this.entityName}MessageRightEverybody`:
          return this?.currentUser?.userId > 0;
        case `${this.entityName}MessageRightAdminAndCommunity`:
          return this.isTeamMember || this.isInitiator;
        case `${this.entityName}MessageRightAdminOnly`:
          return this.isInitiator;
        default:
            return false;
      }
    }
    return false;
  }

  loadData(): void {
    const entityService = this.factoryService.createServiceByEntityName(
      this.entityName
    );

    combineLatest([
      this.sessionService.apiReady$,
      this.sessionService.isHeaderVisible$,
      entityService?.getMessagesByEntity(this.entityId, this.entityName),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([apiReady, isHeaderVisible, messages]: [
          boolean,
          boolean,
          ApiGetResponse<NewsMessageInterface>
        ]) => {
          if (!apiReady) {
            return;
          }

          this.isLoadingItems$.next(false);
          super.updateEntityList(messages, false);
        }
      );
  }

  isFormNotSaved(): boolean {
    return !!this.content;
  }

  isValidContent(event: ContentChange): void {
    const { text } = event;
    const maxlength = MessageValidation.MaxLength;
    if (StringUtils.isRTEContentMaxlengthError(text, maxlength)) {
      this.content.setErrors({ maxlength });
    } else {
      if (this.content.getError('maxlength')) {
        delete this.content.errors.maxlength;
      }
    }
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  openLikesModal(event: NewsMessageInterface): void {
    console.log('Likes modal opened:', event);
  }

  onMessageListModalClosed(event: NewsMessageInterface): void {
    console.log('Message list modal closed:', event);
  }

  onSendMessageClick(event: Event): void {
    const params = {
      entityId: this.entityId,
      entityName: this.entityName,
      recipientCodeId: this.entityName == "Challenge" ? "SendMessageChoicesOrganizations" : "SendMessageChoicesEvents",
      instanceName: this.instanceName,
    };
    this.newsMessagesComponent.openNewMessage(params);
  }

  ngOnDestroy(): void {
    /** */
  }
}
