<ng-container>
  <div class="filter-pane">
    <app-filter-pane
      [httpService]="listService"
      [entityName]="entityName"
      [isShowFilterPane]="show_filter_pane"
      [filterPaneUi]="filterPaneUi"
      (sortingPayloadChange)="onSortingCustomAttributes($event)"
    >
    </app-filter-pane>
  </div>
  <ng-container *ngIf="items?.length > 0; else emptyContent">
    <div class="mb-4">
      <div *ngFor="let venture of items" class="mb-3">
        <app-venture-card
          [data]="venture"
          [uiTemplate]="
            (venture.attributeValues | attributeValue : 'UiTemplate')
              ?.describedValue?.value
          "
        ></app-venture-card>
      </div>
    </div>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>

    <div
      *ngIf="show_creation_btn"
      class="text-right widget__btn-create-wrapper"
    >
      <button
        class="btn btn-primary widget__btn-create my-3"
        translate
        appRequireLogin
        [callBackLoginPage]="entityCreatepath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
