<div
  #chipContainer
  class="tags-container"
  (ngxVisibility)="updateTagsVisibility()"
>
  <span *ngFor="let tag of visibleTags" class="tag">{{ tag }}</span>
  <span *ngIf="hiddenTags.length > 0" class="extra-tags-counter"
    >+{{ hiddenTags.length }}</span
  >
</div>
