import { Component, Input, OnInit } from '@angular/core';
import { VentureHttpService } from '@core/http/venture.http.service';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { CbChartaProjectIdeaDisplayProperties } from '@src/app/shared/constants/ventures.const';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';
import { BaseCardComponent } from './../base-card/base-card.component';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-venture-card',
  templateUrl: './venture-card.component.html',
})
export class VentureCardComponent
  extends BaseCardComponent<VentureInterface>
  implements OnInit
{
  @Input() uiTemplate: TemplateName;
  constructor(protected service: VentureHttpService) {
    super(service);
  }

  TemplateName = TemplateName;
  CbChartaProjectIdeaDisplayProperties = CbChartaProjectIdeaDisplayProperties;

  ngOnInit(): void {
    if (this.data?.attributeDescriptions) {
      this.attributes = {};
      for (const item of this.data.attributeDescriptions) {
        this.attributes[item.propertyName] = item;
      }
    } else {
      super.ngOnInit();
    }
    this.getEntityDetailPageUrl(environment.jipUrl.venture);
  }

  shouldShowDescription(item): boolean {
    return this.service.shouldShowContent(item?.attributeDescriptions, item, [
      'Content',
      'Excerpt',
    ]);
  }

  shouldShowCbChartaTemplate(venture: VentureInterface): boolean {
    return (
      FormUtils.getFieldValue(venture.attributeValues, 'ShowCbChartaTemplate')
        ?.describedValue?.value || venture.showChChartaTemplate
    );
  }

  getProjectDetailFromEntity(venture: VentureInterface, key: string) {
    return venture[StringUtils.toLowerCaseFirstLetter(key)]
  };
}
