import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SendMessageBoxBaseComponent } from '@src/app/components/dialogs/send-message-box-base/send-message-box-base.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { EventBusService } from 'ngx-eventbus';

@Component({
  selector: 'app-approve-reject-message-dialog',
  templateUrl: './approve-reject-message-dialog.component.html',
})
export class ApproveRejectMessageDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() title = '';
  @Input() requesting = false;

  @Output() formValue = new EventEmitter();

  profile: UserInterface;

  //#region SERVICES
  protected toastService: ToastService;
  private translateService: TranslateService;
  protected sessionService: SessionService;
  //#endregion End Services

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected injector: Injector,
    protected authenService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    private eventBus: EventBusService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );
    this.toastService = injector.get<ToastService>(ToastService);
    this.translateService = injector.get<TranslateService>(TranslateService);
    this.sessionService = injector.get<SessionService>(SessionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      Content: [''],
      isSendMail: [false],
      imageId: [],
    });
  }

  protected handleAfterLoadProfileData(userProfile: UserInterface): void {
    super.handleAfterLoadProfileData(userProfile);
    this.profile = userProfile;
  }

  send(): void {
    this.activeModal.close(this.form.value);
  }

  onCloseModal(): void {
    this.activeModal.close(null);
  }
}
