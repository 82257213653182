<app-filter-pane
  [httpService]="listService"
  [entityName]="entityName"
  [isShowFilterPane]="show_filter_pane"
  [filterPaneUi]="filterPaneUi"
  (sortingPayloadChange)="onSortingCustomAttributes($event)"
>
</app-filter-pane>
<ng-container *ngIf="!isLoading">
  <div>
    <ng-container *ngIf="items && items.length > 0; else emptyContent">
      <ng-container *ngIf="!isGridView; else entityGridView">
        <app-metadata-table-venture
          [entityDatas]="items"
          [isLoading]="isLoading"
          [editable]="false"
        ></app-metadata-table-venture>
      </ng-container>

      <ng-template #entityGridView>
        <ul
          [class.three-columns]="columns === '3'"
          [style.background-color]="bg_color"
          class="widget__container venture-list"
        >
          <li
            *ngFor="let venture of items; index as ventureIndex"
            class="venture__item"
          >
            <div
              class="venture__wrapper"
              *ngIf="
                venture.attributeValues
                  | attributeValue : 'UiTemplate' as template
              "
            >
              <a
                *ngIf="template?.describedValue?.value as uiTemplateName"
                class="link-unstyled link-rich-text-pointer"
                href="{{ entitybase }}/{{ venture.id }}"
                target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
                rel="noopener"
              >
                <div class="venture__header">
                  <div class="venture__header-top p-3">
                    <app-privacy-flag
                      [isPrivate]="venture?.isPrivate"
                    ></app-privacy-flag>

                    <div
                      class="d-flex"
                      *ngIf="uiTemplateName !== TemplateName.Reduced4"
                    >
                      <app-join-venture
                        [baseHttpService]="listService"
                        [entity]="venture"
                      >
                      </app-join-venture>
                    </div>
                  </div>
                  <a
                    href="{{ entitybase }}/{{ venture.id }}"
                    class="link-unstyled"
                  >
                    <app-image-wrapper
                      imgClass="widget__image venture__image"
                      [imgObject]="venture.image"
                    ></app-image-wrapper>
                    <div
                      class="venture__name venture__name--min-height widget__heading--background d-flex align-items-center px-3 py-2"
                    >
                      <h5
                        class="venture__title widget__heading truncate-line-1"
                        appTooltip
                        triggers="manual"
                        #t="ngbTooltip"
                        [toolTipControl]="t"
                        [ngbTooltip]="venture.title"
                      >
                        {{ venture.title }}
                      </h5>
                    </div>
                  </a>
                </div>

                <div
                  *ngIf="
                    !shouldShowCbChartaTemplate(venture);
                    else cbChartaProjectDetail
                  "
                  class="venture__body widget__card--background"
                >
                  <div class="row">
                    <div class="col col-12 mt-2">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="venture__label widget__label" translate>
                          UI.Ventures.WeAreLookingFor
                        </div>
                      </div>
                      <ul
                        appTooltip
                        triggers="manual"
                        #t3="ngbTooltip"
                        [toolTipControl]="t3"
                        [ngbTooltip]="openFor"
                        tooltipClass="no-limit-width-tooltip"
                        class="list-inline widget__bold truncate-line-1 open-for-list"
                      >
                        <ng-container
                          *ngTemplateOutlet="openFor"
                        ></ng-container>
                      </ul>
                      <ng-template #openFor>
                        <ng-container *ngIf="venture.demands">
                          <li
                            *ngFor="let item of venture.demands"
                            class="list-inline-item font-weight-bold"
                          >
                            <span class="venture__hashtags widget__bold badge"
                              ># {{ item?.content }}</span
                            >
                          </li>
                        </ng-container>
                        <ng-container *ngIf="venture.offerings">
                          <li
                            *ngFor="let item of venture.offerings || []"
                            class="list-inline-item font-weight-bold"
                          >
                            <span class="venture__hashtags widget__bold badge"
                              ># {{ item?.content }}</span
                            >
                          </li>
                        </ng-container>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <ng-template #cbChartaProjectDetail>
                  <div class="venture__body widget__card--background">
                    <div
                      *ngFor="let item of CbChartaProjectIdeaDisplayProperties"
                      class="venture__cbcharta-detail--small d-flex justify-content-between"
                    >
                      <ng-container
                        *ngIf="getFieldOptions(item, venture) as attribute"
                      >
                        <span class="text-medium-gray font-weight-bold">{{
                          attribute.displayName
                        }}</span>
                        <span
                          >{{
                            (venture.attributeValues | attributeValue : item)
                              ?.describedValue?.value | number : '1.0-0'
                          }}{{ attribute.helpTextId | translate }}</span
                        >
                      </ng-container>
                    </div>
                  </div>
                </ng-template>

                <div
                  class="venture__body venture__body--company widget__separator--background d-flex"
                >
                  <div class="venture__company-header">
                    {{ 'UI.Ventures.VentureProfile' | translate }}
                  </div>
                </div>

                <div class="venture__body widget__card--background">
                  <div class="row">
                    <div class="col col-12 mt-3 pb-3">
                      <div class="venture__company">
                        <div
                          *ngIf="
                            venture.leadCompany && venture.leadCompany?.canSee;
                            else deactiveOrg
                          "
                          class="venture__company-brand"
                        >
                          <div
                            class="venture__company-logo flex-shrink-0"
                            [ngbTooltip]="venture.leadCompany?.name"
                          >
                            <div class="venture__company-logo-wraper">
                              <a
                                [href]="
                                  venture?.leadCompany?.id
                                    ? organizationBase +
                                      '/' +
                                      venture?.leadCompany?.id
                                    : 'javascript:void(0)'
                                "
                                [class]="{
                                  'pointer-none': !venture?.leadCompany?.id
                                }"
                              >
                                <img
                                  [src]="venture.leadCompany?.logo.url"
                                  appDefaultPlaceholder
                                  [default]="placeholderImg"
                                  appDynamicImgUrl
                                />
                              </a>
                            </div>
                          </div>

                          <a
                            [href]="
                              venture?.leadCompany?.id
                                ? organizationBase +
                                  '/' +
                                  venture?.leadCompany?.id
                                : 'javascript:void(0)'
                            "
                            class="venture__company-brand-link text-reset"
                            [class]="{
                              'pointer-none': !venture?.leadCompany?.id
                            }"
                          >
                            <span
                              class="venture__company-name truncate-line-1"
                              appTooltip
                              triggers="click"
                              [ngbTooltip]="venture.leadCompany?.name"
                              >{{ venture.leadCompany?.name }}</span
                            >
                          </a>
                        </div>
                        <ng-template #deactiveOrg>
                          <div class="venture__company-brand">
                            <div
                              class="venture__company-logo flex-shrink-0 p-0 text-white border"
                            >
                              <div class="venture__company-logo-wraper">
                                <app-deactive-entity
                                  [entityName]="EntityName.Organization"
                                >
                                </app-deactive-entity>
                              </div>
                            </div>

                            <div class="venture__company-brand-link text-reset">
                              <span
                                class="venture__company-name truncate-line-1 deactive-label"
                                >{{
                                  'UI.UnsubscribedOrganization' | translate
                                }}</span
                              >
                            </div>
                          </div>
                        </ng-template>
                        <div class="flex-grow-1 ventures-people">
                          <app-venture-people
                            [initiator]="venture.initiator"
                            [stakeholders]="venture.stakeholders"
                            [labelTranslateKey]="
                              shouldShowCbChartaTemplate(venture)
                                ? 'Site Administrator'
                                : 'Initiator'
                            "
                          ></app-venture-people>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col col-12 pt-3 pb-2 border-top"
                      *ngIf="
                        uiTemplateName | visibility : 'BusinessCharacteristics'
                      "
                    >
                      <div class="venture__label widget__label">
                        {{ 'UI.Ventures.BusinessCharacteristics' | translate }}
                      </div>
                      <div class="row px-3 py-2">
                        <app-tag-view
                          class="w-100"
                          [tags]="venture | call : getTags"
                        ></app-tag-view>
                      </div>
                    </div>

                    <div
                      *ngIf="shouldShowCbChartaTemplate(venture)"
                      class="col col-12 pt-3 pb-3 border-top"
                    >
                      <div class="row">
                        <div class="col col-12">
                          <div class="venture__label widget__label mb-3">
                            {{ 'Project Phases' | translate }}
                          </div>
                          <app-form-field-custom
                            [editable]="false"
                            [currentValue]="
                              (
                                venture.attributeValues
                                | attributeValue : 'CbChartaPhase'
                              )?.describedValue?.selection
                            "
                            [type]="allType.VenturePhase"
                            [attributeDescription]="
                              getFieldOptions('CbChartaPhase', venture)
                            "
                          ></app-form-field-custom>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <app-interaction-bar
                [httpService]="listService"
                [entity]="venture"
                entityPath="{{ entitybase }}/{{ venture.id }}"
                [entityName]="entityName"
                [isLoadingLikingsCount]="isLoadingLikingsCount"
                [isLoadingFollowingsCount]="isLoadingFollowingsCount"
                [isLoadingCommentsCount]="isLoadingCommentsCount"
                (triggerToggleLike)="toggleLike(venture)"
                (triggerShowLikingsData)="
                  showEntityData(venture);
                  showLikesModal = !!venture?.likesCount
                "
                (triggerShowFollowingsData)="
                  showEntityData(venture); showPeopleModal = true
                "
                (afterCommentModalClosed)="onCommentModalClosed()"
                (triggerToggleFavorite)="toggleFavorite(venture)"
              >
              </app-interaction-bar>
            </div>
          </li>
        </ul>
      </ng-template>
    </ng-container>
    <ng-template #emptyContent>
      <app-empty-content></app-empty-content>
    </ng-template>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>

    <div
      *ngIf="show_creation_btn"
      class="text-right widget__btn-create-wrapper"
    >
      <button
        class="btn btn-primary widget__btn-create my-3"
        translate
        appRequireLogin
        [callBackLoginPage]="entityCreatepath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #hiddenContentRef>
  <div class="business-characteristics">
    <app-hidden-content></app-hidden-content>
  </div>
</ng-template>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.title"
  modalTitle="{{ 'Venture' | translate }} {{ 'Followers' | translate }}"
  [httpService]="listService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.title"
  [modalTitle]="'UI.Likes.Venture' | translate"
  [httpService]="listService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>

<div class="entity-fullscreen-mode">
  <app-metadata-table-venture
    [hidden]="!entityFullScreenMode"
    [entityDatas]="items"
    [isLoading]="isLoading"
    [showEntityPagination]="true"
    [editable]="false"
    [isFullScreen]="entityFullScreenMode"
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
    (minimize)="onMinize()"
  ></app-metadata-table-venture>
</div>
