import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';

@Component({
  selector: 'app-upload-banner-control',
  templateUrl: './upload-banner-control.component.html',
})
export class UploadBannerControlComponent {
  @Input() isCreating: boolean;
  @Input() entityName: EntityName;
  @Input() changeImageText: string;
  @Input() resetDefaultImageText: string;
  @Input() shouldShowResetButton: boolean = false;

  @Output() uploadImage = new EventEmitter();
  @Output() resetImage = new EventEmitter();
}
