<div
  *ngIf="navigateUrl"
  [class.text-center]="isStandaloneWidget"
  [class.text-left]="!isStandaloneWidget"
  [class.text-lg-center]="!isStandaloneWidget"
  class="navigation__content--padding"
>
  <div class="nav-profile__action-item font-weight-bold" translate>
    Information
  </div>
  <div class="nav-profile__action-item">
    <a
      appUnsavedFormCheck
      appUrlPrefix
      [value]="navigateUrl"
      class="nav-link about-us p-0"
    >
      {{ 'UI.Common.AboutUs' | translate }}
    </a>
  </div>
</div>

<div
  *ngIf="isTenantAdmin"
  [class.text-center]="isStandaloneWidget"
  class="navigation__content--padding"
>
  <div
    [class.text-left]="!isStandaloneWidget"
    class="nav-profile__action-item font-weight-bold text-lg-center"
  >
    {{ 'UI.Common.Admin' | translate }}
  </div>

  <div class="nav-profile__action-item">
    <a
      appUnsavedFormCheck
      [class.text-left]="!isStandaloneWidget"
      class="nav-link p-0 text-lg-center"
      [href]="adminSettingsUrl"
    >
      {{ 'Settings' | translate }}
    </a>
  </div>

  <div
    appRequireLogin
    class="nav-profile__action-item"
    (click)="
      $event.stopPropagation(); $event.preventDefault(); openExportPopup()
    "
  >
    <a href class="btn btn-outline-primary w-100 py-0">
      <i class="fas fa-file-export"></i>
      {{ 'UI.Common.Export' | translate }}
    </a>
  </div>
</div>
<div
  [class.d-none]="isStandaloneWidget"
  class="navigation__content--padding d-sm-none"
>
  <app-languages></app-languages>
</div>
<div
        [class.d-none]="isStandaloneWidget"
        class="navigation__content--padding d-sm-none add-home-screen"
>
  <app-add-home-screen></app-add-home-screen>
</div>
