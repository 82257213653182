<div class="venture__footer interaction-bar" [ngClass]="wrapperClass">
  <ul class="list-inline d-flex venture__actions justify-content-between">
    <div
      class="main-interaction"
      [ngClass]="{
        'entity-detail-interaction': sessionService.isOnDetailPage
      }"
    >
      <app-like-entity
        [entity]="entity"
        [isLoadingLikingsCount]="isLoadingLikingsCount"
        [hideLikeNumber]="hideLikeNumber"
        (triggerToggleLike)="toggleLike(entity)"
        (triggerShowLikingsData)="showLikingsData(entity)"
      ></app-like-entity>

      <!-- Followers -->
      @if(!useFollowIcon) {
      <li class="list-inline-item venture__actions-item">
        <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
          <div class="text-center">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </ng-container>
        <ng-template #followersBtn>
          <a class="d-flex">
            <span class="venture__actions-label d-flex align-items-center">
              <span
                appRequireLogin
                appDebounceClick
                (debounceClick)="toggleFavorite($event)"
                class="d-flex align-items-center"
              >
                <app-internal-icon
                  [className]="
                    !entity.isFavorite ? 'filter-gray' : 'icon-follow following'
                  "
                  [name]="'user-follow'"
                ></app-internal-icon>
                <span
                  class="text-hide-small ml-1"
                  [class.text-primary]="entity.isFavorite"
                  >{{ 'UI.Interaction.Follow' | translate }}</span
                >
              </span>
              <span
                appDebounceClick
                (debounceClick)="showFollowingsData(entity)"
                [innerHTML]="entity?.followerCount | parentheses"
              ></span>
            </span>
          </a>
        </ng-template>
      </li>

      } @else {
      <app-follow-interact
        [entity]="entity"
        [hasBigSize]="true"
        [uiTemplate]="'icon'"
        (followClicked)="toggleFavorite($event)"
      >
      </app-follow-interact>
      }

      <app-comment-entity
        [entity]="entity"
        [isLoadingCommentsCount]="isLoadingCommentsCount"
        [entityName]="entityName"
        (afterCommentModalClosed)="afterCommentModalClosed.emit()"
      ></app-comment-entity>

      <ng-container *ngIf="!supportSocialSharing(entityName); else newShare">
        <!-- Old Share -->
        <li class="list-inline-item venture__actions-item" role="button">
          <a
            appRequireLogin
            appDebounceClick
            (debounceClick)="openShare(entity)"
          >
            <app-internal-icon
              [height]="20"
              [name]="'share'"
              [className]="'filter-gray'"
            ></app-internal-icon>
            <span class="venture__actions-label text-hide-small">
              {{ 'UI.Interaction.Share' | translate }}
            </span>
          </a>
        </li>
      </ng-container>

      <ng-template #newShare>
        <!-- New Share -->
        <li
          *ngIf="!hideShareIcon"
          ngbDropdown
          placement="bottom-right"
          class="list-inline-item venture__actions-item"
        >
          <a ngbDropdownToggle>
            <app-internal-icon
              [height]="20"
              [name]="'share'"
              [className]="'filter-gray'"
            ></app-internal-icon>
            <span class="venture__actions-label text-hide-small text-gray-600">
              {{ 'UI.Interaction.Share' | translate }}
            </span>
          </a>

          <ul ngbDropdownMenu>
            <li
              *ngIf="sessionService.tenant.enableCommunication"
              appRequireLogin
              appDebounceClick
              (debounceClick)="openShare(entity)"
              ngbDropdownItem
              class="list-inline-item venture__actions-item"
            >
              <a>
                <img class="icon-size" [src]="tenantIcon || tenantLogo" />
                <span
                  class="venture__actions-label"
                  [innerHTML]="
                    'UI.Tenant-community'
                      | translate
                      | stringReplacement
                        : {
                            '{tenantName}': tenantName
                          }
                  "
                ></span>
              </a>
            </li>
            <li
              (debounceClick)="
                socialSharing(entity, entityName, SocialSharingEnum.LinkedIn)
              "
              appDebounceClick
              class="list-inline-item venture__actions-item"
              ngbDropdownItem
            >
              <a>
                <i class="fab fa-linkedin icon-size mt-n1"></i>
                <span class="venture__actions-label">{{
                  SocialSharingEnum.LinkedIn
                }}</span>
              </a>
            </li>

            <li
              *ngIf="entityPath"
              class="list-inline-item venture__actions-item"
              ngbDropdownItem
              appDebounceClick
              (debounceClick)="shareByMail.openEmailClient()"
            >
              <app-share-via-email
                #shareByMail
                [className]="'mr-auto w-100'"
                [entityName]="entityDisplayName"
                [entityPath]="entityPath"
                [messageBody]="'UI.Entity-Sharing.Mail-content--Body'"
              >
              </app-share-via-email>
            </li>
          </ul>
        </li>
      </ng-template>

      <ng-container *ngIf="isSupportMessage(entityName) && !iconsHidden.message">
        <li
              appDebounceClick
              (debounceClick)="openMessageModal(entityName, entity)"
              class="list-inline-item venture__actions-item"
            >
            <a>
                <app-internal-icon
                  [height]="20"
                  [name]="'comments'"
                  [className]="'filter-gray'"
                ></app-internal-icon>
                <span class="venture__actions-label font-weight-500 text-gray-600">
                  <span class="text-hide-small">{{ 'Messages' | translate }}</span>
                  <span [hidden]></span>
                </span>
              </a>
            </li>
      </ng-container>
    </div>

    <!-- Custom Content -->
    <ng-content></ng-content>
  </ul>
</div>
