<ng-container *ngIf="isLoading; else viewDetail">
  <div class="entity-loading">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-container>
<ng-template #viewDetail>
  <div class="venture-edit">
    <app-timestamp-header
      [timestampInfo]="timestampInfo"
      (closeTimestamp)="backToEntity()"
    >
    </app-timestamp-header>
    <div
      *ngIf="entity"
      class="venture__wrapper venture-details timestamp-detail pb-3"
    >
      <div class="venture__header">
        <div class="venture__publishing">
          <app-privacy-flag [isPrivate]="entity?.isPrivate"></app-privacy-flag>
        </div>
        <app-image-wrapper
          [imgObject]="entity.image"
          [imgAlt]="entity.title"
        ></app-image-wrapper>
        <div class="venture__title-wrapper">
          <app-form-field-custom
            [formInput]="form"
            [editable]="false"
            [attributeDescription]="getFieldOptions('Title')"
            [options]="{ placeholder: 'Venture Name' | translate }"
          >
          </app-form-field-custom>
        </div>
      </div>

      <ng-container *ngIf="uiTemplateName | visibility : 'InnovationProgress'">
        <div class="venture__body widget__card--background">
          <div class="row">
            <div class="col col-12">
              <div
                class="venture__label venture__label--head widget__label"
                translate
              >
                Current Innovation phase of this venture
              </div>
              <div class="venture__phase-description">
                <span>
                  {{ 'UI.VenturePhase.Description' | translate }}
                </span>
              </div>
              <app-form-field-custom
                [formInput]="form"
                [editable]="false"
                [type]="allType.VenturePhase"
                [attributeDescription]="getFieldOptions('Phase')"
              ></app-form-field-custom>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="venture__body widget__card--background">
        <div class="row">
          <div class="col col-12">
            <ul ngbNav #nav="ngbNav" [destroyOnHide]="true" class="nav-tabs">
              <li ngbNavItem>
                <a ngbNavLink
                  ><span class="venture__tabs__title" translate
                    >Short Description <span class="after"></span></span
                ></a>

                <ng-template ngbNavContent>
                  <div class="mb-3">
                    <label class="venture__label widget__label" translate
                      >Excerpt</label
                    >

                    <app-form-field-custom
                      #excerpt
                      [formInput]="form"
                      [editable]="false"
                      [attributeDescription]="getFieldOptions('Excerpt')"
                      [options]="{
                        placeholder:
                          'Type short description of Venture' | translate,
                        isViewAsText: true,
                        showHiddenMarkBackground: isOwner
                      }"
                    ></app-form-field-custom>
                  </div>
                </ng-template>
              </li>

              <li *ngIf="uiTemplateName | visibility : 'Content'" ngbNavItem>
                <a ngbNavLink
                  ><span class="venture__tabs__title" translate
                    >Long Description <span class="after"></span></span
                ></a>

                <ng-template ngbNavContent>
                  <label class="venture__label widget__label" translate
                    >Description</label
                  >

                  <app-form-field-custom
                    #content
                    [attributeDescription]="getFieldOptions('Content')"
                    [editable]="false"
                    [formInput]="form"
                    [options]="{
                      placeholder:
                        'Type long description of Venture' | translate,
                      isViewAsText: true,
                      isWideView: true,
                      showHiddenMarkBackground: isOwner
                    }"
                  ></app-form-field-custom>
                </ng-template>
              </li>

              <li
                *ngIf="
                  customMetadata
                    | call : checkBestPracticeAttribute : uiTemplateName
                "
                ngbNavItem
              >
                <a ngbNavLink
                  ><span class="venture__tabs__title" translate
                    >Best Practices<span class="after"></span></span
                ></a>

                <ng-template ngbNavContent>
                  <label class="venture__label widget__label" translate
                    >Best Practices</label
                  >
                  <app-form-field-custom
                    #content
                    [formInput]="form"
                    [editable]="false"
                    [attributeDescription]="getFieldOptions('BestPractices')"
                    [options]="{
                      placeholder: 'Type best practices of Venture' | translate,
                      isViewAsText: true,
                      isWideView: true,
                      showHiddenMarkBackground: isOwner
                    }"
                  ></app-form-field-custom>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="venture__content-tab"></div>
          </div>
        </div>
      </div>

      <div
        *ngIf="shouldShowDemandSection"
        class="venture__body widget__card--background"
      >
        <div class="row">
          <div class="col col-12">
            <div class="mb-3" *ngIf="uiTemplateName | visibility : 'OpenFor'">
              <div
                *ngIf="getFieldOptions('Demands') as demands"
                class="venture__label widget__label"
              >
                {{ demands.displayName }}
                <app-collapsible-hint
                  [content]="demands.helpTextId | translate"
                ></app-collapsible-hint>
              </div>
              <ul class="list-inline widget__bold">
                <li
                  *ngFor="let item of form.controls['Demands']?.value"
                  class="list-inline-item font-weight-bold"
                >
                  <span class="venture__hashtags widget__bold badge"
                    ># {{ item?.value }}</span
                  >
                </li>
              </ul>
            </div>

            <div class="mb-3" *ngIf="uiTemplateName | visibility : 'Terms'">
              <div class="venture__label widget__label" translate>
                Venture Terms
              </div>

              <div
                *ngIf="shouldShowVentureDetails(); else hiddenContentRef"
                [class.hidden-content-mark]="shouldShowHiddenMarkBackground"
              >
                <app-form-field-custom
                  [attributeDescription]="getFieldOptions('Terms')"
                  [controlName]="'Terms'"
                  [editable]="false"
                  [formInput]="form"
                  [options]="{
                    defaultLabel: 'Select Venture Term' | translate
                  }"
                  [type]="allType.SingleSelection"
                >
                </app-form-field-custom>
              </div>
            </div>

            <div
              class="mb-3"
              *ngIf="uiTemplateName | visibility : 'LevelOfDisclosure'"
            >
              <div class="venture__label widget__label venture__nda-required">
                <div class="mb-2 pb-1">
                  {{ getFieldOptions('IsNDARequired')?.displayName }}
                  <i
                    [ngbTooltip]="disclosureTooltip"
                    appTooltip
                    class="fas fa-info-circle pointer-cursor"
                    triggers="click"
                  ></i>

                  <ng-template #disclosureTooltip>
                    <quill-view
                      [content]="
                        getFieldOptions('IsNDARequired')?.helpTextId | translate
                      "
                      class="ql-editor-only-view"
                    ></quill-view>
                  </ng-template>
                </div>
                <div>
                  <app-toggle-button-slider
                    [status]="!entity.isNDARequired"
                    (statusChange)="entity.isNDARequired = $event"
                    [settings]="{
                      disabled: true,
                      activeText:
                        'VENTURE-DETAILS_Level-of-Disclosure_Open' | translate,
                      deactiveText:
                        'VENTURE-DETAILS_Level-of-Disclosure_Private'
                        | translate
                    }"
                  >
                  </app-toggle-button-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ngb-accordion
        [activeIds]="uiTemplateName | call : getActiveIds"
        [destroyOnHide]="false"
      >
        <!-- Lead Organization -->
        <ngb-panel
          cardClass="venture__body venture__accordion ignore-overflow"
          id="lead-company-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button
              class="btn d-flex align-items-center justify-content-between w-100 text-left"
              ngbPanelToggle
              type="button"
            >
              <div translate class="pull-left float-left container-fluid px-0">
                <div class="row">
                  <div class="col-12">
                    <span class="venture__company-header mr-2" translate
                      >Lead Organization</span
                    >
                  </div>
                </div>
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background">
              <div class="row mb-2">
                <div class="col-12 px-md-0">
                  <app-organizer
                    [leadCompany]="entity.leadCompany"
                    [disabledSection]="true"
                    [childClass]="'col-12 col-lg-6'"
                    [editing]="false"
                    [userId]="profile?.id"
                  ></app-organizer>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Custom Metadata -->
        <ngb-panel
          *ngIf="customMetadata.attributeDescriptions.length > 0"
          cardClass="venture__body venture__accordion ignore-overflow"
          id="custom-metadata-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button
              class="btn d-flex align-items-center justify-content-between w-100 text-left"
              ngbPanelToggle
              type="button"
            >
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  >{{
                    uiTemplateName === TemplateName.Reduced3
                      ? 'Feedback Category'
                      : ('UI.Organization.OrganizationSpecificInformation'
                        | translate)
                  }}</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background m-0">
              <div
                *ngIf="shouldShowVentureDetails(); else hiddenContentRef"
                [class.hidden-content-mark]="shouldShowHiddenMarkBackground"
              >
                <app-custom-attributes
                  [formInput]="form"
                  [editable]="false"
                  [metadataRecord]="customMetadata"
                  [orderOfPropertyNames]="
                    VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES
                  "
                >
                </app-custom-attributes>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Submit Venture to Organizations -->
        <ngb-panel
          cardClass="venture__accordion venture__organizations ignore-overflow"
          id="submit-to-orgs"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button
              class="btn d-flex align-items-center justify-content-between w-100 text-left"
              ngbPanelToggle
              type="button"
            >
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  >{{
                    'VENTURE-DETAILS_Submit-venture-section'
                      | kczTranslationKey : uiTemplateName
                      | translate
                  }}</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background">
              <app-venture-organizations
                #ventureOrgs
                [editting]="false"
                [items]="entity?.submittedOrganizations"
                [userId]="profile?.id"
                [ignoreCompany]="entity?.leadCompany"
                [baseHttpService]="baseHttpService"
              ></app-venture-organizations>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Initiator &  Stakeholders -->
        <ngb-panel
          cardClass="venture__body venture__accordion"
          id="people-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button
              class="btn d-flex align-items-center justify-content-between w-100 text-left"
              ngbPanelToggle
              type="button"
            >
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  >{{ getFieldOptions('Initiator')?.displayName }} &
                  {{ getFieldOptions('Stakeholders')?.displayName }}</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body venture-people widget__card--background">
              <div class="row">
                <div class="col col-12 px-md-0">
                  <label
                    class="venture__label venture__label--head widget__label"
                    translate
                  >
                    {{ getFieldOptions('Initiator')?.displayName }}
                  </label>
                  <ng-container *ngIf="entity?.initiator; else deactiveUser">
                    <app-initator-cards
                      [items]="[entity?.initiator]"
                      [entityId]="entity?.id"
                      [httpService]="baseHttpService"
                      [enablePagination]="false"
                      [customUserRoles]="customAdminRoles"
                      [editable]="false"
                    ></app-initator-cards>
                  </ng-container>

                  <ng-template #deactiveUser>
                    <app-deactive-entity
                      [deactiveShowType]="deactiveShowTypeEnum.Card"
                    ></app-deactive-entity>
                  </ng-template>

                  <ng-container *ngIf="isOwner">
                    <label
                      class="venture__label venture__label--head widget__label mr-4"
                    >
                      {{ 'UI.PeopleEdit.PendingPeopleTitle' | translate }}
                    </label>

                    <app-stakeholder-cards
                      [httpService]="baseHttpService"
                      [items]="entity.timestampPendingStakeholders"
                      [entityId]="entity?.id"
                      [editable]="false"
                      [shouldValidatePersonBeforeAdding]="true"
                      [parentEntityName]="entityName"
                      [parentEntity]="entity"
                      [customUserRoles]="customUserRoles"
                    ></app-stakeholder-cards>
                  </ng-container>

                  <div
                    class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
                  >
                    <label
                      class="venture__label venture__label--head widget__label mr-4"
                      translate
                    >
                      {{ getFieldOptions('Stakeholders')?.displayName }}
                    </label>
                  </div>

                  <app-stakeholder-cards
                    [httpService]="baseHttpService"
                    [items]="entity.timestampStakeholders"
                    [entityId]="entity?.id"
                    [editable]="false"
                    [shouldValidatePersonBeforeAdding]="true"
                    [parentEntityName]="entityName"
                    [parentEntity]="entity"
                    [customUserRoles]="customUserRoles"
                  ></app-stakeholder-cards>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Venture Details -->
        <ngb-panel
          cardClass="venture__body venture__accordion"
          id="venture-details-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button
              class="btn d-flex align-items-center justify-content-between w-100 text-left"
              ngbPanelToggle
              type="button"
            >
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >{{
                    'Venture Details' | kczTranslationKey : uiTemplateName
                  }}</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background venture__characteristic-edit"
            >
              <div class="row">
                <div class="col col-12 px-md-0">
                  <ul
                    ngbNav
                    #navVentureDetails="ngbNav"
                    [destroyOnHide]="false"
                    class="nav-tabs"
                  >
                    <!-- Characteristics -->
                    <li
                      *ngIf="
                        uiTemplateName | visibility : 'BusinessCharacteristics'
                      "
                      ngbNavItem
                    >
                      <a ngbNavLink
                        ><span class="venture__tabs__title"
                          >{{ 'Venture Characteristic' | translate }}
                          <span class="after"></span> </span
                      ></a>

                      <ng-template ngbNavContent>
                        <div
                          *ngIf="
                            shouldShowVentureDetails();
                            else hiddenContentRef
                          "
                          [class.hidden-content-mark]="
                            shouldShowHiddenMarkBackground
                          "
                        >
                          <ul
                            *ngIf="
                              !uiTemplateName ||
                              !(
                                uiTemplateName | visibility : 'OnlyBusinessArea'
                              )
                            "
                            class="list-inline widget__highlight"
                          >
                            <ng-container
                              *ngFor="let fieldName of VENTURE_CHARACTERISTICS"
                            >
                              <li
                                *ngIf="
                                  form?.getRawValue()[fieldName]?.codeId !==
                                  'DefaultValue'
                                "
                                class="list-inline-item"
                              >
                                <span
                                  class="venture__characteristic widget__characteristics--background widget__highlight badge badge-pill badge-light"
                                >
                                  {{
                                    form?.getRawValue()[fieldName]?.value
                                  }}</span
                                >
                              </li>
                            </ng-container>
                          </ul>
                          <app-form-field-custom
                            [formInput]="form"
                            [editable]="false"
                            [showLabel]="true"
                            [attributeDescription]="
                              getFieldOptions('BusinessAreas')
                            "
                            [options]="{ showHiddenMarkBackground: isOwner }"
                          ></app-form-field-custom>
                        </div>
                      </ng-template>
                    </li>

                    <!-- Evolution -->
                    <li
                      *ngIf="uiTemplateName | visibility : 'VentureEvolution'"
                      ngbNavItem
                    >
                      <a ngbNavLink
                        ><span class="venture__tabs__title" translate
                          >{{
                            'Venture Evolution'
                              | kczTranslationKey : uiTemplateName
                          }}
                          <span class="after"></span> </span
                      ></a>

                      <ng-template ngbNavContent>
                        <div
                          *ngIf="
                            shouldShowVentureDetails();
                            else hiddenContentRef
                          "
                          [class.hidden-content-mark]="
                            shouldShowHiddenMarkBackground
                          "
                        >
                          <div class="row">
                            <div class="col-md-auto mr-5">
                              <label
                                class="venture__label widget__label"
                                translate
                                >{{
                                  getFieldOptions('CreatedDate')?.displayName
                                }}</label
                              >
                              {{
                                entity?.createdDate
                                  | localizedDate
                                    : DateFormat.FullDateTimeCommon
                              }}
                            </div>
                            <div class="col">
                              <label
                                class="venture__label widget__label"
                                translate
                                >{{
                                  getFieldOptions('ModifiedDate')?.displayName
                                }}</label
                              >
                              {{
                                entity?.modifiedDate
                                  | localizedDate
                                    : DateFormat.FullDateTimeCommon
                              }}
                            </div>
                          </div>

                          <ng-container
                            *ngIf="
                              uiTemplateName | visibility : 'ConstitutionState'
                            "
                          >
                            <div class="row">
                              <div class="col col-12">
                                <div
                                  class="venture__label widget__label"
                                  translate
                                >
                                  Constitution State
                                </div>
                                <app-form-field-custom
                                  [formInput]="form"
                                  [editable]="false"
                                  [type]="allType.VentureStatus"
                                  [attributeDescription]="
                                    getFieldOptions('Status')
                                  "
                                ></app-form-field-custom>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-template>
                    </li>
                  </ul>
                  <div
                    class="venture__content-tab"
                    [ngbNavOutlet]="navVentureDetails"
                  ></div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Project Idea Reference -->
        <ng-container *ngIf="uiTemplateName !== TemplateName.Reduced4">
          <ngb-panel
            cardClass="venture__body venture__accordion"
            id="venture-details-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >{{
                      'Venture References'
                        | kczTranslationKey : uiTemplateName
                        | translate
                    }}</span
                  >
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div
                class="venture__body widget__card--background venture__characteristic-edit"
              >
                <div class="row">
                  <div class="col col-12 px-md-0">
                    <div
                      *ngIf="shouldShowVentureDetails(); else hiddenContentRef"
                      [class.hidden-content-mark]="
                        shouldShowHiddenMarkBackground
                      "
                    >
                      <span class="venture__label widget__label mt-0 mb-3">
                        {{ 'Venture links' }}
                      </span>
                      <ng-container>
                        <app-form-field-custom
                          [type]="allType.MultiLink"
                          [formInput]="form"
                          [editable]="false"
                          [attributeDescription]="
                            getFieldOptions('LinkCompanyHomePage')
                          "
                          [options]="{
                            placeholder:
                              'VENTURE-DETAILS_Webpage-link-placeholder'
                              | translate,
                            showHiddenMarkBackground: isOwner,
                            fixedItems: form?.getRawValue()
                              .LinkCompanyHomePage || [
                              {
                                url: '',
                                faIconClass: 'fa-home'
                              }
                            ]
                          }"
                        ></app-form-field-custom>

                        <app-social-links
                          [items]="entity?.linkCollaborationSpaces"
                          [size]="40"
                        ></app-social-links>

                      </ng-container>
                      <app-form-field-custom
                        [type]="allType.Files"
                        [formInput]="form"
                        [showLabel]="true"
                        [editable]="false"
                        [attributeDescription]="
                          getFieldOptions('LinkAttatchFiles')
                        "
                        [options]="{
                          showHiddenMarkBackground: isOwner
                        }"
                      >
                      </app-form-field-custom>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
  </div>
</ng-template>

<ng-template #hiddenContentRef>
  <app-hidden-content></app-hidden-content>
</ng-template>
