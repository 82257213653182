<ng-container *ngIf="isShowFilterPane">
  <div
    class="filter-pane mb-3"
    *ngIf="isShowfilter && customFilters.length > 0"
  >
    <ng-container [ngSwitch]="filterPaneUi">
      <ng-container *ngSwitchCase="FilterPaneUi.Default">
        <ngb-accordion
          class="filter-pane__accordion filter-pane-default"
          [activeIds]="['filter-panel']"
        >
          <ngb-panel
            cardClass="ignore-overflow"
            id="filter-panel"
            class="m-0 p-0"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn-header-panel px-2 py-1"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                  >
                    {{ 'Filters' | translate }}
                  </span>
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div class="widget__card--background m-0">
                <div class="form-section" *ngIf="form">
                  <form [formGroup]="form">
                    <ng-container
                      *ngFor="let customFilter of customFilters; index as i"
                    >
                      <div class="mb-3">
                        <label class="pointer-cursor font-weight-bold">
                          {{ customFilter.attributeDescription?.displayName }}
                        </label>

                        <div class="d-flex flex-column">
                          <div
                            class="form-check custom-control custom-checkbox"
                          >
                            <input
                              class="form-check-input custom-control-input"
                              type="checkbox"
                              [id]="
                                'cb' +
                                customFilter.attributeDescription?.propertyName
                              "
                              [name]="
                                'cb' +
                                customFilter.attributeDescription?.propertyName
                              "
                              [(ngModel)]="customFilter.isSelectAll"
                              (ngModelChange)="onCheckSelectAll(customFilter)"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <label
                              class="pointer-cursor form-check-label custom-control-label flex-grow-1"
                              [for]="
                                'cb' +
                                customFilter.attributeDescription?.propertyName
                              "
                            >
                              {{ 'UI.Common.SelectAll' | translate }}
                            </label>
                          </div>
                          <!-- Filter: Use Multiple Selection -->
                          <app-form-field-custom
                            [formInput]="form"
                            [editable]="true"
                            [attributeDescription]="
                              customFilter.attributeDescription
                            "
                            [type]="AttributeType.MultipleSelection"
                            (valueChange)="
                              onValueSelectionChange($event, customFilter)
                            "
                          >
                          </app-form-field-custom>
                        </div>
                      </div>
                    </ng-container>

                    <div class="col-12 col-sm-4 p-0">
                      <ng-container
                        *ngTemplateOutlet="sortSection"
                      ></ng-container>
                    </div>

                    <ng-container
                      *ngFor="
                        let attrDes of customFormService.customAttributeDescriptions
                      "
                    >
                      <ng-container
                        *ngIf="attrDes.attributeType === AttributeType.DateTime"
                      >
                        <div class="mb-3">
                          <label class="pointer-cursor font-weight-bold">
                            {{ attrDes.displayName }}
                          </label>

                          <app-date-filter
                            [dateFilter]="
                              form.controls[attrDes.propertyName].value
                            "
                            (dateFilterChange)="updateDto(attrDes.propertyName)"
                          ></app-date-filter>
                        </div>
                      </ng-container>
                    </ng-container>
                  </form>

                  <div class="form-section__btn-wrapper">
                    <button
                      (click)="onClearAll()"
                      class="btn btn-outline-primary form-section__btn"
                      translate
                      [disabled]="isFiltering"
                    >
                      Clear All
                    </button>

                    <button
                      appDebounceClick
                      (debounceClick)="onFilter(true)"
                      class="btn btn-primary form-section__btn"
                      [disabled]="isFiltering"
                    >
                      <ng-container *ngIf="isFiltering">
                        <i class="fas fa-spinner fa-spin"></i>
                      </ng-container>
                      <span translate>Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-container>
      <ng-container *ngSwitchCase="FilterPaneUi.Simple">
        <ng-container
          *ngIf="!isMobile && !isTouchDevice; else filterMobileHeader"
        >
          <ngb-accordion
            #filterPane="ngbAccordion"
            class="filter-pane__accordion filter-pane-simple"
            [activeIds]="['filter-panel']"
          >
            <ngb-panel
              cardClass="ignore-overflow"
              id="filter-panel"
              class="m-0 p-0"
              (click)="openFilterMobile()"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button class="btn-header-panel" ngbPanelToggle type="button">
                  <div translate class="pull-left float-left">
                    <i class="fas fa-filter mr-2"></i>
                    <span
                      class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >
                      {{ 'Filters' | translate }}
                      <span *ngIf="numberOfFiltersApplied"
                        >({{ numberOfFiltersApplied }})</span
                      >
                    </span>
                  </div>
                  <div>
                    <button
                      *ngIf="
                        !filterPane.isExpanded('filter-panel') && hasFilters
                      "
                      class="btn btn-link form-section__btn clear-all-btn"
                      (click)="onClearAll()"
                      translate
                    >
                      UI.SmallFilter.ClearFilter
                    </button>
                    <i
                      class="fas float-right pull-right venture__company-header"
                      [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                    ></i>
                  </div>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="widget__card--background m-0">
                  <div class="form-section" *ngIf="form">
                    <form [formGroup]="form" class="row mb-2">
                      <ng-container
                        *ngFor="let customFilter of customFilters; index as i"
                      >
                        <div class="col-4 mb-2">
                          <label
                            class="pointer-cursor font-weight-bold mb-1 truncate-line-1"
                          >
                            {{ customFilter.attributeDescription?.displayName }}
                          </label>

                          <div class="d-flex flex-column">
                            <ng-select
                              [placeholder]="
                                'UI.SmallFilter.Select' | translate
                              "
                              [items]="
                                customFilter.attributeDescription.choice
                                  .selections
                              "
                              [multiple]="true"
                              bindLabel="value"
                              [selectableGroup]="true"
                              [closeOnSelect]="false"
                              [formControlName]="
                                customFilter.attributeDescription.propertyName
                              "
                              (add)="
                                onValueSelectionChange($event, customFilter)
                              "
                              (remove)="
                                onValueSelectionChange($event, customFilter)
                              "
                            >
                              <ng-template ng-header-tmp>
                                <div class="select-all">
                                  <input
                                    class="option-checkbox"
                                    type="checkbox"
                                    [(ngModel)]="customFilter.isSelectAll"
                                    (ngModelChange)="
                                      onCheckSelectAll(customFilter)
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                  />
                                  {{ 'UI.Common.SelectAll' | translate }}
                                </div>
                              </ng-template>
                              <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-item$="item$"
                                let-index="index"
                              >
                                <input
                                  class="option-checkbox"
                                  id="item-{{ index }}"
                                  type="checkbox"
                                  [checked]="item$.selected"
                                />
                                {{ item.value }}
                              </ng-template>

                              <ng-template
                                ng-label-tmp
                                let-item="item"
                                let-index="index"
                              >
                                {{ item.value }},
                              </ng-template>
                            </ng-select>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container
                        *ngFor="
                          let attrDes of customFormService.customAttributeDescriptions
                        "
                      >
                        <ng-container
                          *ngIf="
                            attrDes.attributeType === AttributeType.DateTime
                          "
                        >
                          <div class="mb-3 col-12">
                            <label class="pointer-cursor font-weight-bold mb-1">
                              {{ attrDes.displayName }}
                            </label>

                            <app-date-filter
                              [labelPosition]="'top'"
                              [dateFilter]="
                                form.controls[attrDes.propertyName].value
                              "
                              (dateFilterChange)="
                                updateDto(attrDes.propertyName)
                              "
                            ></app-date-filter>
                          </div>
                        </ng-container>
                      </ng-container>

                      <div class="col-4">
                        <ng-container *ngTemplateOutlet="sortSection">
                        </ng-container>
                      </div>
                    </form>

                    <div class="filter-btn-wrapper">
                      <button
                        *ngIf="hasFilters"
                        (click)="onClearAll()"
                        class="btn btn-link form-section__btn clear-all-btn"
                        translate
                        [disabled]="isFiltering"
                      >
                        UI.SmallFilter.ClearFilter
                      </button>

                      <button
                        appDebounceClick
                        (debounceClick)="onFilter(true)"
                        class="btn btn-primary form-section__btn apply-filter-btn mr-0"
                      >
                        <ng-container *ngIf="isFiltering">
                          <i class="fas fa-spinner fa-spin"></i>
                        </ng-container>
                        <span>{{
                          'UI.SmallFilter.ApplyFilter' | translate | uppercase
                        }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #filterMobileHeader>
  <div (click)="openFilterMobile()">
    <button class="btn-header-panel-mobile" type="button">
      <div translate class="pull-left float-left w-5 d-flex align-items-center">
        <i class="fas fa-filter mr-2"></i>
        <span class="venture__company-header mr-5 d-block d-lg-inline-block">
          {{ 'Filters' | translate }}
          <span *ngIf="numberOfFiltersApplied"
            >({{ numberOfFiltersApplied }})</span
          >
        </span>
      </div>
      <i
        class="fas fa-caret-right float-right pull-right venture__company-header"
      ></i>
    </button>
  </div>
</ng-template>

<ng-template #filterMobile>
  <div class="modal-header">
    <h6 class="modal-title truncate-line-2" translate>
      {{ 'Filters' | translate }}
    </h6>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="closeFilterMobile()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-confirmation">
    <div class="form-section" *ngIf="form">
      <form [formGroup]="form" class="row mb-2">
        <ng-container *ngFor="let customFilter of customFilters; index as i">
          <div class="col-12 mb-2">
            <label class="pointer-cursor font-weight-bold mb-1">
              {{ customFilter.attributeDescription?.displayName }}
            </label>

            <div class="d-flex flex-column">
              <ng-select
                [placeholder]="'UI.SmallFilter.Select' | translate"
                [items]="customFilter.attributeDescription.choice.selections"
                [multiple]="true"
                bindLabel="value"
                [selectableGroup]="true"
                [closeOnSelect]="false"
                [formControlName]="
                  customFilter.attributeDescription.propertyName
                "
                (add)="onValueSelectionChange($event, customFilter)"
                (remove)="onValueSelectionChange($event, customFilter)"
              >
                <ng-template ng-header-tmp>
                  <div class="select-all">
                    <input
                      class="option-checkbox"
                      type="checkbox"
                      [(ngModel)]="customFilter.isSelectAll"
                      (ngModelChange)="onCheckSelectAll(customFilter)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    {{ 'UI.Common.SelectAll' | translate }}
                  </div>
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <input
                    class="option-checkbox"
                    id="item-{{ index }}"
                    type="checkbox"
                    [checked]="item$.selected"
                  />
                  {{ item.value }}
                </ng-template>

                <ng-template ng-label-tmp let-item="item" let-index="index">
                  {{ item.value }},
                </ng-template>
              </ng-select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngTemplateOutlet="sortSection"></ng-container>

        <ng-container
          *ngFor="let attrDes of customFormService.customAttributeDescriptions"
        >
          <ng-container
            *ngIf="attrDes.attributeType === AttributeType.DateTime"
          >
            <div class="mb-3 col-12">
              <label class="pointer-cursor font-weight-bold mb-1">
                {{ attrDes.displayName }}
              </label>

              <app-date-filter
                [dateFilter]="form.controls[attrDes.propertyName].value"
                (dateFilterChange)="updateDto(attrDes.propertyName)"
              ></app-date-filter>
            </div>
          </ng-container>
        </ng-container>
      </form>

      <div class="filter-btn-wrapper d-flex flex-column">
        <button
          *ngIf="hasFilters"
          (click)="onClearAll()"
          class="btn btn-link form-section__btn clear-all-btn"
          translate
          [disabled]="isFiltering"
        >
          UI.SmallFilter.ClearFilter
        </button>

        <button
          appDebounceClick
          (debounceClick)="closeFilterMobile(); onFilter(true)"
          class="btn btn-primary form-section__btn apply-filter-btn mr-0 py-2 ml-0"
          [disabled]="isFiltering || !hasFilters"
        >
          <ng-container *ngIf="isFiltering">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span>{{
            'UI.SmallFilter.ApplyFilter' | translate | uppercase
          }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sortSection>
  <ng-container *ngIf="customSorts.length > 0">
    <form
      [formGroup]="sortForm"
      [ngClass]="{
        'col-12 mb-2': isMobile || isTouchDevice
      }"
    >
      <div class="row">
        <div class="col-12 mb-2">
          <label class="pointer-cursor font-weight-bold mb-1 truncate-line-1">
            Sortiert nach
          </label>

          <div class="d-flex justify-content-between">
            <div
              class="flex-grow-1"
              [style.max-width]="sortForm?.value?.propertyName ? '85%' : '100%'"
            >
              <ng-select
                [placeholder]="'UI.SmallFilter.Select' | translate"
                [items]="customSorts"
                [multiple]="false"
                bindLabel="displayName"
                bindValue="propertyName"
                [selectableGroup]="false"
                [closeOnSelect]="true"
                [searchable]="false"
                [formControlName]="'propertyName'"
                (change)="onValueSortingChange($event)"
              >
              </ng-select>
            </div>

            <button
              *ngIf="sortForm?.value?.propertyName"
              class="btn btn-outline-secondary border-none ml-2"
              (click)="onChangeSortDirection()"
            >
              <i
                *ngIf="sortForm.get('sortingDirection')?.value === 'ASC'"
                class="fas fa-sort-amount-up"
              ></i>
              <i
                *ngIf="sortForm.get('sortingDirection')?.value === 'DESC'"
                class="fas fa-sort-amount-down"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
