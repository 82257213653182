export enum EntityName {
  All = 'All',
  Event = 'Event',
  Venture = 'Venture',
  Person = 'Person',
  Organization = 'Organization',
  Challenge = 'Challenge',
  News = 'News',
  Message = 'Message',
  Tenant = 'Tenant',

  // Only using for News & Messages
  VentureSubmitOrganisation = 'VentureSubmitOrganisation',
  VentureSubmittedToChallenge = 'VentureSubmittedToChallenge',
  VentureAwarded = 'VentureAwarded',
  Post = 'Post',
  NewsMessage = 'NewsMessage',
}

export enum EntityStateCount {
  Liking = 'Liking',
  Following = 'Following',
  Comment = 'Comment',
  Venture = 'Venture',
  Event = 'Event',
  Participant = 'Participant',
}

export enum MessageType {
  News = 'News',
  Message = 'Message',
  VentureMessage = 'VentureMessage',
  OrganizationMessage = 'OrganizationMessage',
  ChallengeMessage = 'ChallengeMessage',
  EventMessage = 'EventMessage',
  PersonMessage = 'PersonMessage',
}

export enum MessageAction {
  Unknow = 0,
  Created,
  Updated,
  Shared,
  GetInTouch,

  VentureSubmittedOrganization = 5,
  UserJoined,
  Approved,
  Rejected,
  UnavailablePendingMember,

  PendingRequest = 10,
  WelcomeMessage,
  RemovedMember,
  Registered,
  VentureAwarded,

  DeactivatedEntity = 15,
  RemovedMyselfFromMember,
  VentureSubmittedToChallenge,
  NewVentureSubmittedToChallenge,
  AcceptedSubmittedVenture,
  RefusedSubmittedVenture,
  NewVentureSubmittedToOrganization,
  Post,
  InviteJuror,
  AcceptJuror,
  DenyJuror,
  WelcomeJuror,
}

export enum MessageValidation {
  MaxLength = 2500,
}

export enum SupportMessage {
  Event = EntityName.Event,
  Challenge = EntityName.Challenge
}
