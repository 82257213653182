<app-jury-menu-tabs-bar
  (filterChanged)="filterChanged($event)"
  [canViewRatingForm]="isAdmin || isJuror"
></app-jury-menu-tabs-bar>

<ng-container [ngSwitch]="currentFormState">
  <ng-container *ngSwitchCase="JurorFormState.List">
    <div class="mb-3" *ngIf="juryCards?.displayItems?.length === 0"></div>

    <!--Juror List-->
    <div class="mt-3">
      <app-people-cards
        #juryCards
        (peopleRemoved)="onRemoveJuror($event)"
        (removeItemClicked)="
          juryCards.openConfirmRemoveItemDialog($event, {
            prefix: 'UI.Challenge.RemoveJurorConfirmPrefix',
            suffix: 'UI.Challenge.RemoveJurorConfirmSuffix'
          })
        "
        [allowRemovePeople]="isAdmin"
        [items]="jurors$ | async"
        [parentEntityName]="EntityName.Challenge"
        [parentEntity]="challenge"
        [cardType]="CardType.Jury"
        removePeopleTitle="UI.RemoveDialog.RemoveFromJury"
      >
      </app-people-cards>
    </div>

    <!--Invitation Buttons-->
    <div class="mt-3" *ngIf="isAdmin">
      <button
        appRequireLogin
        appDebounceClick
        class="btn btn-primary form-section__btn m-0 mr-3"
        (debounceClick)="openModal()"
      >
        <span class="pointer-cursor" translate>UI.Challenge.InviteJurors</span>
      </button>

      <app-invitation-by-email
        label="UI.Challenge.InviteJurorsByEmail"
        messageSubject="UI.Invitation.Mail--Subject-Jury"
        messageBody="UI.Invitation.Mail-content--Body-For-Jury"
        entityName="{{ challenge.orgName }}"
        entityUrl="{{ challenge | call : getChallengeDetailUrl }}"
        [inviteTokenType]="TokenTypes.InviteChallengeJuror"
        [baseHttpService]="challengeService"
        [ccEmail]="profile?.email"
        [entityId]="challengeId"
        [senderName]="profile?.firstName + ' ' + profile?.lastName"
        [senderSignature]="
          'UI.Invitation.Signature'
            | translate
            | stringReplacement
              : {
                  '{name}': profile?.firstName + ' ' + profile?.lastName,
                  '{portalName}': portalName
                }
        "
      >
      </app-invitation-by-email>
    </div>

    <!--Pending List-->
    <ng-container *ngIf="isAdmin || isSinglePendingJuror">
      <h6 class="my-3 font-size-sm font-weight-bold">
        {{ pendingListTitle | translate }}
      </h6>

      <app-people-cards
        #pendingCards
        (extraActionClicked)="onExtraActionClicked($event)"
        (peopleRemoved)="onRemovePendingJuror($event)"
        (removeItemClicked)="
          pendingCards.openConfirmRemoveItemDialog($event, {
            prefix: 'UI.Challenge.RemoveJurorConfirmPrefix',
            suffix: 'UI.Challenge.RemoveJurorConfirmSuffix'
          })
        "
        [allowRemovePeople]="isAdmin"
        [enableExtraAction]="isAdmin || isSinglePendingJuror"
        [extraActions]="extraActions"
        [items]="pendingJurors"
        [parentEntityName]="EntityName.Challenge"
        [parentEntity]="challenge"
        [cardType]="CardType.Jury"
        removePeopleTitle="UI.RemoveDialog.RemoveFromJury"
      >
      </app-people-cards>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="JurorFormState.Rating">
    <ng-container [ngSwitch]="currentRatingState">
      <div *ngSwitchCase="JurorRatingState.Create"></div>

      <ng-container *ngSwitchCase="JurorRatingState.Link">
        <app-form-section
          [showEditButton]="showEditButton"
          [editting]="editing"
          (editState)="editing = $event"
          [shouldButtonsShown]="false"
        >
          <div
            class="d-flex flex-column"
            *ngIf="jurorRatingLink as link; else loading"
          >
            <label
              class="venture__label venture__label--head widget__label mr-4"
              translate
              >UI.Challenge.Link</label
            >

            <ng-container *ngIf="isAdmin && editing; else viewLinkTmp">
              <div class="row">
                <div class="col-12 col-md-3 mb-2 mb-md-0">
                  <input
                    class="people-cards__search-btn w-100 m-auto"
                    [placeholder]="'Name' | translate"
                    [(ngModel)]="link.juryRatingLinkName"
                    (keydown.enter)="$event.stopPropagation(); onSave()"
                    (keydown.escape)="cancel()"
                  />
                </div>

                <div class="col-12 col-md-9 input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"
                      ><i class="fas fa-link"></i
                    ></span>
                  </div>

                  <input
                    class="people-cards__search-btn flex-grow-1 flex-shrink-1"
                    [placeholder]="'Please Enter a Link' | translate"
                    [(ngModel)]="link.juryRatingLink"
                    (keydown.enter)="$event.stopPropagation(); onSave()"
                    (keydown.escape)="cancel()"
                  />
                </div>
              </div>

              <div class="form-section__btn-wrapper">
                <button
                  type="button"
                  class="btn btn-outline-primary form-section__btn"
                  (click)="cancel()"
                  translate
                >
                  Cancel
                </button>

                <button
                  appRequireLogin
                  appDebounceClick
                  (debounceClick)="onSave()"
                  class="btn btn-primary form-section__btn"
                >
                  <ng-container *ngIf="requesting">
                    <i class="fas fa-spinner fa-spin"></i>
                  </ng-container>

                  <span translate> Save </span>
                </button>
              </div>
            </ng-container>

            <ng-template #viewLinkTmp>
              <div class="row">
                <div class="col-12 col-md-3 mb-2 mb-md-0">
                  <span
                    *ngIf="
                      link.juryRatingLinkName as linkName;
                      else noLinkNameMessage
                    "
                    class="text-truncate mt-1 d-block w-fit mw-100"
                    [ngbTooltip]="linkName"
                  >
                    {{ linkName | translate }}
                  </span>

                  <ng-template #noLinkNameMessage>
                    <span class="message-empty" translate
                      >UI.Challenge.Empty-Link-Name</span
                    >
                  </ng-template>
                </div>

                <div
                  class="col-12 col-md-9 input-group align-items-center flex-nowrap"
                >
                  <div class="input-group-prepend flex-shrink-0 flex-grow-0">
                    <span class="input-group-text" id="basic-addon1"
                      ><i class="fas fa-link"></i
                    ></span>
                  </div>

                  <div class="flex-grow-1 w-100 text-truncate d-flex">
                    <a
                      *ngIf="
                        link.juryRatingLink as ratingLink;
                        else noLinkMessage
                      "
                      [ngbTooltip]="ratingLink"
                      [value]="ratingLink"
                      [class.text-underline]="ratingLink"
                      appUrlPrefix
                      class="ml-2 d-inline-block mw-100 text-truncate"
                      target="_blank"
                    >
                      {{ ratingLink | translate }}
                    </a>
                    <ng-template #noLinkMessage>
                      <span class="message-empty ml-1" translate>
                        UI.Challenge.Empty-Link
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </app-form-section>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="d-flex justify-content-center mt-4">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-template>
