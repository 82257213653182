<div class="social-links">
  <div
    *ngFor="let item of items"
    class="social-links__item"
    [style.width.px]="size"
    [style.height.px]="size"
  >
    <div class="social-links__image-wrapper">
      <a
        [value]="item.link"
        appUrlPrefix
        target="_blank"
        [title]="item.socialMediaName"
      >
        <img
          [src]="item.socialMediaLogo"
          appDefaultPlaceholder
          appDynamicImgUrl
        />
      </a>
    </div>
  </div>
</div>
