import { Injectable } from '@angular/core';
import { EntityViewMode, EventViewMode } from '@src/app/components/master-layout/page-header/page-header.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  private eventViewMode$ = new BehaviorSubject<EventViewMode>(
    EventViewMode.TileView
  );
  private entityViewMode$ = new BehaviorSubject<EntityViewMode>(
      EntityViewMode.TileView
  );

  private entityGridView$ = new BehaviorSubject<boolean>(
      true
  );

  private entityFullscreenMode$ = new BehaviorSubject<boolean>(
      false
  );
  private entityName$ = new BehaviorSubject<EntityName>(EntityName.All);

  getEventViewMode(): Observable<EventViewMode> {
    return this.eventViewMode$.asObservable();
  }

  getEntityViewMode(): Observable<EntityViewMode> {
      return this.entityViewMode$.asObservable();
  }

  getEntityFullscreenwMode(): Observable<boolean> {
      return this.entityFullscreenMode$.asObservable();
  }

  getEntityName(): Observable<EntityName> {
    return this.entityName$.asObservable();
  }

  setEventViewMode(value: EventViewMode): void {
    this.eventViewMode$.next(value);
  }

  setEntityGridView(value: boolean): void {
      this.entityGridView$.next(value);
      if (value) {
          return this.entityViewMode$.next(EntityViewMode.TileView);
      }
      return this.entityViewMode$.next(EntityViewMode.TableView);
  }

  setEntityFullscreenMode(value: boolean): void {
      this.entityFullscreenMode$.next(value);
  }

  setEntityName(value: EntityName): void {
    this.entityName$.next(value);
  }
}
