import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavInterface } from '@shared/interfaces/nav.interface';
import { environment } from '@env/environment';
import { UserInterface } from '@shared/interfaces/user.interface';
import { GlobalFilterComponent } from '@components/master-layout/navs/components/global-filter/global-filter.component';
import { SessionService } from '@core/session.service';
import { AuthenService } from '@core/authen/authen.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { I18nService } from '@core/i18n/i18n.service';
import { CommunitySelectorStoredService } from '@core/services/community-selector-stored.service';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { StringUtils } from '@shared/utils/string-utils';
import { UrlParam } from '@shared/enums/url-param.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { FormUtils } from '@shared/utils/form-utils';
import { BaseComponentWithServiceComponent } from '@components/base/base-list/base-component-with-service/base-component-with-service.component';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styles: ``
})
export class GlobalSearchComponent
    extends BaseComponentWithServiceComponent
    implements OnInit
{

    // tslint:disable-next-line:variable-name
    @Input() global_org_id: string; // Central config
    @ViewChild('searchInput') searchInput!: ElementRef;
    private inputFocusPending = false;

    isSearchActivated = false;
    globalFilterRegisterEvent: any;
    isVisibleFilterButton = false;
    orgUrl = environment.jipUrl.organizations;

    profile: UserInterface;
    isShowSearchBar = false;
    searchText = '';

    homeNav = { route: '/', name: 'Home', jipIcon: 'home' };

    @ViewChild('globalFilter') globalFilter: GlobalFilterComponent;
    navDropdownItems: NavInterface[];

    selectedOrg;
    flipWord: string[] = [];
    currentWord = '';
    private timer = 3000;
    private index = 0;

    constructor(
        public sessionService: SessionService,
        public authenService: AuthenService,
        private breakpointObserver: BreakpointObserver,
        private i18nService: I18nService,
        public communitySelectorStored: CommunitySelectorStoredService,
        readonly searchEntitiesService: SearchEntitiesService,
        private translateService: TranslateService,
    ) {
        super(sessionService);
    }

    ngOnInit(): void {
        this.sessionService.isHeaderVisible$.next(true);

        const globalSearchParam = StringUtils.getParamFromUrl(
            UrlParam.GlobalSearchTab
        );
        if (globalSearchParam) {
            this.isShowSearchBar = true;
        }

        this.communitySelectorStored.selectedOrg
            .pipe(untilDestroyed(this))
            .subscribe((org) => {
                this.selectedOrg = org;
            });

        this.communitySelectorStored.keyword
            .pipe(untilDestroyed(this))
            .subscribe((keyword: string) => {
                this.searchText = keyword;
            });

        this.currentWord = this.flipWord.length > 0 ? this.flipWord[0] : '';
        setInterval(() => {
            this.getNextWord();
        }, this.timer);
    }

    ngAfterViewChecked() {
        if (this.isShowSearchBar && this.searchInput) {
            this.inputFocusPending = false;
            this.searchInput.nativeElement.focus();
        }
    }

    toggleSearch(): void {
        this.isSearchActivated = !this.isSearchActivated;
    }

    toggleSearchBar(): void {
        this.isShowSearchBar = !this.isShowSearchBar;
    }

    navigateToActiveInnovationSpaceDetail(id = null) {
        if (id) {
            FormUtils.navigateToWithLanguagePath(
                this.sessionService,
                this.orgUrl + '/' + id
            );
        } else {
            FormUtils.navigateToWithLanguagePath(this.sessionService, '/');
        }
    }

    clearText() {
        this.communitySelectorStored.setKeyword('');
        return this.toggleSearchBar();
    }

    onSearch() {
        if(!this.isShowSearchBar){
            return false;
        }
        this.communitySelectorStored.search();
    }

    onClearText(): void {
        this.communitySelectorStored.setKeyword('');
        this.searchText = '';
        this.communitySelectorStored.search();
    }

    onSearchTextChange(event): void {
        this.communitySelectorStored.setKeyword(event);
    }

    searchBy(text: string): void {
        this.communitySelectorStored.setKeyword(this.translateService.instant(text));
        this.communitySelectorStored.search();
    }

    private getNextWord(): void {
        this.index++;
        if (this.index >= this.flipWord.length) {
            this.index = 0;
        }
        this.currentWord = this.flipWord[this.index];
    }
}
