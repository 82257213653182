import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SessionService } from '@core/session.service';
import { FormUtils } from '@shared/utils/form-utils';
import { EntityStateCountComponent } from '@src/app/shared/components/entity-state-count/entity-state-count.component';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';

@Component({
  selector: 'app-base-widget',
  template: ``,
})
export class BaseWidgetComponent
  extends EntityStateCountComponent
  implements OnChanges
{
  @Input() domain: string;

  @Input() columns: string;

  // tslint:disable-next-line:variable-name
  @Input() page_size: '2' | '3';

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_paginator: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_creation_btn: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() is_open_in_new_tab: boolean;

  // tslint:disable-next-line:variable-name
  @Input() bg_color: string;

  // tslint:disable-next-line:variable-name
  @Input() org_id: string;

  // tslint:disable-next-line:variable-name
  @Input() current_template_slug: string;

  isHeaderVisible = false;

  entityPath: string;

  constructor(protected readonly sessionService: SessionService) {
    super();
    this.sessionService.isHeaderVisible$.pipe(untilDestroyed(this)).subscribe((isVisible: boolean) => {
      this.isHeaderVisible = isVisible;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.domain?.currentValue && this.domain) {
      this.sessionService.setDomain(this.domain);
    }
  }

  goToEntityDetails(id: number): void {
    const url = `${this.entityPath}/${id}`;
    const target = this.is_open_in_new_tab ? '_blank' : '_self';

    FormUtils.openWithLanguagePath(this.sessionService, url, target);
  }
}
