import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { AuthenService } from '@core/authen/authen.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { EntityCreationModalService } from '@core/services/entity-creation-modal.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import {
  EntityName,
  EntityStateCount,
  MessageAction,
} from '@shared/enums/entity-name.enum';
import { NewsMessagesFilterType } from '@shared/enums/filter.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { News } from '@shared/interfaces/news.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { CrossSearchingEntity } from '@shared/models/cross-searching-entity.model';
import { PageSizeConfig } from '@shared/models/pagination.model';
import {
  applyFilterByWidgetConfig,
  applyFilterStreamByWidgetConfig,
} from '@shared/utils/filter.utils';
import { FormUtils } from '@shared/utils/form-utils';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { EntityGlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import { BehaviorSubject, Observable, firstValueFrom, iif } from 'rxjs';
import { filter, finalize, map, share, switchMap, tap } from 'rxjs/operators';

const { jipUrl, wordpressJipImages } = environment;

@Component({
  selector: 'app-news-tiles',
  templateUrl: './news-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class NewsTilesComponent
  extends EntityStateCountComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() domain: string;

  @Input() columns: string;

  // tslint:disable-next-line:variable-name
  @Input() page_size: '2' | '3';

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_paginator: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_creation_btn: boolean;

  // tslint:disable-next-line:variable-name
  @Input() bg_color: string;

  // tslint:disable-next-line:variable-name
  @Input() org_id: string;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() is_open_in_new_tab: boolean;

  // tslint:disable-next-line:variable-name
  @Input() redirect_url: string;

  // tslint:disable-next-line:variable-name
  @Input() filter_selection: string;

  @Input() stream: string;

  data$: Observable<ApiGetResponse<News>>;
  isLoading = false;
  skeletonCount = [];
  pageIndex = 1;
  pageIndex$ = new BehaviorSubject<number>(this.pageIndex);

  readonly entityPath = jipUrl.news;

  readonly entityCreationPath = `${this.entityPath}/create`;

  readonly user$: Observable<UserInterface> = this.authService
    .getProfile();

  readonly MessageAction = MessageAction;

  constructor(
    public readonly listService: NewsMessagesHttpService,
    public readonly searchEntitiesService: SearchEntitiesService,
    public readonly authService: AuthenService,
    protected readonly sessionService: SessionService,
    private readonly entityCreationModalService: EntityCreationModalService,
    private newsMessagesService: NewsMessagesHttpService
  ) {
    super();

    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      if (!this.org_id) {
        this.isLoading = loading;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.domain?.currentValue && this.domain) {
      this.sessionService.setDomain(this.domain);
    }

    if (changes.org_id) {
      this.data$ = iif(
        () => !!this.org_id,
        this.getSingleFilterData(),
        this.getSearchEntityData()
      );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngOnInit(): Promise<void> {
    await this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.listenPostCreated();
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  syncCommentStateCount(news: News): void {
    this.syncEntityStateCount(
      EntityStateCount.Comment,
      EntityName.News,
      [news],
      this.listService
    );
  }

  goToListPage(): void {
    FormUtils.openWithLanguagePath(
      this.sessionService,
      this.redirect_url || this.entityPath,
      this.is_open_in_new_tab ? '_blank' : '_self'
    );
  }

  changePaging(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.pageIndex$.next(this.pageIndex);
  }

  getSingleFilterDto() {
    let dto = {
      pageSize: this.page_size,
      pageIndex: this.pageIndex,
    } as unknown as EntityGlobalFilterCriteria;

    dto.filterType = NewsMessagesFilterType.News;
    dto.organizationIds = [Number(this.org_id)];
    applyFilterByWidgetConfig(this.filter_selection, dto);
    applyFilterStreamByWidgetConfig(this.stream, dto);

    return dto;
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncCommentsState();
  }

  private async configSearchService(): Promise<void> {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.News }];
    this.searchEntitiesService.currentFilter = EntityName.News;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    const isHeaderVisible = await firstValueFrom(this.sessionService.isHeaderVisible$);

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter =
      !this.org_id && isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.filterType = NewsMessagesFilterType.News;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];
    applyFilterByWidgetConfig(this.filter_selection, filterDTO);
    applyFilterStreamByWidgetConfig(this.stream, filterDTO);

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  private listenPostCreated(): void {
    this.entityCreationModalService.newsMessageCreated$
      .pipe(
        filter((entityName: EntityName) => entityName === EntityName.Post),
        untilDestroyed(this)
      )
      .subscribe(() => this.searchEntitiesService.refreshList());
  }

  private getSingleFilterData(): Observable<ApiGetResponse<News>> {
    return this.pageIndex$.pipe(
      switchMap(() =>
        this.sessionService.apiReady$.pipe(
          filter(Boolean),
          switchMap(() =>
            this.newsMessagesService.paginateX(this.getSingleFilterDto()).pipe(
              tap(() => (this.isLoading = true)),
              finalize(() => (this.isLoading = false)),
              map((res: any) => {
                this.processEntityStateCount(res.items);
                return res;
              })
            )
          )
        )
      ),
      share()
    );
  }

  private getSearchEntityData(): Observable<ApiGetResponse<News>> {
    return this.searchEntitiesService.entities$.pipe(
      filter((entities: CrossSearchingEntity[]) => entities?.length > 0),
      switchMap((entities: CrossSearchingEntity[]) => entities[0].content),
      map((res: ApiGetResponse<News>) => {
        this.processEntityStateCount(res.items);
        return res;
      }),
      share()
    );
  }

  private processEntityStateCount(items: any): void {
    this.runEntityStateCount(EntityName.News, items, this.listService);
  }
}
