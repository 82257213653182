<div class="pending-people">
  <ng-container *ngIf="items?.length > 0; else emptyRef">
    <div
      *ngFor="let item of items; index as i"
      class="pending-people__item d-md-flex align-items-center mx-3 mx-md-4"
    >
      <app-people-info-small
        [person]="item"
        [fromPendingPeople]="true"
        class="mr-3"
      ></app-people-info-small>

      <div class="ml-auto mb-3 mb-md-0 flex-shrink-0 d-flex">
        <div ngbDropdown class="dropdown-no-arrow" container="body">
          <div ngbDropdownToggle>
            <button class="jip-btn-big btn btn-outline-primary py-2 px-3 mr-2">
              <i class="far fa-times-circle"></i>
              <span class="ml-2 font-weight-bold">{{
                'UI.Common.Reject' | translate | uppercase
              }}</span>
            </button>
          </div>

          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="rejectOne(item, i)"
              [disabled]="requesting[i]?.reject || requesting.all?.reject"
            >
              <i class="far fa-times-circle"></i>
              {{'UI.PendingMember.RejectNow' | translate}}
            </button>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="handleMessageClick(item, i, 'reject')"
            >
              <i class="far fa-comment-alt"></i>
              {{'UI.PendingMember.RejectWithMessage' | translate}}
            </button>
          </div>
        </div>

        <div ngbDropdown class="dropdown-no-arrow" container="body">
          <div ngbDropdownToggle>
            <button class="btn btn-secondary-blue py-2 px-3">
              <i class="far fa-check-circle"></i>
              <span class="ml-2 font-weight-bold">{{
                'UI.Common.Approve' | translate | uppercase
              }}</span>
            </button>
          </div>

          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="approveOne(item, i)"
              [disabled]="requesting[i]?.approve || requesting.all?.approve"
            >
              <i class="far fa-check-circle"></i>
              {{'UI.PendingMember.ApproveNow' | translate}}
            </button>
            <button
              ngbDropdownItem
              class="py-2 px-3 pointer-cursor btn"
              (click)="handleMessageClick(item, i, 'approve')"
            >
              <i class="far fa-comment-alt"></i>
              {{'UI.PendingMember.ApproveWithMessage' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mr-4">
      <app-bootstrap-pagination
        [page]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (changePageSize)="onPageSizeChange($event)"
        (changePage)="onPageChange($event)"
      ></app-bootstrap-pagination>
    </div>
  </ng-container>

  <ng-template #emptyRef>
    <div class="people-cards-emtpy" translate>
      UI.Pending-people.Empty-message
    </div>
  </ng-template>
</div>
