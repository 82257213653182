<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>
<ng-container>
  <div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="onCloseModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body new-message-dialog-modal-body">
    <form [formGroup]="form">
      <div class="d-flex flex-row">
        <div class="sender-profile d-flex justify-content-center">
          <div class="message-to-profile">
            <img [src]="profile?.image" appDefaultAvatar appDynamicImgUrl />
          </div>
        </div>
        <div class="message-content-quill mb-3">
          <app-form-field-custom
            #messageForm
            [formInput]="form"
            [type]="AttributeType.RTE"
            [attributeDescription]="contentAttributeDescription"
            [shouldValidateOnTouch]="true"
            [options]="{
              placeholder: 'Say something...' | translate,
              allowEmojiOnRTE: true,
              allowImgOnRTE: false,
              allowCropImg: true,
              aspectRatio: 15 / 8
            }"
          >
          </app-form-field-custom>
        </div>
      </div>

      <app-mail-forwarding-checkbox
        [alignLeft]="false"
        formControlName="isSendMail"
      ></app-mail-forwarding-checkbox>
    </form>
    <ng-content></ng-content>
  </div>
  <div class="modal-footer message-command">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      {{ 'Cancel' | translate | uppercase }}
    </button>
    <button
      appRequireLogin
      [disabled]="
        form.invalid ||
        isSubmitting ||
        messageForm?.imageInputDisplay?.uploading
      "
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      (click)="send()"
    >
      <ng-container *ngIf="requesting">
        <i class="fas fa-spinner fa-spin btn-icon-right"></i>
      </ng-container>
      <span>{{ 'Send' | translate | uppercase }}</span>
    </button>
  </div>
</ng-container>
