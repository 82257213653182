import { Component, Injector, Input, OnInit } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { News } from '@shared/interfaces/news.interface';
import { Post } from '@shared/interfaces/post.interface';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { TenantHttpService } from '@src/app/core/http/tenant.http.service';
import { CommunitySelectorStoredService } from '@src/app/core/services/community-selector-stored.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DateFormat } from '@shared/enums/date.enum';
import { YouTransformPipe } from '@shared/pipes/you-transform.pipe';
import { environment } from '@env/environment';
import { NewPostDialogComponent } from '@components/news-messages/components/new-post-dialog/new-post-dialog.component';

@Component({
  selector: 'app-post-dialog',
  templateUrl: './post-dialog.component.html',
})
export class PostDialogComponent
  extends NewPostDialogComponent
  implements OnInit
{
  @Input() currentPersonId?: number;

  @Input() peopleURL: string = environment.jipUrl.people;
  @Input() hideMessageDate = false;

  rawHtml: any;
  safeHtml!: SafeHtml;
  DateFormat = DateFormat;


  //#region SERVICES
  protected toastService: ToastService;
  protected orgHttpService: OrganizationHttpService;
  protected tenantHttpService: TenantHttpService;
  protected sessionService: SessionService;
  //#endregion End Services

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly unsavedFormCheckService: UnsavedFormCheckService,
    protected readonly injector: Injector,
    protected readonly authService: AuthenService,
    protected readonly sendMessageHttpService: SendMessageHttpService,
    protected readonly newsMessagesHttpService: NewsMessagesHttpService,
    protected readonly userConfig: UserConfigManagementService,
    protected communitySelectorService: CommunitySelectorStoredService,
    protected youTransformPipe: YouTransformPipe,
    protected sanitizer: DomSanitizer
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      injector,
      authService,
      sendMessageHttpService,
      newsMessagesHttpService,
      userConfig,
      communitySelectorService
    );
    this.toastService = injector.get<ToastService>(ToastService);
    this.orgHttpService = injector.get<OrganizationHttpService>(
      OrganizationHttpService
    );
    this.tenantHttpService = injector.get<TenantHttpService>(TenantHttpService);
    this.sessionService = injector.get<SessionService>(SessionService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const isCreationMode = false;
    this.getStreamOptions(isCreationMode);
    this.rawHtml = this.getSafeHtml(this.postData?.message || '');
  }

  get streamNames(): string {
      return this.streams
          ?.filter(stream => this.postData?.content?.streams.includes(stream.id))
          .map(stream => stream.name)
          .join(', ') || '';
  }

  getSafeHtml(html: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  isCreationMode(post: News): boolean {
      return !post?.id;
  }

  isFormNotSaved(): boolean {
    return this.content?.value;
  }

  isAdvanceMode(post: News): boolean {
    return (
      !this.disableTinyMCE &&
      this.form?.controls['editorType'].value === this.EditorType.ADVANCE
    );
  }

  createPost(): void {
    this.isSubmitting = true;

    const payload: Post = {
      content: this.content?.value,
      imageId: this.imageId?.value,
      orgId: this.currentOrg?.id ?? this.globalOrgId,
      streams: this.streamsControl?.value,
      editorType: this.editorTypeControl?.value,
    };

    this.newsMessagesHttpService.createPost(payload).subscribe(() => {
      this.toastService.showSuccess('UI.Toast.ShareSuccessfully');
      this.isSubmitting = false;
      this.activeModal.close(true);
    });
  }

  formFieldLoaded(postDialogComponent: FormFieldCustomComponent): void {
    if (!this.postData?.content?.image) return;

    const image: ImageInterface = { url: this.postData.content.image };
    postDialogComponent?.imageInputDisplay?.setImage(image);
  }

  onSubmit(post: News): void {
    // this.isCreationMode(post) ? this.createPost() : this.editPost(post);
  }

  getTitle(post: News): string {
    return this.getSender();
  }

  getSender(): string {
      return this.youTransformPipe.transform(
          this.postData.from,
          this.currentPersonId
      );
  }
}
