<div class="modal-header">
  <div class="d-flex align-items-center">
    <h6 class="modal-title">{{ 'Messages' | translate }}</h6>
    <button
      *ngIf="haveRightsToSendMessage"
      (click)="onSendMessageClick($event)"
      class="page-header__btn btn btn-primary font-weight-bold"
      type="button"
    >
      <em class="fas fa-paper-plane pr-2"></em>
      <span class="text-uppercase" translate>
        UI.NewMessage.SendMessageToAllMembers
      </span>
    </button>
  </div>

  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="close()"
  >
    <span aria-hidden="true"> &times; </span>
  </button>
</div>

<div class="modal-body">
  <ng-container *ngIf="isLoadingItems$ | async; else messageListTemplate">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #messageListTemplate>
    <ng-template #emptyContent>
      <app-empty-content></app-empty-content>
    </ng-template>

    <app-news-and-message-list
      *ngIf="items.length > 0; else emptyContent"
      [currentUserId]="currentUser?.id"
      [items]="items"
      [backgroundColor]="bg_color"
      (likesModalOpened)="openLikesModal($event)"
      (messageListModalClosed)="onMessageListModalClosed($event)"
    ></app-news-and-message-list>
  </ng-template>
  
</div>
