<div *ngIf="data" class="card-item person-card col-12">
  <div class="row">
    <div class="col-12 col-md-3 col-xl-2 p-0">
      <div
        appHoverDisplay
        [dataHoverDirective]="data"
        class="card-item__profile_img_wrapper"
      >
        <img
          [src]="data.image?.url || data.image"
          appDefaultAvatar
          appDynamicImgUrl
        />
      </div>
    </div>
    <a
      class="col-12 col-md-9 col-xl-10 link-unstyled"
      appUnsavedFormCheck
      [href]="entityUrl"
    >
      <div
        class="person-card__info pl-md-4 pt-3 pb-2 h-100 col widget__news-message--background"
      >
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <h5
                class="venture__title widget__heading truncate-line-1"
                appTooltip
                triggers="manual"
                #t="ngbTooltip"
                [toolTipControl]="t"
                [ngbTooltip]="data.firstName + data.lastName"
              >
                {{ data.firstName }} {{ data.lastName }}
              </h5>
              <app-bot-flag
                *ngIf="data.isBot"
                [parentClass]="'ml-1'"
              ></app-bot-flag>
            </div>
          </div>

          <div class="col-12 col-md-5">
            <div class="venture__label widget__label mt-3 mt-md-2">
              {{ 'UI.People.JobTitle' | translate }}
            </div>
            <div class="widget__content person-card__info--ellipsis">
              {{ data.jobTitle }} &nbsp;
            </div>

            <div class="venture__label widget__label mt-3 mt-md-2" translate>
              Company Name
            </div>
            <div class="widget__content person-card__info--ellipsis">
              {{ data.company?.canSee ? (data.company?.name || data.company) : '' }} &nbsp;
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="venture__label widget__label mt-3 mt-md-2" translate>
              UI.New-Message.Offering
            </div>
            <div
              class="widget__content person-card__badge-wrapper person-card__info--ellipsis"
            >
              <ng-container *ngIf="data.personalOfferings">
                <ng-container
                  *ngFor="let personalOfferingItem of data.personalOfferings"
                >
                  <div *ngIf="personalOfferingItem.content" class="badge">
                    {{ personalOfferingItem.content }}
                  </div>
                </ng-container>
              </ng-container>
              &nbsp;
            </div>

            <div class="venture__label widget__label mt-3 mt-md-2" translate>
              UI.New-Message.Seeking
            </div>
            <div
              class="widget__content person-card__badge-wrapper person-card__info--ellipsis"
            >
              <ng-container *ngIf="data.personalSearchings">
                <ng-container
                  *ngFor="let personalSeekingItem of data.personalSearchings"
                >
                  <div *ngIf="personalSeekingItem.content" class="badge">
                    {{ personalSeekingItem.content }}
                  </div>
                </ng-container>
              </ng-container>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
