<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="items && items.length > 0; else emptyContent">
    <ng-container *ngIf="!isGridView; else entityGridView">
      <app-metadata-table-people
        [entityDatas]="items"
        [isLoading]="isLoading"
        [editable]="false"
      ></app-metadata-table-people>
    </ng-container>

    <ng-template #entityGridView>
      <ul
        class="widget__container people-list"
        [style.background-color]="bg_color"
      >
        <ng-container *ngFor="let person of items; index as listIndex">
          <li *ngIf="person && person" class="people__item">
            <div class="people__wrapper">
              <div class="people__body col-12 widget__card--background">
                <a
                  appUnsavedFormCheck
                  href="{{ entitybase }}/{{ person.id }}"
                  class="link-unstyled p-0"
                >
                  <div class="row">
                    <div class="col-5 col-md-6 col-lg-5 p-0 pr-lg-3">
                      <div class="widget__avatar-wraper">
                        <app-image-wrapper
                          wrapperClasses="people__avatar"
                          imageclasses="widget__image widget__avatar-image"
                          [imgObject]="person.image"
                          [imgAlt]="person.name"
                          [isVCardShown]="isLogin"
                          [titleTemplateRef]="qrCodeTitle"
                          [getEntityDataCallback]="getVcard.bind(this, person)"
                        >
                          <ng-template #qrCodeTitle>
                            <div class="text-center">
                              <h3
                                class="page-header__text widget__heading"
                                translate
                              >
                                UI.Profile.MemberCard
                              </h3>
                              <span
                                class="venture__label widget__label m-0"
                                translate
                                >UI.Profile.MemberId</span
                              >
                              <span class="widget__content">
                                {{ person.id }}
                              </span>
                            </div>
                          </ng-template>
                        </app-image-wrapper>
                      </div>
                    </div>

                    <div
                      class="col-7 col-md-6 col-lg-7 d-flex flex-column pl-lg-0"
                    >
                      <div
                        class="people__name widget__heading mt-1 mt-md-2 mt-xl-3 d-flex align-items-center"
                      >
                        <span
                          data-placement="top"
                          appTooltip
                          triggers="manual"
                          #t="ngbTooltip"
                          [toolTipControl]="t"
                          [ngbTooltip]="
                            person.firstName + ' ' + person.lastName
                          "
                          class="truncate-line-1"
                        >
                          {{ person.firstName + ' ' + person.lastName }}
                        </span>
                        <app-bot-flag
                          *ngIf="person.isBot"
                          [parentClass]="'ml-1'"
                        ></app-bot-flag>
                      </div>
                      <div
                        class="people__label widget__label mt-1 mt-md-2 mt-xl-3"
                      >
                        {{ 'UI.People.JobTitle' | translate }}
                      </div>
                      <div class="people__info">
                        <span
                          data-placement="top"
                          appTooltip
                          triggers="manual"
                          #t2="ngbTooltip"
                          [toolTipControl]="t2"
                          [ngbTooltip]="person.jobTitle"
                          class="truncate-line-1"
                        >
                          {{ person.jobTitle }}
                        </span>
                      </div>
                      <div class="people__label widget__label mt-1" translate>
                        Company name
                      </div>
                      <div class="people__info">
                        <span
                          data-placement="top"
                          appTooltip
                          triggers="manual"
                          #t3="ngbTooltip"
                          [toolTipControl]="t3"
                          [ngbTooltip]="person.company?.displayText"
                          class="truncate-line-1"
                        >
                          {{
                            person.company?.canSee ? person.company?.name : ''
                          }}
                        </span>
                      </div>
                      <div
                        *ngIf="person.id !== currentUser?.id"
                        class="d-flex align-items-center mt-1 mt-md-2"
                      >
                        <!-- More -->
                        <div class="more-options" ngbDropdown>
                          <button
                            placement="bottom-left"
                            class="btn btn-interact btn-secondary-dark text-white mr-2"
                            [class.btn-interact--big]="!isMobileScreen"
                            [class.btn-interact--small]="isMobileScreen"
                            (click)="$event.preventDefault()"
                            ngbDropdownToggle
                          >
                            <i class="far fa-ellipsis-h fa-lg align-middle"></i>
                            <span class="text-hide-small" translate>
                              UI.Common.Button.More
                            </span>
                          </button>
                          <ul ngbDropdownMenu>
                            <!-- Ventures -->
                            <li
                              ngbDropdownItem
                              class="list-inline-item venture__actions-item"
                            >
                              <ng-container
                                *ngIf="isLoadingVenturesCount; else venturesBtn"
                              >
                                <div class="text-center">
                                  <i class="fas fa-spinner fa-spin"></i>
                                </div>
                              </ng-container>
                              <ng-template #venturesBtn>
                                <a
                                  appDebounceClick
                                  (debounceClick)="
                                    showEntityModal(EntityName.Venture, person)
                                  "
                                >
                                  <i class="fas fa-briefcase"></i>
                                  <span
                                    class="venture__actions-label font-weight-bold"
                                  >
                                    <span>{{ 'Ventures' | translate }}</span>
                                    <span
                                      [innerHTML]="
                                        person.venturesCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                </a>
                              </ng-template>
                            </li>

                            <!-- Events -->
                            <li
                              ngbDropdownItem
                              class="list-inline-item venture__actions-item"
                            >
                              <ng-container
                                *ngIf="isLoadingEventsCount; else eventsBtn"
                              >
                                <div class="text-center">
                                  <i class="fas fa-spinner fa-spin"></i>
                                </div>
                              </ng-container>
                              <ng-template #eventsBtn>
                                <a
                                  appDebounceClick
                                  (debounceClick)="
                                    showEntityModal(EntityName.Event, person)
                                  "
                                >
                                  <i class="fas fa-folder-open"></i>
                                  <span
                                    class="venture__actions-label font-weight-bold"
                                  >
                                    {{ 'Events' | translate }}
                                    <span
                                      [innerHTML]="
                                        person.eventsCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                </a>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="people__footer">
                <app-interaction-bar-people
                  [isSamePerson]="person.id === currentUser?.id"
                  [data]="person"
                  [httpService]="listService"
                  [isLoadingFollowingsCount]="isLoadingFollowingsCount"
                  [showDeactivateProfileBtn]="false"
                  (triggerToggleFavorite)="toggleFavorite(person)"
                ></app-interaction-bar-people>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </ng-template>
  </ng-container>
  <ng-template #emptyContent>
    <app-empty-content></app-empty-content>
  </ng-template>

  <div class="widget__btn-create-wrapper">
    <button
      class="btn btn-primary widget__btn-create my-3"
      translate
      appRequireLogin
      (click)="invitePeopleRef?.openInvitationEmail()"
    >
      <em class="fas fa-plus fa-lg"></em>
    </button>
    <app-invitation-by-email
      #invitePeopleRef
      class="hidden"
      [baseHttpService]="listService"
      [ccEmail]="(profile$ | async)?.email"
      [willUseEntityId]="false"
      [messageBody]="'UI.Invitation.Mail-content--Body-For-People'"
      [senderName]="currentUser?.firstName + ' ' + currentUser?.lastName"
      [senderSignature]="
        'UI.Invitation.Signature'
          | translate
          | stringReplacement
            : {
                '{name}': currentUser?.firstName + ' ' + currentUser?.lastName,
                '{portalName}': portalName
              }
      "
      [inviteTokenType]="TokenTypes.InvitePeople"
    ></app-invitation-by-email>
  </div>
</ng-container>

<app-bootstrap-pagination
  [page]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  (changePageSize)="onPageSizeChange($event)"
  (changePage)="onPageChange($event)"
></app-bootstrap-pagination>

<app-events-modal
  *ngIf="showModal === EntityName.Event"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.firstName + ' ' + selectedEntity?.lastName"
  [modalTitle]="'Events' | translate"
  [httpService]="listService"
  (dismissModal)="showModal = null"
></app-events-modal>

<app-ventures-modal
  *ngIf="showModal === EntityName.Venture"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.firstName + ' ' + selectedEntity?.lastName"
  [modalTitle]="'Ventures' | translate"
  [httpService]="listService"
  (dismissModal)="showModal = null"
  [globalOrgId]="orgId"
></app-ventures-modal>

<div class="entity-fullscreen-mode">
  <app-metadata-table-people
    [hidden]="!entityFullScreenMode"
    [entityDatas]="items"
    [isLoading]="isLoading"
    [showEntityPagination]="true"
    [editable]="false"
    [isFullScreen]="entityFullScreenMode"
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
    (minimize)="onMinize()"
  ></app-metadata-table-people>
</div>
