import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { EntityCreationModalService } from '@core/services/entity-creation-modal.service';
import { GlobalFilterStoredService } from '@core/services/global-filter-stored.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  EntityName,
  MessageAction,
  MessageType,
} from '@shared/enums/entity-name.enum';
import { News } from '@shared/interfaces/news.interface';
import { SendMessageDialogComponent } from '@src/app/components/dialogs/send-message-dialog/send-message-dialog.component';
import { NewsMessagesHttpService } from '@src/app/core/http/news-messages-http.service';
import { YouTransformPipe } from '@src/app/shared/pipes/you-transform.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EntityDetailsModalService } from '@core/services/entity-details-modal.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
})
export class NewsItemComponent implements AfterContentInit {
  @Input() news: News;
  @Input() currentPersonId?: number;

  @Output() showLikesModal: EventEmitter<any> = new EventEmitter();
  @Output() afterCommentModalClosed = new EventEmitter<News>();
  @Output() contentLoaded = new EventEmitter();

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;

  MESSAGE_TYPE = MessageType;
  ENTITY_NAME = EntityName;
  MESSAGE_ACTION = MessageAction;

  peopleURL = environment.jipUrl.people;

  rawHtml: any;
  safeHtml!: SafeHtml;

  constructor(
    private sessionService: SessionService,
    private youTransformPipe: YouTransformPipe,
    private newsService: NewsMessagesHttpService,
    private modalService: NgbModal,
    private readonly createEntityModalService: EntityCreationModalService,
    private readonly viewEntityModalService: EntityDetailsModalService,
    private readonly globalFilterStoredService: GlobalFilterStoredService,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterContentInit(): void {
    this.rawHtml = this.getSafeHtml(this.news?.content?.thumbnail || '');
    this.contentLoaded.emit();
  }

  getSafeHtml(html: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  toggleLike(news: News): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    if (news) {
      if (!news.isLiked) {
        this.newsService.like(news.id).subscribe((newLikeId) => {
          news.isLiked = !news.isLiked;
          news.likesCount = news.likesCount + 1;
          news.likeId = newLikeId;
        });
      } else {
        this.newsService.unlike(news.likeId).subscribe((res) => {
          news.isLiked = !news.isLiked;
          news.likesCount = news.likesCount > 0 ? news.likesCount - 1 : 0;
          news.likeId = null;
        });
      }
    }
  }

  showLikes(news: News): void {
    this.showLikesModal.emit(news);
  }

  getSender(): string {
    return this.youTransformPipe.transform(
      this.news.from,
      this.currentPersonId
    );
  }

  openSendMessage(news: News): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;
    const modalRef = this.modalService.open(SendMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    modalRef.componentInstance.toPersonId = news.content?.instanceId;
    modalRef.componentInstance.receiverName =
      news.content?.firstName + ' ' + news.content?.lastName;
  }

  isPostOwner(news: News, personId: number): boolean {
    return news.action === MessageAction.Post && news.from.id === personId;
  }

  openEditingPostDialog(post: News): void {
    this.globalFilterStoredService
      .getFilterCriteria()
      .then((globalFilterCriteria) => {
        this.createEntityModalService.modifyPost(post, globalFilterCriteria);
      });
  }

  openViewingPostDialog(post: News): void {
    this.globalFilterStoredService
      .getFilterCriteria()
      .then((globalFilterCriteria) => {
        this.viewEntityModalService.viewPost(post, globalFilterCriteria);
      });
  }

  onDeletePost(post: News): void {
    this.createEntityModalService.deletePost(post);
  }
}
