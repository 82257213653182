<ng-container *ngIf="!isLoading; else skeleton">
  <div>
    <ng-container *ngIf="items && items.length > 0; else emptyContent">
      <ng-container *ngIf="!isGridView; else entityGridView">
        <app-metadata-table-challenge
                [entityDatas]="items"
                [isLoading]="isLoading"
                [editable]="false"
        ></app-metadata-table-challenge>
      </ng-container>

      <ng-template #entityGridView>
        <ul
                [class.three-columns]="columns === '3'"
                [style.background-color]="bg_color"
                class="widget__container venture-list challenge-list"
        >
          <li
                  *ngFor="let challenge of items; index as orgIndex"
                  class="venture__item"
          >
            <div class="venture__wrapper">
              <a
                      appUnsavedFormCheck
                      class="link-unstyled link-rich-text-pointer"
                      href="{{ entitybase }}/{{ challenge.id }}"
                      target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
                      rel="noopener"
              >
                <div class="venture__header">
                  <div class="organization__brand row">
                <span class="organization__logo d-flex col-1">
                  <img
                          [src]="challenge.logo"
                          appDefaultPlaceholder
                          [default]="placeholderImg"
                          appDynamicImgUrl
                  />
                </span>

                    <div class="organization__name-container col">
                      <div class="organization__name d-flex">
                    <span
                            [ngbTooltip]="challenge.displayText"
                            class="text-reset truncate-line-1 underline"
                    >
                      {{ challenge.orgName }}
                    </span>
                        <a
                                *ngIf="challenge.orgUrl"
                                [ngbTooltip]="orgWebsiteRef"
                                [value]="challenge.orgUrl"
                                appUrlPrefix
                                appTooltip
                                target="_blank"
                                class="ml-2"
                        >
                          <app-internal-icon
                                  [name]="InternalIcon.GlobeCursor"
                                  [height]="18"
                                  [minWidth]="19.5"
                          ></app-internal-icon>
                        </a>
                        <ng-template #orgWebsiteRef>
                          <div class="d-flex flex-column align-items-center">
                        <span>{{
                          'UI.Organization.ExternalWebsiteTooltip' | translate
                        }}</span>
                            <span class="text-white font-weight-bold">
                          {{ challenge.orgUrl }}
                        </span>
                          </div>
                        </ng-template>
                      </div>
                      <div class="organization__sub-name">
                        <a
                                [href]="challenge.enclosingOrgUrl"
                                target="_blank"
                                class="text-reset truncate-line-1"
                                appTooltip
                        >
                      <span [ngbTooltip]="challenge.enclosingOrgName">
                        {{ challenge.enclosingOrgName }}
                      </span>
                        </a>
                      </div>
                    </div>

                    <div class="organization__header-action pt-0">
                      <ng-container
                              *ngIf="
                      challenge &&
                      challenge.showAsChallenge &&
                      challenge.challengeTimeline &&
                      challenge.isActivated
                    "
                      >
                        <div
                                class="d-flex align-items-center justity-content-center"
                                [ngbTooltip]="
                        getReasonWhyCannotSubmitVentureToCall(challenge)
                          | translate
                      "
                                [disableTooltip]="canSubmitVentureToCall(challenge)"
                                (click)="$event.preventDefault()"
                        >
                          <button
                                  class="btn btn-primary btn-interact btn-interact--big"
                                  appRequireLogin
                                  appDebounceClick
                                  [callBackLoginPage]="!sessionService.isLogin ? entityCreatepath : ''"
                                  (debounceClick)="submitVentureToOrg(challenge)"
                                  [disabled]="!canSubmitVentureToCall(challenge)"
                          >
                            <i class="fas fa-upload"></i>
                            <span class="text-hide-small">
                          {{ 'UI.Common.Submit' | translate }}</span
                            >
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <app-image-wrapper [imgObject]="challenge.image">
                  </app-image-wrapper>
                  <div
                          class="venture__name challenge__name--min-height widget__heading--background d-flex align-items-center p-0"
                  >
                    <div class="flex-grow-1 px-3 py-1">
                      <div class="venture__title widget__heading">
                    <span
                            appTooltip
                            triggers="manual"
                            #t2="ngbTooltip"
                            [toolTipControl]="t2"
                            [ngbTooltip]="challenge.headline"
                            class="truncate-line-2"
                    >
                      {{ challenge.headline }}
                    </span>
                      </div>
                      <div class="widget__sub-heading">
                    <span
                            appTooltip
                            triggers="manual"
                            #t3="ngbTooltip"
                            [toolTipControl]="t3"
                            [ngbTooltip]="challenge.subHeadline"
                            class="truncate-line-1"
                    >
                      {{ challenge.subHeadline }}
                    </span>
                      </div>
                    </div>
                    <div class="flex-grow-0">
                      <app-timeline-current-phase
                              [leadCompany]="challenge"
                              [hideNameBlock]="true"
                      ></app-timeline-current-phase>
                    </div>
                  </div>
                </div>

                <div class="venture__body widget__card--background">
                  <div class="row">
                    <div class="col col-12">
                      <div class="venture__info d-flex flex-column">
                        <div
                                class="d-flex justify-content-between align-items-center w-100 mb-3 mt-3"
                        >
                          <div class="organization__created-time">
                            {{
                            challenge.createdDate
                            | localizedDate : DateFormat.MetadataTable
                            }}
                          </div>
                          <div class="d-flex">
                            <!-- More -->
                            <div class="more-options" ngbDropdown>
                              <button
                                      placement="bottom-left"
                                      class="btn btn-interact btn-interact--small btn-secondary-dark text-white mr-2"
                                      (click)="$event.preventDefault()"
                                      ngbDropdownToggle
                              >
                                <i
                                        class="far fa-ellipsis-h fa-lg align-middle"
                                ></i>
                                <span class="text-hide-small" translate>
                              UI.Common.Button.More
                            </span>
                              </button>
                              <ul ngbDropdownMenu>
                                <!-- Ventures -->
                                <li
                                        ngbDropdownItem
                                        class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                          *ngIf="
                                  isLoadingVenturesCount;
                                  else venturesBtn
                                "
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #venturesBtn>
                                    <a
                                            appDebounceClick
                                            (debounceClick)="
                                    showEntityData(challenge);
                                    showVenturesModal = true
                                  "
                                    >
                                      <i class="fas fa-briefcase"></i>
                                      <span
                                              class="venture__actions-label font-weight-bold"
                                      >
                                    {{ 'Ventures' | translate }}
                                    <span
                                            [innerHTML]="
                                        challenge.venturesCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                    </a>
                                  </ng-template>
                                </li>

                                <!-- Events -->
                                <li
                                        ngbDropdownItem
                                        class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                          *ngIf="isLoadingEventsCount; else eventsBtn"
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #eventsBtn>
                                    <a
                                            appDebounceClick
                                            (debounceClick)="
                                    showEntityData(challenge);
                                    showEventsModal = true
                                  "
                                    >
                                      <i class="fas fa-folder-open"></i>
                                      <span
                                              class="venture__actions-label font-weight-bold"
                                      >
                                    {{ 'Events' | translate }}
                                    <span
                                            [innerHTML]="
                                        challenge.eventsCount | parentheses
                                      "
                                    ></span>
                                  </span>
                                    </a>
                                  </ng-template>
                                </li>
                              </ul>
                            </div>

                            <app-join-organization
                                    [baseHttpService]="listService"
                                    [entity]="challenge"
                            ></app-join-organization>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>

              <div class="venture__body widget__card--background">
                <div class="row">
                  <div class="col col-12">
                    <app-timeline-view
                            [items]="challenge.challengeTimeline"
                    ></app-timeline-view>
                  </div>
                </div>
              </div>

              <div
                      class="venture__body widget__card--background organization__keyOfferings"
              >
                <div class="row">
                  <div class="col col-12 mt-2">
                    <div
                            class="d-flex pointer-cursor"
                            appDebounceClick
                            (debounceClick)="
                    showEntityData(challenge); showKeyOfferModal = true
                  "
                            (click)="selectedChallenge = challenge"
                    >
                      <div class="venture__label widget__label" translate>
                        UI.Organization.KeyOfferings
                      </div>

                      <span
                              [innerHTML]="challenge.keyOfferings.length | parentheses"
                      ></span>
                    </div>

                    <ul
                            appTooltip
                            triggers="manual"
                            #t="ngbTooltip"
                            [toolTipControl]="t"
                            [ngbTooltip]="keyOfferings"
                            tooltipClass="no-limit-width-tooltip"
                            class="list-inline tooltip-wrapper widget__highlight"
                    >
                      <ng-container
                              *ngTemplateOutlet="keyOfferings"
                      ></ng-container>
                    </ul>
                    <ng-template #keyOfferings>
                      <app-tag-view
                              [tags]="challenge.keyOfferings"
                              [bindLabel]="'description'"
                              class="w-100 d-block"
                      >
                      </app-tag-view>
                    </ng-template>
                  </div>
                </div>
              </div>

              <app-interaction-bar
                      entityPath="{{ entitybase }}/{{ challenge?.id }}"
                      [httpService]="listService"
                      [entity]="challenge"
                      [entityName]="entityName"
                      [iconsHidden]="{
                        message: true
                      }"
                      [isLoadingLikingsCount]="isLoadingLikingsCount"
                      [isLoadingFollowingsCount]="isLoadingFollowingsCount"
                      [isLoadingCommentsCount]="isLoadingCommentsCount"
                      (triggerToggleLike)="toggleLike(challenge)"
                      (triggerShowLikingsData)="
                              showEntityData(challenge);
                              showLikesModal = !!challenge?.likesCount
                            "
                      (triggerShowFollowingsData)="
                                    showEntityData(challenge); showPeopleModal = true
                                  "
                      (afterCommentModalClosed)="onCommentModalClosed()"
                      (triggerToggleFavorite)="toggleFavorite(challenge)"
              >
              </app-interaction-bar>
            </div>
          </li>
        </ul>
      </ng-template>

    </ng-container>
    <ng-template #emptyContent>
      <app-empty-content></app-empty-content>
    </ng-template>

    <app-bootstrap-pagination
            *ngIf="show_paginator"
            [page]="page"
            [pageSize]="pageSize"
            [collectionSize]="collectionSize"
            (changePageSize)="onPageSizeChange($event)"
            (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>

    <div
            *ngIf="show_creation_btn"
            class="text-right widget__btn-create-wrapper"
    >
      <button
              class="btn btn-primary widget__btn-create my-3"
              translate
              appRequireLogin
              [callBackLoginPage]="entityCreatepath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<app-key-offer-modal
        *ngIf="showKeyOfferModal && !!selectedChallenge?.keyOfferings?.length"
        [modalTitle]="'UI.Organization.KeyOfferings' | translate"
        [entityId]="selectedEntityId"
        (dismissModal)="showKeyOfferModal = false"
        [keyOfferings]="selectedChallenge.keyOfferings"
>
</app-key-offer-modal>

<app-people-modal
        *ngIf="showPeopleModal"
        [entityId]="selectedEntityId"
        [entityTitle]="selectedEntity?.orgName"
        [modalTitle]="'Followers' | translate"
        [httpService]="listService"
        (dismissModal)="showPeopleModal = false"
>
</app-people-modal>

<app-events-modal
        *ngIf="showEventsModal"
        [entityId]="selectedEntityId"
        [entityTitle]="selectedEntity?.orgName"
        [modalTitle]="'Events' | translate"
        [httpService]="listService"
        (dismissModal)="showEventsModal = false"
>
</app-events-modal>

<app-ventures-modal
        *ngIf="showVenturesModal"
        [entityId]="selectedEntityId"
        [entityTitle]="selectedEntity?.orgName"
        [modalTitle]="'Ventures' | translate"
        [httpService]="listService"
        (dismissModal)="showVenturesModal = false"
        [globalOrgId]="orgId"
>
</app-ventures-modal>

<app-likes-modal
        *ngIf="showLikesModal"
        [entityId]="selectedEntityId"
        [entityTitle]="selectedEntity?.orgName"
        [modalTitle]="'UI.Likes.Challenge' | translate"
        [httpService]="listService"
        (dismissModal)="showLikesModal = false"
>
</app-likes-modal>

<ng-template #skeleton>
  <div *ngIf="isLoadWithSkeleton" class="loading-skeleton">
    <div class="row">
      <div
              *ngFor="let item of skeletonCount"
              class="col-12"
              [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="d-flex align-items-center m-2">
            <div class="skeleton-item avatar-skeleton"></div>
            <div class="flex-grow-1 mx-2">
              <h6></h6>
            </div>
            <div class="skeleton-item btn-skeleton"></div>
          </div>
          <div class="skeleton-item img-skeleton challenge-img"></div>
          <div class="p-2">
            <div class="d-flex justify-content-between mb-4">
              <h6 class="w-25"></h6>
              <div class="w-50 d-flex justify-content-between">
                <h6 class="w-25"></h6>
                <h6 class="w-25"></h6>
                <h6 class="w-25"></h6>
              </div>
            </div>
            <h6 class="w-25"></h6>
            <h6></h6>
            <h6></h6>
            <h6></h6>
            <h6></h6>
            <h6></h6>
          </div>
          <div class="skeleton-item img-skeleton challenge-img"></div>
          <hr class="mt-2" />
          <div class="p-2">
            <h6 class="w-50"></h6>
          </div>
          <hr />
          <div class="p-2">
            <div class="d-flex justify-content-between">
              <h6 class="w-25"></h6>
              <h6 class="w-25"></h6>
              <h6 class="w-25"></h6>
              <h6 class="w-25"></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="entity-fullscreen-mode">
  <app-metadata-table-challenge
          [hidden]="!entityFullScreenMode"
          [entityDatas]="items"
          [isLoading]="isLoading"
          [showEntityPagination]="true"
          [editable]="false"
          [isFullScreen]="entityFullScreenMode"
          [page]="page"
          [pageSize]="pageSize"
          [collectionSize]="collectionSize"
          (changePageSize)="onPageSizeChange($event)"
          (changePage)="onPageChange($event)"
          (minimize)="onMinize()"
  ></app-metadata-table-challenge>
</div>
