import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tag-view',
  templateUrl: './tag-view.component.html',
})
export class TagViewComponent implements OnChanges {
  @Input() tags: any[] = [];
  @Input() bindLabel: string;

  @ViewChild('chipContainer', { static: false }) chipContainer!: ElementRef;

  visibleTags: any[] = [];
  hiddenTags: any[] = [];
  rootTags = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags || changes.bindLabel) {
      this.rootTags = changes.tags.currentValue;
      this.tags = this.tags.map((tag) => {
        if (typeof tag === 'string') {
          return tag;
        } else {
          return tag[this.bindLabel];
        }
      });

      this.updateTagsVisibility();
    }
  }

  ngAfterViewInit() {
    this.updateTagsVisibility();
  }

  @HostListener('window:resize')
  updateTagsVisibility() {
    if (!this.chipContainer) return;

    const containerWidth = this.chipContainer?.nativeElement?.offsetWidth - 20;
    let totalWidth = 0;
    this.visibleTags = [];
    this.hiddenTags = [];

    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.fontSize = '12px';
    document.body.appendChild(tempSpan);

    this.tags.forEach((tag) => {
      tempSpan.innerText = tag;
      const tagWidth = tempSpan.offsetWidth + 24;
      if (totalWidth + tagWidth < containerWidth) {
        this.visibleTags.push(tag);
        totalWidth += tagWidth;
      } else {
        this.hiddenTags.push(tag);
      }
    });

    document.body.removeChild(tempSpan);
  }
}
