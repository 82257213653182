import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-rich-text-toggle',
  templateUrl: './rich-text-toggle.component.html',
})
export class RichTextToggleComponent implements OnChanges, OnInit {
  @Input() text: string;
  @Input() controlName: string;
  @Input() isWideView = false;
  @Input() showOverlayInsteadOfShowMore = false;
  @Input() hideSeeMore = false;
  @Input() minNumOfRows = 3;
  @Input() maxNumOfRows = 10;
  @Input() defaultLineHeight = 20;
  @Input() controlStyleByCentralConfig = false;
  @Input() image: string;
  @Input() openDetailsPopup = false;

  @Input() expandedMode = false;

  @Input() isRevertCaret = false;

  @Input() coverImage: string;

  @Output() viewMoreClicked = new EventEmitter<boolean>();
  @Output() viewing: EventEmitter<void> = new EventEmitter();

  @ViewChild('contentContainer') contentContainer: ElementRef;

  isCollapsed = true;
  showToggle = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.shouldShowToggle();
  }

  ngOnInit(): void {
    this.checkShowToggle();
  }

  get textContainerHeight(): string {
    if (this.expandedMode) return '100%';

    if (!this.isCollapsed) {
      return 'unset';
    }

    return this.isWideView
      ? this.getTextHeight(this.maxNumOfRows)
      : this.getTextHeight(this.minNumOfRows);
  }

  private shouldShowToggle(): void {
    if (!this.text || !this.controlName) {
      return;
    }

    setTimeout(() => {
      this.checkShowToggle();
    }, 500);
  }

  onViewMore(event: MouseEvent): void {
    event.stopPropagation();

    if (!this.showOverlayInsteadOfShowMore) {
      this.isCollapsed = !this.isCollapsed;
    }

    this.viewMoreClicked.emit(this.isCollapsed);

    if (this.isCollapsed && this.contentContainer?.nativeElement) {
      this.contentContainer.nativeElement.scrollTop = 0;
    }
  }

  onViewing($event): void {
    if (this.openDetailsPopup){
      return this.viewing.emit();
    }
    return this.onViewMore($event);
  }

  private getTextHeight(numberOfRows: number): string {
    return `${numberOfRows * this.defaultLineHeight}px`;
  }

  private checkShowToggle(): void {
    const container: HTMLElement = document.querySelector(
      `.rich-text-toggle.${this.controlName}`
    );
    if (!container) {
      return;
    }

    const editor = container.querySelector('.ql-editor');
    if (!editor || editor.children?.length === 0) {
      return;
    }

    let contentHeight = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < editor.children.length; i++) {
      contentHeight += editor.children[i].clientHeight;
    }

    this.showToggle = contentHeight > container.clientHeight;

    if (this.controlStyleByCentralConfig) {
      editor.classList.add('widget__content');
    }
  }
}
