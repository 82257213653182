import { Component, Input, OnInit } from '@angular/core';
import { BaseDetailComponent } from '@src/app/components/base/base-detail/base-detail.component';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
})
export class OrganizationComponent
  extends BaseDetailComponent
  implements OnInit
{
  // tslint:disable-next-line:variable-name
  @Input() ui_template: TemplateName = TemplateName.Default;
  @Input() @ToBoolean() isSimpleOnboarding: boolean;
}
